import axios from "axios";
import LayoutApi from "./LayoutApi";
import CategoryApi from "../categories/CategoryApi";
import LayoutConstants from "./LayoutConstants";
import UuidUtil from "../../utils/UuidUtil";
import CategoryConstants from "../categories/CategoryConstants";
import UploadFile from "../../utils/UploadFile";
import { actionErrorAuth } from "../login/LoginAction";
import { actionSetErrorUploadImage } from "../uploadImage/UploadImageActions";

const getLayout = async (dispatch) => {
    try {
        const response = await axios.get(CategoryApi.GetCategories);
        if (response.data) {
            dispatch({
                type: CategoryConstants.GET_ALL_ACTION_TYPE,
                items: response.data.length > 0 ? response.data : null
            });
            if (response.data.length > 0) {
                dispatch(actionGetLayoutByCategory(response.data[0].id));//TODO - There should be a way to get main category or endpoint get categories in order , ie. first category result is the main one
            }
        } else {
            console.log("TODO - server error");
        }

        dispatch(actionSetErrorUploadImage(LayoutConstants.IMAGE, response.data.imageUrl && response.data.imageUrl.length > 0 ? false : true));
    } catch (e) {
        dispatch(actionErrorAuth(e));
    }
};

const getLayoutByCategory = async (dispatch, categoryId) => {
    try {
        const response = await axios.get(LayoutApi.GetLayoutByCategoryId(categoryId));
        dispatch({
            type: LayoutConstants.GET_ACTION_TYPE,
            layout: response.data != "" ? response.data : getEmptyLayout(categoryId),
            category: categoryId
        });
        dispatch(actionSetErrorUploadImage(LayoutConstants.IMAGE, response.data != "" && response.data.imageUrl && response.data.imageUrl.length > 0 ? false : true));
    } catch (e) {
        dispatch(actionErrorAuth(e));
    }
};

const saveLayout = async (dispatch, layout) => {
    try {
        const response = await axios.post(LayoutApi.PostLayout, layout);
        if (response.data) {
            dispatch({
                type: LayoutConstants.SAVE_ADD_ACTION_TYPE,
                id: response.data.id
            });
            dispatch(actionMessageLayout(LayoutConstants.MESSAGE_LAYOUT_OK));
        } else {
            dispatch(actionMessageLayout(LayoutConstants.MESSAGE_LAYOUT_ERROR));
        }
    } catch (e) {
        dispatch(actionErrorAuth(e));
        dispatch(actionMessageLayout(LayoutConstants.MESSAGE_LAYOUT_ERROR));
    }
};

const editLayout = async (dispatch, layout) => {
    try {
        const response = await axios.put(LayoutApi.PutLayout, layout);
        dispatch(actionMessageLayout(response.data ? LayoutConstants.MESSAGE_LAYOUT_OK : LayoutConstants.MESSAGE_LAYOUT_ERROR));
    } catch (e) {
        dispatch(actionErrorAuth(e));
        dispatch(actionMessageLayout(LayoutConstants.MESSAGE_LAYOUT_ERROR));
    }
    dispatch({
        type: LayoutConstants.SAVE_EDIT_ACTION_TYPE
    });
};

const uploadFile = async (dispatch, file) => {
    try {
        const formData = new FormData();
        formData.append('file', file, file.name);
        const response = await axios.post(UploadFile.Api, formData);
        if (response.data) {
            dispatch(actionChangeValueLayout('imageUrl', response.data.dbPath));
            dispatch(actionSetErrorUploadImage(LayoutConstants.IMAGE, false));
        } else {
            dispatch(actionSetErrorUploadImage(LayoutConstants.IMAGE, true));
        }
    } catch (e) {
        if (e && e.response && e.response.status === 400 && e.response.data) {
            dispatch(actionMessageLayout(LayoutConstants.MESSAGE_LAYOUT_ERROR_IMAGE(e.response.data)));
        }
        dispatch(actionErrorAuth(e));
    }
};

const actionGetLayout = () => {
    return dispatch => {
        getLayout(dispatch);
    };
};

const actionGetLayoutByCategory = (category) => {
    return dispatch => {
        getLayoutByCategory(dispatch, category);
    };
};

const actionChangeValueLayout = (propertie, value) => {
    return dispatch => {
        dispatch({
            type: LayoutConstants.CHANGE_VALUE_ACTION_TYPE,
            propertie: propertie,
            value: value
        });
    };
};

const actionSaveAddLayout = (layout) => {
    return dispatch => {
        saveLayout(dispatch, layout);
    };
};

const actionSaveEditLayout = (layout) => {
    return dispatch => {
        editLayout(dispatch, layout);
    };
};

const actionUploadLayout = (file) => {
    return dispatch => {
        dispatch(actionMessageLayout(null));
        uploadFile(dispatch, file);
    };
};

const actionMessageLayout = (message) => {
    return {
        type: LayoutConstants.MESSAGE_ACTION_TYPE,
        message: message
    };
};

const getEmptyLayout = (categoryId) => {
    return {
        id: UuidUtil.EMPTY, title: "", description: "", titleResult: "", descriptionResult: "", discoverMoreResult: "", category: { id: categoryId } };
}

export { actionGetLayout, actionGetLayoutByCategory, actionChangeValueLayout, actionSaveAddLayout, actionSaveEditLayout, actionUploadLayout, actionMessageLayout };