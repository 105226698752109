import ProductConstants from "./ProductConstants";
import getAPIPath from '../../utils/PathResolver';

export default class ProductApi {
  static GetProduct = (id) => { return `${getAPIPath()}product/GetById?id=${id}`; }
  static GetProducts = (sortBy, page, pageSize) => {
    return `${getAPIPath()}product?page=${page}&pageSize=${pageSize || ProductConstants.PAGE_SIZE}&orderField=${sortBy}`;
  }
  static SearchProducts = (search, sortBy, page, pageSize) => {
    if (search && search.length > 0) {
      return `${getAPIPath()}product/Search?name=${search}&page=${page}&pageSize=${pageSize || ProductConstants.PAGE_SIZE}&orderField=${sortBy}`;
    } else {
      return `${getAPIPath()}product/Search?page=${page}&pageSize=${pageSize || ProductConstants.PAGE_SIZE}&orderField=${sortBy}`;
    }    
  }
  static ToggleProduct = (id) => { return `${getAPIPath()}product/Toggle?id=${id}`; }
  static DeleteProduct = (id) => { return `${getAPIPath()}product?id=${id}`; }
  static get PostProduct() { return `${getAPIPath()}product`; }
  static get PutProduct() { return `${getAPIPath()}product`; }
  static GetProductsResume = (name, sortBy) => { return `${getAPIPath()}product/GetAllResume?name=${name}&orderField=${sortBy}`; }
}
