import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Label, Input, CustomInput, Alert } from 'reactstrap';
import HeaderSave from '../header/HeaderSave';
import PathsApp from '../../utils/PathsApp';
import classnames from 'classnames';
import ButtonAdd from '../buttons/ButtonAdd';
import ProductsDistributor from './ProductsDistributor';
import ProductSelectorModal from './ProductSelectorModal';
import { actionOpenProductsSelect, actionBeginAddDistributor, actionChangeValueDistributor, actionSaveAddDistributor, actionMessageDistributorDetail, actionBeginEditDistributor, actionSaveEditDistributor, actionToggleDistributor, actionDeleteDistributor, actionSetInvalidsProductsDistributor, actionResetInvalidProductsDistributor } from './DistributorAction';
import ProductSelectedModal from './ProductSelectedModal';
import { actionGetProductsResume, actionResetMessageList } from '../products/ProductAction';
import DistributorConstants from './DistributorConstants';
import { Redirect } from 'react-router';
import AlertContainer from '../../alerts/AlertContainer';
import { v4 as uuidv4 } from 'uuid';
import { actionGetCategories } from '../categories/CategoryActions';
import DistributorRequestModel from './DistributorRequestModel';
import ProductSortEnum from '../products/ProductSortEnum';

class DistributorContainer extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            token: uuidv4().toString(),
            urlWizard: window.location.origin + '/explore'
        };
    }

    componentDidMount() {
        this.props.getCategories();
        this.props.getProducts(ProductSortEnum.SKU);
        if (this.props.match.path === PathsApp.DISTRIBUTORS_ADD) {
            this.props.beginAdd(this.state.token);
        } else {
            this.props.beginEdit(this.props.match.params.id, (distributorToken, isRegion) => {
                if (!isRegion) {
                    this.setState({
                        token: distributorToken
                    });
                }
            });
        }
    }

    handleSave = (event) => {
        event.preventDefault();
        if (this.isValidProductDistributor()) {
            console.log('continue');
            this.props.resetInvalidProducts();
            this.props.resetMessage();
            if (this.props.match.path === PathsApp.DISTRIBUTORS_ADD) {
                this.props.saveAdd(this.props.currentDistributor);
            } else {
                this.props.saveEdit(this.props.currentDistributor);
            }
        } else {
            console.log('stop');
            this.props.setMessage(DistributorConstants.MESSAGE_ADD_ERROR('Invalid products distributor url.'));
        }
    };

    isValidProductDistributor() {
        const productsDistributor = this.props.currentDistributor.productsDistributor.filter(pd => pd.isEnabled);
        if (productsDistributor && productsDistributor.length > 0) {
            const invalids = productsDistributor.filter(pd => {
                if (pd.url) {
                    const urlPattern = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
                    return !pd.url.match(urlPattern);
                } else {
                    return true;
                }
            });
            if (invalids && invalids.length > 0) {
                this.props.setInvalidsProducts(invalids);
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    handleGetBack = (event) => {
        event.preventDefault();
        this.props.history.push(PathsApp.DISTRIBUTORS_ROOT);
    };

    handleVisible = (event) => {
        event.preventDefault();
        if (!DistributorRequestModel.isEmpty(this.props.currentDistributor)) {
            this.props.toggle(this.props.currentDistributor.id);
        } else {
            console.log('TODO-can not toggle message');
        }
    };

    handleDelete = (event) => {
        event.preventDefault();
        if (!DistributorRequestModel.isEmpty(this.props.currentDistributor)) {
            this.props.delete(this.props.currentDistributor.id, false);
        } else {
            console.log('TODO-can not delete message');
        }
    };

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    handleAdd = (event) => {
        event.preventDefault();
        this.props.openSelect(this.props.products);
    };

    handleChangeInput = (event) => {
        if (event.target.id === 'isRegion') {
            if (this.props.currentDistributor.isRegion) {
                this.props.changeInputValue('token', this.state.token);
            } else {
                this.props.changeInputValue('token', '');
                this.props.changeInputValue('productDomain', '');
            }
            this.props.changeInputValue(event.target.id, !this.props.currentDistributor.isRegion);
        } else {
            this.props.changeInputValue(event.target.id, event.target.value);
        }
    };

    existsSelected = () => {
        return this.props.currentDistributor.productsDistributor && this.props.currentDistributor.productsDistributor.length > 0 && this.props.currentDistributor.productsDistributor.find(pd => pd.isEnabled) !== undefined;
    };

    generateToken = () => {
        this.props.changeInputValue('token', uuidv4().toString());
    };

    componentWillUnmount() {
        this.props.resetMessageList();
        this.props.resetMessage();
    }

    render() {
        if (!this.props.currentDistributor.exists) {
            return (
                <Redirect to={PathsApp.DISTRIBUTORS_ROOT} />
            );
        } else {
            return (
                <Fragment>
                    {this.props.messageAction ? window.scrollTo(0, 0) : null}
                    <AlertContainer alert={this.props.messageAction} />
                    <Container className="page-admin page-admin--feature" fluid>
                        <HeaderSave title={"Distributor detail"} handleSave={this.handleSave} handleGetBack={this.handleGetBack} handleVisible={this.handleVisible} handleDelete={this.handleDelete} isVisible={this.props.currentDistributor.isEnabled} />
                        <Container className="admin-tabs product-tabs" fluid>
                            <div>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink className={classnames({ 'admin-tabs__item': true, active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }} >Distributor info</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ 'admin-tabs__item': true, active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }} >Products</NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane className="pt-4" tabId="1">
                                        <Row>
                                            <Col className="mb-1">
                                                <FormGroup>
                                                    <Label for="name">Name</Label>
                                                    <Input type="text" className="input-admin" name="name" id="name" onChange={this.handleChangeInput} value={this.props.currentDistributor.name} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="3" className="mb-1">
                                                <FormGroup>
                                                    <Label for="isRegion">Type</Label>
                                                    <div className="question-type">
                                                        <CustomInput type="checkbox" id="isRegion" name="isRegion" label="Is Region" onChange={this.handleChangeInput} checked={this.props.currentDistributor.isRegion} />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="9" className="mb-1">
                                                <FormGroup>
                                                    <Label for="token">Token</Label>
                                                    <Input readOnly={!this.props.currentDistributor.isRegion} type="text" className="input-admin" name="token" id="token" onChange={this.handleChangeInput} value={this.props.currentDistributor.token} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {
                                            this.props.currentDistributor.isRegion
                                                ? <Row>
                                                    <Col className="mb-1">
                                                        <FormGroup>
                                                            <Label for="productDomain">Product domain</Label>
                                                            <Input type="text" className="input-admin" name="productDomain" id="productDomain" onChange={this.handleChangeInput} value={this.props.currentDistributor.productDomain} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                : null
                                        }
                                        <Row>
                                            <Col className="mb-1">
                                                <FormGroup>
                                                    <Row>
                                                        <Col>
                                                            <Label>Urls wizard</Label>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        this.props.categories ? this.props.categories.map(c =>
                                                            <Row key={c.id}>
                                                                <Col>
                                                                    <span className="font-weight-bold">{`${this.state.urlWizard}/${c.code.toLowerCase()}/${this.props.currentDistributor.token}`}</span>
                                                                </Col>
                                                            </Row>)
                                                            : null
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mb-1">
                                                <FormGroup>
                                                    <Label for="layoutUrl">Layout url</Label>
                                                    <Input type="text" className="input-admin" name="layoutUrl" id="layoutUrl" onChange={this.handleChangeInput} value={this.props.currentDistributor.layoutUrl} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2" className="pt-4">
                                        <Row>
                                            <Col className="text-right">
                                                <ButtonAdd text="Add products" handleAdd={this.handleAdd} />
                                            </Col>
                                        </Row>
                                        {
                                            this.existsSelected()
                                                ? null
                                                : <Alert className="mt-2" color="warning">{DistributorConstants.MESSAGE_PRODUCTSDISTRIBUTOR_NOT_SELECTED}</Alert>
                                        }
                                        <ProductsDistributor items={this.props.currentDistributor.productsDistributor.filter(pd => pd.isEnabled)} />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Container>
                        <ProductSelectorModal />
                        <ProductSelectedModal />
                    </Container>
                </Fragment>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        currentDistributor: state.distributor.selected,
        sortBy: state.product.sortBy,
        products: state.product.items,
        messageAction: state.distributor.messageAction,
        categories: state.category.items
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openSelect: (products) => dispatch(actionOpenProductsSelect(products)),
        beginAdd: (token) => dispatch(actionBeginAddDistributor(token)),
        changeInputValue: (propertie, value) => dispatch(actionChangeValueDistributor(propertie, value)),
        getProducts: (sortBy) => dispatch(actionGetProductsResume('', DistributorConstants.PAGE_SIZE, sortBy)),
        saveAdd: (distributor) => dispatch(actionSaveAddDistributor(distributor)),
        resetMessage: () => dispatch(actionMessageDistributorDetail(null)),
        getCategories: () => dispatch(actionGetCategories()),
        beginEdit: (distributorId, callback) => dispatch(actionBeginEditDistributor(distributorId, callback)),
        saveEdit: (distributor) => dispatch(actionSaveEditDistributor(distributor)),
        toggle: (distributorId) => dispatch(actionToggleDistributor(distributorId)),
        delete: (distributorId, isFromList) => dispatch(actionDeleteDistributor(distributorId, isFromList)),
        resetMessageList: () => dispatch(actionResetMessageList()),
        setInvalidsProducts: (invalids) => dispatch(actionSetInvalidsProductsDistributor(invalids)),
        setMessage: (message) => dispatch(actionMessageDistributorDetail(message)),
        resetInvalidProducts: () => dispatch(actionResetInvalidProductsDistributor())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DistributorContainer));
