import { ResultApi } from "./ResultApi";
import axios from 'axios';
import ResultConstants from "./ResultConstants";
import WizardConstants from "../wizard/WizardConstants";


const getQuestionForResult = (questions) => {
  let request = { questions: [] };
  if (questions && questions.length > 0) {
    const questionChecked = questions.filter(q => {
      const options = q.options.filter(o => o.checked);
      return options && options.length > 0
    });
    request.questions = questionChecked.map(q => {
      return {
        "questionId": q.id, "answerIds": q.options.filter(o => o.checked).map(m => m.id), "layoutTypeCode": q.layoutTypeCode
      };
    });
  } else {
    request = null;
  }
  return request;
};

let call;
const onceResult = (config = {}) => {
  if (call) {
    call.cancel("Only one request allowed at a time.");
  }
  call = axios.CancelToken.source();
  config.cancelToken = call.token
  return axios(config);
};

const getResult = async (dispatch, questions, typeSetup, distributorId) => {
  try {
    dispatch({
      type: ResultConstants.LOADING_ACTION_TYPE,
    });
    const request = getQuestionForResult(questions);
    if (request) {
      var config = {
        method: "post",
        url: typeSetup === WizardConstants.CLIENT ? ResultApi.Result(distributorId) : ResultApi.ResultDraft,
        data: request
      };
      const response = await onceResult(config);
      if (response.data && response.data.products) {
        dispatch({
          type: ResultConstants.GET_ACTION_TYPE,
          data: response.data.products
        });
        dispatch({
          type: ResultConstants.MESSAGE_RESULT_ACTION_TYPE,
          message: response.data.products.length > 0 ? null : ResultConstants.MESSAGE_RESULT_NOT_FOUND
        });
      } else {
        dispatch({
          type: ResultConstants.MESSAGE_RESULT_ACTION_TYPE,
          message: ResultConstants.MESSAGE_RESULT_NOT_FOUND
        });
      }
    } else {
      dispatch({
        type: ResultConstants.MESSAGE_RESULT_ACTION_TYPE,
        message: ResultConstants.MESSAGE_RESULT_NOT_FOUND
      });
    }
  } catch (e) {
    if (axios.isCancel(e)) {
      console.error(`Cancelling previous request: ${e.message}`);
    }
    console.log("TODO - error", e);
  }
};

const getAllProducts = async (dispatch, category, distributorId) => {
  try {
    dispatch({
      type: ResultConstants.LOADING_ACTION_TYPE
    });
    const response = await axios.get(ResultApi.GetAllProducts(category, distributorId));
    if (response.data && response.data.products) {
      dispatch({
        type: ResultConstants.GET_ALL_PRODUCTS_ACTION_TYPE,
        data: response.data.products
      });
      dispatch({
        type: ResultConstants.MESSAGE_RESULT_ACTION_TYPE,
        message: response.data.products.length > 0 ? null : ResultConstants.MESSAGE_RESULT_NOT_FOUND
      });
    } else {
      dispatch({
        type: ResultConstants.GET_ALL_PRODUCTS_ACTION_TYPE,
        data: []
      });
      dispatch({
        type: ResultConstants.MESSAGE_RESULT_ACTION_TYPE,
        message: ResultConstants.MESSAGE_RESULT_NOT_FOUND
      });
    }
  } catch (e) {
    dispatch({
      type: ResultConstants.GET_ALL_PRODUCTS_ACTION_TYPE,
      data: null
    });
    dispatch({
      type: ResultConstants.MESSAGE_RESULT_ACTION_TYPE,
      message: ResultConstants.MESSAGE_RESULT_NOT_FOUND
    });
  }
};

const actionGetResult = (questions, typeSetup, distributorId) => {
  return dispatch => {
    getResult(dispatch, questions, typeSetup, distributorId);
  };
};

const actionResetResult = () => {
  return {
    type: ResultConstants.RESET_ACTION_TYPE
  };
};

const actionGetAllProductsResult = (category, distributorId) => {
  return dispatch => {
    getAllProducts(dispatch, category, distributorId);
  };
}

export { actionGetResult, actionResetResult, actionGetAllProductsResult };
