import HomeConstants from "./HomeConstants";
import axios from 'axios';
import HomeApi from "./HomeApi";

const getLayout = async (dispatch, category) => {
  try {
    const response = await axios.get(HomeApi.GetLayout(category));
    if (response.data) {
      dispatch({
        type: HomeConstants.GET_ACTION_TYPE,
        layout: response.data
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    console.log('TODO - error', e);
  }
};

const actionGetWizardLayout = (category) => {
  return dispatch => {
    getLayout(dispatch, category);
  };
};

export { actionGetWizardLayout }
