import OptionConstants from "./OptionConstants";
import axios from 'axios';
import OptionModel from './OptionModel';
import OptionApi from "./OptionApi";
import { actionErrorAuth } from "../login/LoginAction";
import arrayMove from 'array-move';
import { actionOrderAnswers } from "../questions/QuestionAction";

const getOptions = async (dispatch, featureId, page) => {
  try {
    const isPaged = Number.isInteger(page);
    const response = isPaged ? await axios.get(OptionApi.GetPagedOptions(featureId, page)) : await axios.get(OptionApi.GetOptions(featureId));
    if (response.data) {
      dispatch({
        type: OptionConstants.GET_ALL_ACTION_TYPE,
        items: isPaged ? response.data.list : response.data,
        totalItems: isPaged ? response.data.totalItems : response.data.length,
        totalPages: isPaged ? response.data.totalPages : 1
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: OptionConstants.GET_ALL_ACTION_TYPE,
      items: null,
      totalItems: 0,
      totalPages: 0
    });
  }
}

const saveOption = async (dispatch, option, featureId) => {
  try {
    const response = await axios.post(OptionApi.PostOption, new OptionModel(option.id, option.name, option.name, featureId));
    if (response.data) {
      dispatch(actionMessageOption(OptionConstants.MESSAGE_ADD_OK));
      dispatch({
        type: OptionConstants.SAVE_ADD_ACTION_TYPE,
        newOption: response.data
      });
    } else {
      dispatch(actionMessageOption(OptionConstants.MESSAGE_ADD_ERROR));
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch(actionMessageOption(OptionConstants.MESSAGE_ADD_ERROR));
  }
};

const editOption = async (dispatch, option, featureId) => {
  try {
    const editedOption = new OptionModel(option.id, option.name, option.name, featureId);
    const response = await axios.put(OptionApi.PutOption, editedOption);
    if (response.data) {
      dispatch(actionMessageOption(OptionConstants.MESSAGE_UPDATE_OK));
      dispatch({
        type: OptionConstants.SAVE_EDIT_ACTION_TYPE,
        editedOption: editedOption
      });
    } else {
      dispatch(actionMessageOption(OptionConstants.MESSAGE_UPDATE_ERROR));
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch(actionMessageOption(OptionConstants.MESSAGE_UPDATE_ERROR));
  }
};

const deleteOption = async (dispatch, id) => {
  try {
    const response = await axios.delete(OptionApi.DeleteOption(id));
    if (response.data) {
      dispatch({
        type: OptionConstants.DELETE_ACTION_TYPE,
        id: id
      });
    } else {
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const actionGetOptions = (featureId) => {
  return dispatch => {
    getOptions(dispatch, featureId);
  };
};

const actionBeginAddOption = () => {
  return {
    type: OptionConstants.BEGIN_ADD_ACTION_TYPE
  };
};

const actionSaveAddOption = (option, featureId) => {
  return dispatch => {
    saveOption(dispatch, option, featureId);
  };
};

const actionCancelAddOption = () => {
  return {
    type: OptionConstants.CANCEL_ADD_ACTION_TYPE
  };
};

const actionSaveEditOption = (option, featureId) => {
  return dispatch => {
    editOption(dispatch, option, featureId);
  };
};

const actionDeleteOption = (id) => {
  return dispatch => {
    deleteOption(dispatch, id);
  };
};

const actionResetOptions = () => {
  return {
    type: OptionConstants.RESET_ACTION_TYPE
  };
};

const actionMessageOption = (message) => {
  return {
    type: OptionConstants.MESSAGE_ACTION_TYPE,
    message: message
  };
};

const actionGetOptionsByPage = (featureId, page) => {
  return dispatch => {
    getOptions(dispatch, featureId, page);
  };
};

const actionOrderOptions = (oldIndex, newIndex, oldOptions) => {
  const newOptions = arrayMove(oldOptions, oldIndex, newIndex);
  return dispatch => {
    dispatch({
      type: OptionConstants.ORDER_ACTION_TYPE,
      options: newOptions
    });
    dispatch(actionOrderAnswers(newOptions));
  };
};

export {
  actionGetOptions,
  actionBeginAddOption,
  actionSaveAddOption,
  actionCancelAddOption,
  actionSaveEditOption,
  actionDeleteOption,
  actionResetOptions,
  actionMessageOption,
  actionGetOptionsByPage,
  actionOrderOptions
};
