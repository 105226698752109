import LoginConstants from "./LoginConstants";

const initialState = {
  user: null,
  lastError: null,
  errorMessage: null
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LoginConstants.AUTHENTICATE_ACTION_TYPE:
      return {
        ...state,
        user: action.user
      };
    case LoginConstants.LOGOUT_ACTION_TYPE:
      return {
        ...state,
        user: null
      };
    case LoginConstants.LAST_ERROR_ACTION_TYPE:
      return {
        ...state,
        lastError: { error: action.error, time: new Date() }
      };
    case LoginConstants.MESSAGE_ACTION_TYPE:
      return {
        ...state,
        errorMessage: action.message
      };      
    default:
      return state;
  }
};

export default LoginReducer;
