import PreviewConstants from "./PreviewConstants";

const initialState = {
  categoryId: '',
  message: null,
  modalIsOpen: false
};

const PreviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case PreviewConstants.MESSAGE_ACTION_TYPE:
      return {
        ...state,
        message: action.message
      };
    case PreviewConstants.SELECT_OPEN_ACTION_TYPE:
      return {
        ...state,
        modalIsOpen: true
      };
    case PreviewConstants.SELECT_CLOSE_ACTION_TYPE:
      return {
        ...state,
        modalIsOpen: false
      };
    case PreviewConstants.SET_CATEGORY_ACTION_TYPE:
      return {
        ...state,
        categoryId: action.categoryId
      };
    default:
      return state;
  }
};

export default PreviewReducer;
