import WizardConstants from "./WizardConstants";

const initialState = {
  data: null,
  currentQuestion: null,
  message: null,
  lastIndex: 0,
  category: null,
  numberProducts: 0,
  loading: false
};

const getQuestion = (data, index) => {
  return data && data[index] ? data[index] : null;
};

const updateOption = (isMultipleChoice, options, optionId, isChecked) => {
  return options.map((item) => {
    return item.id === optionId ? { ...item, checked: isChecked } : isMultipleChoice ? item : { ...item, checked: false };
  });
};

const updateData = (data, questionId, options) => {
  return data.map((item) => {
    return item.id === questionId ? { ...item, options: options } : item;
  });
};

const backData = (data, indexBack) => {
  data[indexBack].options = data[indexBack].options.map(item => {
    return { ...item, checked: false };
  });
  return data;
};

const WizardReducer = (state = initialState, action) => {
  switch (action.type) {
    case WizardConstants.SET_CATEGORY_ACTION_TYPE:
      return {
        ...state,
        category: action.category
      };
    case WizardConstants.GET_SETUP_ACTION_TYPE:
      return {
        ...state,
        data: action.data,
        loading: false
      };
    case WizardConstants.GET_QUESTION_ACTION_TYPE:
      return {
        ...state,
        currentQuestion: getQuestion(state.data, action.index),
        lastIndex: action.index
      };
    case WizardConstants.UPDATE_OPTION_ACTION_TYPE:
      const updatedOptions = updateOption(state.currentQuestion.isMultipleChoice, state.currentQuestion.options, action.optionId, action.isChecked);
      const updatedData = updateData(state.data, state.currentQuestion.id, updatedOptions);
      return {
        ...state,
        data: updatedData,
        currentQuestion: {
          ...state.currentQuestion,
          options: updatedOptions
        }
      };
    case WizardConstants.RESET_SELECTED_ACTION_TYPE:
      return {
        ...state,
        currentQuestion: null,
        data: null,
        lastIndex: 0
      };
    case WizardConstants.MESSAGE_ACTION_TYPE:
      return {
        ...state,
        message: action.message
      };
    case WizardConstants.GET_NUMBER_PRODUCTS_ACTION_TYPE:
      return {
        ...state,
        numberProducts: action.numberProducts
      };
    case WizardConstants.LOADING_ACTION_TYPE:
      return {
        ...state,
        loading: true
      };
    case WizardConstants.BACK_ACTION_TYPE:
      return {
        ...state,
        data: backData(state.data, action.indexBack)
      };
    default:
      return state;
  }
};

export default WizardReducer;
