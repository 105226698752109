import React from 'react';
import NavAdmin from '../nav/NavAdmin';
import { Container } from 'reactstrap';
import './MasterAdmin.css';

const MasterAdmin = (props) => {
  return (
    <Container fluid className="admin">
      <NavAdmin />
      <Container fluid className="admin__container">
        {props.children}
      </Container>
    </Container>
  );
};

export default MasterAdmin;
