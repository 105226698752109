import React, { Component, Fragment } from 'react';
import { Container } from 'reactstrap';
import '../Admin.css';
import HeaderChildAdd from '../header/HeaderChildAdd';
import { withRouter } from 'react-router-dom';
import ListContainer from '../list/ListContainer';
import FeatureItem from './FeatureItem';
import PathsApp from '../../utils/PathsApp';
import { connect } from 'react-redux';
import { actionGetCategory } from '../categories/CategoryActions';
import { actionBeginAddFeature, actionSaveAddFeature, actionCancelAddFeature, actionSaveEditFeature, actionDeleteFeature, actionMessageFeature, actionGetFeaturesByPage } from './FeatureActions';
import AlertContainer from '../../alerts/AlertContainer';

class FeatureContainer extends Component {

  componentDidMount() {
    this.props.getCategory(this.props.match.params.id);
    this.props.getFeatures(this.props.match.params.id, 0);
  }

  handleAdd = (event) => {
    event.preventDefault();
    const newItem = this.props.features.find(i => i.id === '');
    if (newItem) {
      console.log('todo integrate'); //TODO INTEGRATE REDUX TO UPDATE LIST ITEMS
    } else {
      this.props.beginAdd();
    }
  };

  handleGetBack = (event) => {
    event.preventDefault();
    this.props.history.push(PathsApp.CATEGORIES_ROOT);
  };

  componentWillUnmount() {
    this.props.resetMessage();
  }

  handlePageClick = (data) => {
    this.props.getFeatures(this.props.match.params.id, data.selected);
  };

  render() {
    return (
      <Fragment>
        <AlertContainer alert={this.props.message} />
        <Container className="page-admin page-admin--feature" fluid>
        {
          this.props.category && this.props.features
            ? <Fragment>
                <HeaderChildAdd title={this.props.category.name} addText={"Add Feature"} handleAdd={this.handleAdd} handleGetBack={this.handleGetBack} />
                <Container className="page-admin__list-container" fluid>
                  <ListContainer
                    items={this.props.features}
                    Component={FeatureItem}
                    hasPagination={true}
                    saveAdd={this.props.saveAdd}
                    cancelAdd={this.props.cancelAdd}
                    parentId={this.props.category.id}
                    saveEdit={this.props.saveEdit}
                    delete={this.props.delete}
                    pageCount={this.props.pageCount}
                    handlePageClick={this.handlePageClick} />
              </Container>
            </Fragment>
            : null
        }        
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    category: state.category.selected,
    features: state.feature.items,
    message: state.feature.message,
    pageCount: state.feature.totalPages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCategory: (id) => dispatch(actionGetCategory(id)),
    getFeatures: (categoryId, page) => dispatch(actionGetFeaturesByPage(categoryId, page)),
    beginAdd: () => dispatch(actionBeginAddFeature()),
    saveAdd: (newFeature, categoryId) => dispatch(actionSaveAddFeature(newFeature, categoryId)),
    cancelAdd: () => dispatch(actionCancelAddFeature()),
    saveEdit: (editedFeature, categoryId) => dispatch(actionSaveEditFeature(editedFeature, categoryId)),
    delete: (id) => dispatch(actionDeleteFeature(id)),
    resetMessage: () => dispatch(actionMessageFeature(null))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeatureContainer));
