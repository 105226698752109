import getAPIPath from '../../utils/PathResolver';
import DistributorConstants from "./DistributorConstants";

export default class DistributorApi {
  static GetDistributors = (page) => { return `${getAPIPath()}distributor?page=${page}&pageSize=${DistributorConstants.PAGE_LIST_SIZE}`; }
  static get PostDistributor() { return `${getAPIPath()}distributor`; }
  static GetDistributor = (id) => { return `${getAPIPath()}distributor/GetById?id=${id}`; }
  static get PutDistributor() { return `${getAPIPath()}distributor`; }
  static ToggleDistributor = (id) => { return `${getAPIPath()}distributor/Toggle?id=${id}`; }
  static DeleteDistributor = (id) => { return `${getAPIPath()}distributor?id=${id}`; }
}
