import ErrorsApp from "../../utils/ErrorsApp";

export default class QuestionConstants {
  static get BEGIN_LIST_ACTION_TYPE() { return 'question/BEGIN_LIST'; }
  static get GET_ALL_BY_CATEGORY_ACTION_TYPE() { return 'question/GET_ALL_BY_CATEGORY'; }
  static get BEGIN_ADD_ACTION_TYPE() { return 'question/BEGIN_ADD'; }
  static get TOGGLE_ACTION_TYPE() { return 'question/TOGGLE'; }
  static get DELETE_ACTION_TYPE() { return 'question/DELETE'; }
  static get GET_ACTION_TYPE() { return 'question/GET'; }
  static get CHANGE_VALUE_ACTION_TYPE() { return 'question/CHANGE_VALUE'; }
  static get CHANGE_ANSWER_ACTION_TYPE() { return 'question/CHANGE_ANSWER'; }
  static get RESET_ANSWERS_ACTION_TYPE() { return 'question/RESET_ANSWERS'; }
  static get SET_ANSWERS_ACTION_TYPE() { return 'question/SET_ANSWERS'; }
  static get GET_LAYOUT_TYPES_ACTION_TYPE() { return 'question/GET_LAYOUT_TYPES'; }
  static get SAVE_ADD_ACTION_TYPE() { return 'question/SAVE_ADD'; }
  static get SAVE_EDIT_ACTION_TYPE() { return 'question/SAVE_EDIT'; }
  static get MESSAGE_LIST_ACTION_TYPE() { return 'question/MESSAGE_LIST'; }
  static get MESSAGE_LIST_ACTION_ACTION_TYPE() { return 'question/MESSAGE_LIST_ACTION'; }
  static get MESSAGE_ACTION_ACTION_TYPE() { return 'product/MESSAGE_ACTION'; }
  static get MESSAGE_OPTIONS_ACTION_TYPE() { return 'question/MESSAGE_OPTIONS'; }
  static get UPLOAD_IMAGE_ACTION_TYPE() { return 'question/UPLOAD_IMAGE'; }
  static get ORDER_ACTION_TYPE() { return 'question/ORDER'; }
  static get ORDER_ANSWERS_ACTION_TYPE() { return 'question/ORDER_ANSWERS'; }
  static get ADD_RANGE_ACTION_TYPE() { return 'question/ADD_RANGE'; }
  static get CHANGE_VALUE_RANGE_ACTION_TYPE() { return 'question/CHANGE_VALUE_RANGE'; }
  static get DELETE_RANGE_ACTION_TYPE() { return 'question/DELETE_RANGE'; }
  static get MESSAGE_LIST_NOT_FOUND() { return 'No questions found'; }
  static get MESSAGE_ADD_OK() { return { type: 'success', text: 'Question added succesfully.' }; }
  static MESSAGE_ADD_ERROR = (data) => { return { type: 'danger', text: ErrorsApp.GET_MESSAGE_TEXT(data) }; }
  static get MESSAGE_UPDATE_OK() { return { type: 'success', text: 'Question updated succesfully.' }; }
  static MESSAGE_UPDATE_ERROR = (data) => { return { type: 'danger', text: ErrorsApp.GET_MESSAGE_TEXT(data) }; }
  static get MESSAGE_LIST_ADD_ERROR() { return { type: 'danger ', text: 'Error creating question.' }; }
  static get MESSAGE_OPTIONS_NOT_FOUND() { return 'No options found'; }
}
