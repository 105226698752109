import UploadImageConstants from "./UploadImageConstants";

const actionSetErrorUploadImage = (key, isError) => {
  return {
    type: UploadImageConstants.ERROR_ACTION_TYPE,
    key: key,
    isError: isError
  };
};

export { actionSetErrorUploadImage }
