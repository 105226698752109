import UuidUtil from "../../utils/UuidUtil";

export default class QuestionRequestModel {
  constructor(id, orderNumber, text, isMultipleChoice, layoutTypeId, categoryId, featureId, helpInfo, isEnabled, answers) {
    this.id = id;
    this.text = text;
    this.orderNumber = orderNumber;
    this.isMultipleChoice = isMultipleChoice;
    this.layoutTypeId = layoutTypeId;
    this.categoryId = categoryId;
    this.featureId = featureId === '' ? UuidUtil.EMPTY : featureId;
    this.helpInfo = helpInfo;
    this.isEnabled = isEnabled;
    this.answers = answers;
  }
}
