import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import ButtonGroup from '../buttons/ButtonGroup';
import BaseItem from '../items/BaseItem';
import { connect } from 'react-redux';
import { actionOpenProductOptions, actionUpdateProductFeature } from './ProductAction';
import { actionGetOptions } from '../options/OptionActions';

class ProductFeatureItem extends BaseItem{

  handleEdit = (event) => {
    event.preventDefault();
    this.props.getOptions(this.props.item.featureCode);
    this.props.openOptions(this.props.item.featureCode, this.props.item.options);    
  };

  handleVisible = (event) => {
    event.preventDefault();
    this.props.updateFeature(this.props.item.featureCode);
  };

  resumeOptions = () => {
    return this.props.item.options.map(o => o.optionName).join();
  };

    render() {
    const resume = this.resumeOptions();
    return (
      <Row className="item-admin product-feature" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} >
        <Col lg="9" className="item-admin__text" onClick={this.handleEdit}>
          <div><b>{this.props.item.featureName}</b></div>
          <div className="product-feature__options">{resume}</div>
        </Col>
        <Col lg="3" className="product-feature__buttons">
          <Row>
            {
              this.state.isMouseInside ? <ButtonGroup handleEdit={this.handleEdit} handleDelete={null} handleVisible={this.handleVisible} itemIsVisible={this.props.item.isEnabled} viewIsVisible={true} /> : null
            }
          </Row>
        </Col>
      </Row>
    );
  }
}

ProductFeatureItem.propTypes = {
  item: PropTypes.object
};

const mapDispatchToProps = dispatch => {
  return {
    openOptions: (featureId, options) => dispatch(actionOpenProductOptions(featureId, options)),
    getOptions: (featureId) => dispatch(actionGetOptions(featureId)),
    updateFeature: (featureId) => dispatch(actionUpdateProductFeature(featureId))
  }
};

export default connect(null, mapDispatchToProps)(ProductFeatureItem);
