export default class FeatureConstants {
  static get GET_PAGED_ACTION_TYPE() { return 'feature/GET_PAGED'; }
  static get GET_ALL_ACTION_TYPE() { return 'feature/GET_ALL'; }
  static get GET_ACTION_TYPE() { return 'feature/GET'; }
  static get BEGIN_ADD_ACTION_TYPE() { return 'feature/BEGIN_ADD'; }
  static get SAVE_ADD_ACTION_TYPE() { return 'feature/SAVE_ADD'; }
  static get CANCEL_ADD_ACTION_TYPE() { return 'feature/CANCEL_ADD'; }
  static get SAVE_EDIT_ACTION_TYPE() { return 'feature/SAVE_EDIT'; }
  static get DELETE_ACTION_TYPE() { return 'feature/DELETE'; }
  static get RESET_ACTION_TYPE() { return 'feature/RESET'; }
  static get MESSAGE_ACTION_TYPE() { return 'feature/MESSAGE'; }
  static get MESSAGE_ADD_OK() { return { type: 'success', text: 'Feature added succesfully.' }; }
  static get MESSAGE_ADD_ERROR() { return { type: 'danger', text: 'Error adding category.' }; }
  static get MESSAGE_UPDATE_OK() { return { type: 'success', text: 'Feature updated succesfully.' }; }
  static get MESSAGE_UPDATE_ERROR() { return { type: 'danger', text: 'Error updating category.' }; }
  static get PAGE_SIZE() { return 10; }
}
