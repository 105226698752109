import React, { Fragment } from 'react';

const MasterLogin = (props) => {
  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
}

export default MasterLogin;
