import CategoryConstants from "./CategoryConstants";
import axios from 'axios';
import CategoryApi from "./CategoryApi";
import { actionErrorAuth } from "../login/LoginAction";



const getCategory = async (dispatch, id) => {
  try {
    const response = await axios.get(CategoryApi.GetCategory(id));
    if (response.data) {
      dispatch({
        type: CategoryConstants.GET_ACTION_TYPE,
        selected: response.data
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: CategoryConstants.GET_ALL_ACTION_TYPE,
      items: null
    });
  }
}

const getCategories = async (dispatch) => {
  try {
    const response = await axios.get(CategoryApi.GetCategories);
    if (response.data) {
      dispatch({
        type: CategoryConstants.GET_ALL_ACTION_TYPE,
        items: response.data.length > 0 ? response.data : null
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: CategoryConstants.GET_ALL_ACTION_TYPE,
      items: null
    });
  }
}

const saveCategory = async (dispatch, category) => {
  try {
    const response = await axios.post(CategoryApi.PostCategory, category);
    if (response.data) {
      dispatch(actionMessageCategory(CategoryConstants.MESSAGE_ADD_OK));
      dispatch({
        type: CategoryConstants.SAVE_ADD_ACTION_TYPE,
        newCategory: response.data
      });
    } else {      
      dispatch(actionMessageCategory(CategoryConstants.MESSAGE_ADD_ERROR));
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch(actionMessageCategory(CategoryConstants.MESSAGE_ADD_ERROR));
  }
};

const editCategory = async (dispatch, category) => {
  try {
    const response = await axios.put(CategoryApi.PutCategory, category);
    if (response.data) {
      dispatch(actionMessageCategory(CategoryConstants.MESSAGE_UPDATE_OK));
      dispatch({
        type: CategoryConstants.SAVE_EDIT_ACTION_TYPE,
        editedCategory: category
      });
    } else {
      dispatch(actionMessageCategory(CategoryConstants.MESSAGE_UPDATE_ERROR));
      dispatch({
        type: CategoryConstants.CANCEL_EDIT_ACTION_TYPE
      });
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch(actionMessageCategory(CategoryConstants.MESSAGE_UPDATE_ERROR));
    dispatch({
      type: CategoryConstants.CANCEL_EDIT_ACTION_TYPE
    });
  }  
};

const deleteCategory = async (dispatch, id) => {
  try {
    const response = await axios.delete(CategoryApi.DeleteCategory(id));
    if (response.data) {
      dispatch({
        type: CategoryConstants.DELETE_ACTION_TYPE,
        id: id
      });
    } else {
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const actionGetCategories = () => {
  return dispatch => {
    getCategories(dispatch);
  };
};

const actionGetCategory = (id) => {
  return dispatch => {
    getCategory(dispatch, id);
  };
};

const actionBeginAddCategory = () => {
  return {
    type: CategoryConstants.BEGIN_ADD_ACTION_TYPE
  };
};

const actionSaveAddCategory = (category) => {
  return dispatch => {
    saveCategory(dispatch, category);
  };
};

const actionCancelAddCategory = () => {
  return {
    type: CategoryConstants.CANCEL_ADD_ACTION_TYPE
  };
};

const actionSaveEditCategory = (category) => {
  return dispatch => {
    editCategory(dispatch, category);
  };
};

const actionDeleteCategory = (id) => {
  return dispatch => {
    deleteCategory(dispatch, id);
  };
};

const actionMessageCategory = (message) => {
  return {
    type: CategoryConstants.MESSAGE_ACTION_TYPE,
    message: message
  };
};

export {
  actionGetCategories,
  actionGetCategory,
  actionBeginAddCategory,
  actionSaveAddCategory,
  actionCancelAddCategory,
  actionSaveEditCategory,
  actionDeleteCategory,
  actionMessageCategory
};
