import BaseModel from "../../models/BaseModel";

export default class ProductModel extends BaseModel {
  constructor(id, name, sku, category, description, shortDescription, url, imageUrl, thumbnailImageUrl, isEnabled, productFeatures, exists) {
    super(id, name);
    this.sku = sku;
    this.category = category;
    this.description = description;
    this.shortDescription = shortDescription;
    this.url = url;
    this.imageUrl = imageUrl;
    this.thumbnailImageUrl = thumbnailImageUrl;
    this.isEnabled = isEnabled;
    this.productFeatures = productFeatures;
    this.exists = exists;
  }

  static getEmpty = () => {
    return {
      id: '',
      name: '',
      sku: '',
      category: BaseModel.getEmpty(),
      description: '',
      shortDescription: '',
      url: '',
      imageUrl: '',
      thumbnailImageUrl: '',
      isEnabled: true,
      productFeatures: [],
      exists: true
    };
  };
}
