import React from 'react';
import ButtonAdd from '../buttons/ButtonAdd';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const HeaderAdd = (props) => {
  return (
    <Container className="page-admin__header" fluid>
      <Row>
        <Col className="text-left">
          <h2>{props.title}</h2>
        </Col>
        <Col className="text-right">
          <ButtonAdd text={props.addText} handleAdd={props.handleAdd} />
        </Col>
      </Row>
    </Container>
  );
};

HeaderAdd.propTypes = {
  title: PropTypes.string,
  addText: PropTypes.string,
  handleAdd: PropTypes.func
};

export default HeaderAdd;
