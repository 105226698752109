import React from 'react';
import { Alert } from 'reactstrap';

const AlertContainer = (props) => {
  if (props.alert) {
    return (
      <Alert className={props.className} color={props.alert.type}>{props.alert.text}</Alert>
    );
  } else {
    return null;
  }
};

export default AlertContainer;
