import React from 'react';
import { Row, Col } from 'reactstrap';
import ButtonGroup from '../buttons/ButtonGroup';
import { withRouter } from 'react-router-dom';
import BaseItem from '../items/BaseItem';
import PathsApp from '../../utils/PathsApp';
import PropTypes from 'prop-types';

class ProductItem extends BaseItem {

  componentDidMount() {
    this.setState({
      id: this.props.item.id,
      name: this.props.item.name,
      itemIsVisible: this.props.item.isEnabled
    });
  }

  handleEdit = (event) => {
    event.preventDefault();
    this.props.history.push(PathsApp.PRODUCTS_EDIT_BASE + this.props.item.id);
  };

  handleDelete = (event) => {
    event.preventDefault();
    this.props.delete(this.props.item.id, true);
  };

  handleVisible = (event) => {
    event.preventDefault();
    this.props.handleVisible(this.props.item.id);
  };

  render() {
    return (
      <Row className="item-admin" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        <Col lg="4" className="item-admin__code" onClick={this.handleEdit}>{this.props.item.sku}</Col>
        <Col lg="5" className="item-admin__text" onClick={this.handleEdit}>{this.props.item.name}</Col>
        <Col lg="3">
          <Row>
            {
              this.state.isMouseInside ? <ButtonGroup handleEdit={this.handleEdit} handleDelete={this.handleDelete} handleVisible={this.handleVisible} itemIsVisible={this.props.item.isEnabled} viewIsVisible={true} /> : null
            }
          </Row>
        </Col>
      </Row>
    );
  }
}

ProductItem.propTypes = {
  item: PropTypes.object
};

export default withRouter(ProductItem);