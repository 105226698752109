import React from 'react';
import { Container } from 'reactstrap';
import NavClient from '../nav/NavClient';
import './MasterClient.css';

const MasterClient = (props) => {
  return (
    <Container className="master-client" fluid>
      <NavClient />
      <Container fluid className="master-client__container">
        {props.children}
      </Container>
    </Container>
  );
};

export default MasterClient;
