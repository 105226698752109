import React from 'react';
import { Container } from 'reactstrap';
import './MasterPreview.css'

const MasterPreview = (props) => {
  return (
    <Container className="preview-master" fluid>
      {props.children}
    </Container>
  );
}

export default MasterPreview;
