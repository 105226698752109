export default class PathsApp {
  static get LOGIN_ROOT() { return PathsApp.ADMIN_PATH; }
  static get QUESTIONS_ROOT() { return `${PathsApp.ADMIN_PATH}/questions`; }
  static get QUESTIONS_ADD() { return `${PathsApp.ADMIN_PATH}/questions/add`; }
  static get QUESTIONS_EDIT() { return `${PathsApp.ADMIN_PATH}/questions/edit/:id`; }
  static get QUESTIONS_EDIT_BASE() { return `${PathsApp.ADMIN_PATH}/questions/edit/`; }
  static get PRODUCTS_ROOT() { return `${PathsApp.ADMIN_PATH}/products`; }
  static get PRODUCTS_ADD() { return `${PathsApp.ADMIN_PATH}/products/add`; }
  static get PRODUCTS_EDIT_BASE() { return `${PathsApp.ADMIN_PATH}/products/edit/`; }
  static get PRODUCTS_EDIT() { return `${PathsApp.ADMIN_PATH}/products/edit/:id`; }
  static get CATEGORIES_ROOT() { return `${PathsApp.ADMIN_PATH}/categories`; }
  static get LAYOUT_ROOT() { return `${PathsApp.ADMIN_PATH}/layout`; }
  static get PREVIEW_ROOT() { return `${PathsApp.ADMIN_PATH}/preview`; }
  static get FEATURES_ROOT() { return `${PathsApp.ADMIN_PATH}/categories/edit/:id`; }
  static get FEATURES_BASE() { return `${PathsApp.ADMIN_PATH}/categories/edit/`; }
  static get OPTIONS_ROOT() { return `${PathsApp.ADMIN_PATH}/categories/features/edit/:id`; }
  static get OPTIONS_BASE() { return `${PathsApp.ADMIN_PATH}/categories/features/edit/`; }
  static get DISTRIBUTORS_ROOT() { return `${PathsApp.ADMIN_PATH}/distributors`; }
  static get DISTRIBUTORS_ADD() { return `${PathsApp.ADMIN_PATH}/distributors/add`; }
  static get DISTRIBUTORS_EDIT_BASE() { return `${PathsApp.ADMIN_PATH}/distributors/edit/`; }
  static get DISTRIBUTORS_EDIT() { return `${PathsApp.ADMIN_PATH}/distributors/edit/:id`; }
  static get WIZARD_HOME_ROOT() { return '/:category/:distributor?'; }
  static get WIZARD_STEPS_ROOT() { return '/:category/:distributor/wizard'; }
  static get WIZARD_RESULT_ROOT() { return '/:category/:distributor/result'; }
  static WIZARD_PATH(path, category, distributor) { return path.replace(':category', category).replace(':distributor', distributor) }
  static get ADMIN_PATH() { return '/ng-admin'; }
}