import BaseModel from "../../models/BaseModel";

export default class DistributorClientModel extends BaseModel {
  constructor(id, name, layoutUrl) {
    super(id, name);
    this.layoutUrl = layoutUrl;
  }

  static getEmpty() {
    return new DistributorClientModel('', '', '');
  }
}