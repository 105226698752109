class WizardConstants {
  static get PREVIEW() { return 'preview'; }
  static get CLIENT() { return 'client'; }
  static get GET_SETUP_ACTION_TYPE() { return 'wizard/GET_SETUP'; }
  static get SET_CATEGORY_ACTION_TYPE() { return 'wizard/SET_CATEGORY'; }
  static get GET_QUESTION_ACTION_TYPE() { return 'wizard/GET_QUESTION'; }
  static get UPDATE_OPTION_ACTION_TYPE() { return 'wizard/UPDATE_OPTION'; }
  static get RESET_SELECTED_ACTION_TYPE() { return 'wizard/RESET_SELECTED'; }
  static get GET_NUMBER_PRODUCTS_ACTION_TYPE() { return 'wizard/GET_NUMBER_PRODUCTS'; }
  static get MESSAGE_ACTION_TYPE() { return 'wizard/MESSAGE'; }
  static get LOADING_ACTION_TYPE() { return 'wizard/LOADING'; }
  static get BACK_ACTION_TYPE() { return 'wizard/BACK'; }
  static get MESSAGE_ERROR() { return { type: 'danger', text: 'Invalid path.' }; }
}

export default WizardConstants;
