import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Alert } from 'reactstrap';
import '../Admin.css';
import HeaderAdd from '../header/HeaderAdd';
import ListContainer from '../list/ListContainer';
import DistributorItem from './DistributorItem';
import { actionBeginListDistributors, actionToggleDistributor, actionDeleteDistributor, actionGetDistributors, actionMessageDistributor } from './DistributorAction';
import PathsApp from '../../utils/PathsApp';
import AlertContainer from '../../alerts/AlertContainer';

class DistributorListContainer extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0
    }
  }

  componentDidMount() {
    this.props.beginList();
  }

  handlePageClick = (data) => {
    this.props.getDistributors(data.selected);
    this.setState({ currentPage: data.selected });
  };

  handleAdd = (event) => {
    event.preventDefault();
    this.props.history.push(PathsApp.DISTRIBUTORS_ADD);
  };

  componentWillUnmount() {
    this.props.resetMessage();
  }

  render() {
    return (
      <Fragment>
        <AlertContainer alert={this.props.messageListAction} />
        <Container className="page-admin page-admin--feature" fluid>
          <HeaderAdd title={"Distributors"} addText={"Add Distributor"} handleAdd={this.handleAdd} />
          <Container className="page-admin__list-container" fluid>
            {
              this.props.distributors ?
                <ListContainer
                  items={this.props.distributors}
                  Component={DistributorItem}
                  handleVisible={this.props.toggle}
                  delete={this.props.delete}
                  hasPagination={true}
                  pageCount={this.props.pageCount}
                  handlePageClick={this.handlePageClick} />
                : null
            }
            {
              this.props.message ?
                <Alert className="mt-2" color="warning">{this.props.message}</Alert>
                : null
            }
          </Container>
        </Container>
      </Fragment>      
    );
  }
}

const mapStateToProps = state => {
  return {
    distributors: state.distributor.items,
    pageCount: state.distributor.totalPages,
    message: state.distributor.messageList,
    messageListAction: state.distributor.messageListAction
  };
};

const mapDispatchToProps = dispatch => {
  return {
    beginList: () => dispatch(actionBeginListDistributors()),
    toggle: (distributorId) => dispatch(actionToggleDistributor(distributorId)),
    delete: (distributorId, isFromList) => dispatch(actionDeleteDistributor(distributorId, isFromList)),
    getDistributors: (page) => dispatch(actionGetDistributors(page)),
    resetMessage: () => dispatch(actionMessageDistributor(null))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DistributorListContainer));
