import React, { Component, Fragment } from 'react';
import { Container, Alert } from 'reactstrap';
import '../Admin.css';
import './ProductListContainer.css';
import { withRouter } from 'react-router-dom';
import HeaderAdd from '../header/HeaderAdd';
import ListContainer from '../list/ListContainer';
import ProductItem from './ProductItem';
import PathsApp from '../../utils/PathsApp';
import { connect } from 'react-redux';
import { actionBeginListProducts, actionGetProducts, actionSetSearchProduct, actionSearchProducts, actionToggleProduct, actionDeleteProduct, actionMessageProduct, actionResetOrderByProduct, actionResetMessageList } from './ProductAction';
import AlertContainer from '../../alerts/AlertContainer';
import ProductFilter from './ProductFilter';
import ProductSortEnum from './ProductSortEnum';

class ProductListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0
    }
  }

  componentDidMount() {
    this.props.changeSearch('');
    this.props.beginList(ProductSortEnum.SKU);
  }

  handleAdd = (event) => {
    event.preventDefault();
    this.props.history.push(PathsApp.PRODUCTS_ADD);
  };

  handleSort = (event) => {
    this.props.getProducts(event.target.value, this.state.currentPage);
  };

  handleChangeSearch = (event) => {
    this.props.changeSearch(event.target.value);
  };

  handleSearchEnter = (event) => {
    if (event.key === 'Enter') {
      this.props.searchByName(this.props.search, this.props.sortBy, this.state.currentPage);
    }
  };

  handleSearchButton = (event) => {
    event.preventDefault();
    this.props.searchByName(this.props.search, this.props.sortBy, this.state.currentPage);
  };

  componentWillUnmount() {
    this.props.resetMessageList();
    this.props.changeSearch('');
    this.props.resetMessage();
    this.props.resetSort();
  }

  handlePageClick = (data) => {
    this.props.searchByName(this.props.search, this.props.sortBy, data.selected);
    this.setState({ currentPage: data.selected });
  };

  render() {    
    return (
      <Fragment>
        <AlertContainer alert={this.props.messageListAction} />
        <Container className="page-admin page-admin--feature" fluid>
          <HeaderAdd title={"Products"} addText={"Add Product"} handleAdd={this.handleAdd} />
          <Container className="page-admin__list-container" fluid>
            <ProductFilter
              handleChangeSearch={this.handleChangeSearch}
              search={this.props.search}
              handleSearchEnter={this.handleSearchEnter}
              handleSearchButton={this.handleSearchButton}
              handleSort={this.handleSort}
              sortBy={this.props.sortBy}/>
            {
              this.props.products ?
                <ListContainer
                  items={this.props.products}
                  Component={ProductItem}
                  handleVisible={this.props.toggle}
                  delete={this.props.delete}
                  hasPagination={true}
                  pageCount={this.props.pageCount}
                  handlePageClick={this.handlePageClick}/>
                : null
            }
            {
              this.props.message ?
                <Alert className="mt-2" color="warning">{this.props.message}</Alert>
                : null
            }
          </Container>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.product.items,
    message: state.product.messageList,
    sortBy: state.product.sortBy,
    search: state.product.search,
    pageCount: state.product.totalPages,
    messageListAction: state.product.messageListAction
  };
};

const mapDispatchToProps = dispatch => {
  return {
    beginList: (sortBy) => dispatch(actionBeginListProducts(sortBy)),
    getProducts: (sortBy, page) => dispatch(actionGetProducts(sortBy, page)),
    changeSearch: (search) => dispatch(actionSetSearchProduct(search)),
    searchByName: (search, sortBy, page) => dispatch(actionSearchProducts(search, sortBy, page)),
    toggle: (productId) => dispatch(actionToggleProduct(productId)),
    delete: (productId, isFromList) => dispatch(actionDeleteProduct(productId, isFromList)),
    resetMessage: () => dispatch(actionMessageProduct(null)),
    resetSort: () => dispatch(actionResetOrderByProduct()),
    resetMessageList: () => dispatch(actionResetMessageList())
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListContainer));
