export default class LayoutModel {
  constructor(id, title, titleResult, description, descriptionResult, imageUrl, discoverMoreResult) {
    this.id = id;
    this.title = title;
    this.titleResult = titleResult;
    this.description = description;
    this.descriptionResult = descriptionResult;
    this.imageUrl = imageUrl;
    this.discoverMoreResult = discoverMoreResult;
  }

  static getEmpty = () => {
    return {
      id: '',
      title: '',
      titleResult: '',
      description: '',
      descriptionResult: '',
      imageUrl: '',
      discoverMoreResult: ''
    }
  };
}
