import React from 'react';
import { Row, Col } from 'reactstrap';
import PaginationContainer from '../pagination/PaginationContainer';
import PropTypes from 'prop-types';

const ListContainer = (props) => {
  let listItems = null;
  if (props.items && props.Component) {
    listItems = props.items.map(item =>
      <props.Component
        key={item.id}
        item={item}
        saveAdd={props.saveAdd}
        cancelAdd={props.cancelAdd}
        saveEdit={props.saveEdit}
        delete={props.delete}
        parentId={props.parentId}
        handleVisible={props.handleVisible}
        updateList={props.updateList}
        handleFile={props.handleFile} />);
  }
  return (
    <div>
      <Row className="list-admin">
        <Col className="pl-0 pr-0">
          {listItems}
        </Col>
      </Row>
      {
        props.hasPagination ? <Row className="pt-4"><PaginationContainer pageCount={props.pageCount} handlePageClick={props.handlePageClick} /></Row> : null
      }      
    </div>
  );
};

ListContainer.propTypes = {
  items: PropTypes.array,
  Component: PropTypes.elementType,
  saveAdd: PropTypes.func,
  cancelAdd: PropTypes.func,
  saveEdit: PropTypes.func,
  delete: PropTypes.func,
  parentId: PropTypes.string,
  handleFile: PropTypes.func,
  pageCount: PropTypes.number,
  handlePageClick: PropTypes.func
};

export default ListContainer;
