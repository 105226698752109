export default class ErrorsApp {
  static GET_MESSAGE_TEXT(data) {
    switch (typeof data) {
      case 'string':
        return data;
      case 'object':
        return [].concat.apply([], Object.values(data)).join('. ');
      default:
        return data;
    }
  }
}
