import React from 'react';
import ItemParent from '../items/ItemParent';
import PathsApp from '../../utils/PathsApp';
import PropTypes from 'prop-types';

const CategoryItem = (props) => {
  return (
    <ItemParent
      placeholderText={"Add category name"}
      item={props.item}
      path={PathsApp.FEATURES_BASE}
      saveAdd={props.saveAdd}
      cancelAdd={props.cancelAdd}
      saveEdit={props.saveEdit}
      delete={props.delete} />
  );
};

CategoryItem.propTypes = {
  item: PropTypes.object,
  path: PropTypes.string,
  saveAdd: PropTypes.func,
  cancelAdd: PropTypes.func,
  saveEdit: PropTypes.func,
  delete: PropTypes.func
};

export default CategoryItem;
