import React, { Component } from 'react';
import { Switch } from 'react-router'
import QuestionListContainer from '../admin/questions/QuestionListContainer';
import ProductListContainer from '../admin/products/ProductListContainer';
import CategoryContainer from '../admin/categories/CategoryContainer';
import LayoutContainer from '../admin/layout/LayoutContainer';
import MasterAdmin from '../admin/master/MasterAdmin';
import MasterLogin from '../admin/master/MasterLogin';
import MasterPreview from '../admin/master/MasterPreview';
import AppRoute from './AppRoute';
import LoginContainer from '../admin/login/LoginContainer';
import ProductContainer from '../admin/products/ProductContainer';
import FeatureContainer from '../admin/features/FeatureContainer';
import OptionContainer from '../admin/options/OptionContainer';
import QuestionContainer from '../admin/questions/QuestionContainer';
import PreviewContainer from '../admin/preview/PreviewContainer';
import PathsApp from '../utils/PathsApp';
import HomeContainer from '../client/home/HomeContainer';
import MasterClient from '../client/master/MasterClient';
import StepsContainer from '../client/steps/StepsContainer';
import ResultContainer from '../client/result/ResultContainer';
import { connect } from 'react-redux';
import { actionGetSession } from '../admin/login/LoginAction';
import { withRouter } from 'react-router-dom';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import BrowserUtil from '../utils/BrowserUtil';
import InternetExplorerContainer from '../browser/InternetExplorerContainer';
import DistributorListContainer from '../admin/distributors/DistributorListContainer';
import DistributorContainer from '../admin/distributors/DistributorContainer';

class App extends Component {
  componentDidMount() {
    this.props.getSession();
  }

  render() {
    if (BrowserUtil.ISIE()) {
      return (
        <MasterClient>
          <InternetExplorerContainer />
        </MasterClient>
      );
    } else {
      return (
        <Switch>
          <UnauthenticatedRoute exact path={PathsApp.LOGIN_ROOT} layout={MasterLogin} component={LoginContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute exact path={PathsApp.QUESTIONS_ROOT} layout={MasterAdmin} component={QuestionListContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute exact path={PathsApp.PRODUCTS_ROOT} layout={MasterAdmin} component={ProductListContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute exact path={PathsApp.CATEGORIES_ROOT} layout={MasterAdmin} component={CategoryContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute exact path={PathsApp.DISTRIBUTORS_ROOT} layout={MasterAdmin} component={DistributorListContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute path={PathsApp.LAYOUT_ROOT} layout={MasterAdmin} component={LayoutContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute path={PathsApp.PRODUCTS_ADD} layout={MasterAdmin} component={ProductContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute path={PathsApp.PRODUCTS_EDIT} layout={MasterAdmin} component={ProductContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute path={PathsApp.QUESTIONS_ADD} layout={MasterAdmin} component={QuestionContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute path={PathsApp.QUESTIONS_EDIT} layout={MasterAdmin} component={QuestionContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute path={PathsApp.DISTRIBUTORS_ADD} layout={MasterAdmin} component={DistributorContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute path={PathsApp.DISTRIBUTORS_EDIT} layout={MasterAdmin} component={DistributorContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute path={PathsApp.FEATURES_ROOT} layout={MasterAdmin} component={FeatureContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute path={PathsApp.OPTIONS_ROOT} layout={MasterAdmin} component={OptionContainer} isAuthenticated={this.props.isAuthenticated} />
          <AuthenticatedRoute path={PathsApp.PREVIEW_ROOT} layout={MasterPreview} component={PreviewContainer} isAuthenticated={this.props.isAuthenticated} />          
          <AppRoute exact path={PathsApp.WIZARD_HOME_ROOT} layout={MasterClient} component={HomeContainer} />
          <AppRoute path={PathsApp.WIZARD_STEPS_ROOT} layout={MasterClient} component={StepsContainer} />
          <AppRoute path={PathsApp.WIZARD_RESULT_ROOT} layout={MasterClient} component={ResultContainer} />
        </Switch>
      );
    }    
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.login.user ? true :  false,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSession: () => dispatch(actionGetSession())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));