class BaseModel {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  static getEmpty = () => { return { id: '', name: '' }; };

  static isEmpty = (model) => {
    return model.id === '';
  };
}

export default BaseModel;
