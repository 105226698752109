import React from 'react';
import ItemParent from '../items/ItemParent';
import PathsApp from '../../utils/PathsApp';
import PropTypes from 'prop-types';

const FeatureItem = (props) => {
  return (
    <ItemParent
      placeholderText={"Add feature name"}
      item={props.item}
      path={PathsApp.OPTIONS_BASE}
      saveAdd={props.saveAdd}
      cancelAdd={props.cancelAdd}
      saveEdit={props.saveEdit}
      parentId={props.parentId}
      delete={props.delete}/>
  );
};

FeatureItem.propTypes = {
  item: PropTypes.object,
  path: PropTypes.string,
  saveAdd: PropTypes.func,
  cancelAdd: PropTypes.func,
  saveEdit: PropTypes.func,
  delete: PropTypes.func
};

export default FeatureItem;
