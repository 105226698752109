import React, { Component } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import NavApp from '../../nav/NavApp';
import Logo from '../../images/Netgear_Business2x.png';
import PathsApp from '../../utils/PathsApp';
import './NavClient.css';
import { withRouter } from 'react-router-dom';

class NavClient extends Component {
  render() {
    return (
      <NavApp>
        <Navbar className="navbar-expand-lg nav-client" light>
          <NavbarBrand tag={RRNavLink} to={PathsApp.WIZARD_PATH(PathsApp.WIZARD_HOME_ROOT, this.props.match.params.category, this.props.match.params.distributor)}>
            <img src={Logo} alt="logo" className="nav-client__logo" />
          </NavbarBrand>
        </Navbar>
      </NavApp>
    );
  }  
}

export default withRouter(NavClient);
