import React from 'react';
import { Row, Col } from 'reactstrap';
import ProductSelectItem from './ProductSelectItem';
import PropTypes from 'prop-types';

const ProductSelectList = (props) => {
  let productsItems = null;
  if (props.items) {
    productsItems = props.items.map(item => <ProductSelectItem key={item.id} item={item} handleChangeItem={props.handleChangeItem} source={props.source} />);
  }

  return (    
    <Row className="list-admin" >
      <Col className="pl-0 pr-0">
        {productsItems}
      </Col>
     </Row>
  );
};

ProductSelectList.propTypes = {
  items: PropTypes.array
};

export default ProductSelectList;
