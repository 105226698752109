import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ButtonGroupDetail from '../buttons/ButtonGroupDetail';
import Back from '../../images/chevLeft.png';
import PropTypes from 'prop-types';

const HeaderSave = (props) => {
  return (
    <Container className="page-admin__header" fluid>
      <Row>
        <Col>
          <div className="page-admin__group">
            <button className="page-admin__back" onClick={props.handleGetBack}>
              <img alt="back" src={Back} className="page-admin__icon" />
            </button>
            <h2 className="pl-3">{props.title}</h2>
          </div>
        </Col>
        <ButtonGroupDetail handleVisible={props.handleVisible} handleSave={props.handleSave} handleDelete={props.handleDelete} isVisible={props.isVisible} />
      </Row>
    </Container>
  );
};

HeaderSave.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  handleVisible: PropTypes.func,
  handleGetBack: PropTypes.func
};

export default HeaderSave;
