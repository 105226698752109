import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';

const HeaderSaveSimple = (props) => {
  return (
    <Container className="page-admin__header" fluid>
      <Row>
        <Col>
          <h2>{props.title}</h2>
        </Col>
        <Col className="text-right">
          <Button className="button-save text-center" onClick={props.handleSave} color="primary">Save</Button>
        </Col>
      </Row>
    </Container>
  );
};

HeaderSaveSimple.propTypes = {
  title: PropTypes.string,
  handleSave: PropTypes.func
};

export default HeaderSaveSimple;
