import React from 'react';
import { Row, Col } from 'reactstrap';
import ProductDistributorItem from './ProductDistributorItem';
import PropTypes from 'prop-types';

const ProductsDistributor = (props) => {
  let productsItems = null;
  if (props.items) {
    productsItems = props.items.map(item => <ProductDistributorItem key={item.id} item={item} isError={item.isError} url={item.url}/>);
  }

  return (    
    <Row className="list-admin" >
      <Col className="pl-0 pr-0">
        {productsItems}
      </Col>
    </Row>
  );
};

ProductsDistributor.propTypes = {
  items: PropTypes.array
};

export default ProductsDistributor;
