export default class OptionConstants {
  static get GET_PAGED_ACTION_TYPE() { return 'option/GET_PAGED'; }
  static get GET_ALL_ACTION_TYPE() { return 'option/GET_ALL'; }
  static get BEGIN_ADD_ACTION_TYPE() { return 'option/BEGIN_ADD'; }
  static get SAVE_ADD_ACTION_TYPE() { return 'option/SAVE_ADD'; }
  static get CANCEL_ADD_ACTION_TYPE() { return 'option/CANCEL_ADD'; }
  static get SAVE_EDIT_ACTION_TYPE() { return 'option/SAVE_EDIT'; }
  static get DELETE_ACTION_TYPE() { return 'option/DELETE'; }
  static get RESET_ACTION_TYPE() { return 'option/RESET'; }
  static get MESSAGE_ACTION_TYPE() { return 'option/MESSAGE'; }
  static get ORDER_ACTION_TYPE() { return 'option/ORDER'; }
  static get MESSAGE_ADD_OK() { return { type: 'success', text: 'Option added succesfully.' }; }
  static get MESSAGE_ADD_ERROR() { return { type: 'danger', text: 'Error adding option.' }; }
  static get MESSAGE_UPDATE_OK() { return { type: 'success', text: 'Option updated succesfully.' }; }
  static get MESSAGE_UPDATE_ERROR() { return { type: 'danger', text: 'Error updating option.' }; }
  static get PAGE_SIZE() { return 10; }
}
