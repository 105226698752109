import React, { Component, Fragment } from 'react';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Label, Input } from 'reactstrap';
import '../Admin.css';
import classnames from 'classnames';
import './ProductContainer.css';
import { withRouter } from 'react-router-dom';
import ProductFeatures from './ProductFeatures';
import HeaderSave from '../header/HeaderSave';
import PathsApp from '../../utils/PathsApp';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { actionBeginAddProduct, actionChangeCategoryProduct, actionChangeValueProduct, actionBeginEditProduct, actionUploadThumbnailProduct, actionUploadImageProduct, actionSaveAddProduct, actionSaveEditProduct, actionToggleProduct, actionDeleteProduct, actionMessageProductDetail } from './ProductAction';
import UploadImageContainer from '../uploadImage/UploadImageContainer';
import ProductModel from './ProductModel';
import ProductConstants from './ProductConstants';
import AlertContainer from '../../alerts/AlertContainer';

class ProductContainer extends Component {
  constructor(props) {
    super(props);
      
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'      
    };
  }

  componentDidMount() {
    if (this.props.match.path === PathsApp.PRODUCTS_ADD) {
      this.props.beginAdd();
    } else {
      this.props.beginEdit(this.props.match.params.id);
    }      
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  handleGetBack = (event) => {
    event.preventDefault();
    this.props.history.push(PathsApp.PRODUCTS_ROOT);
  };

  handleVisible = (event) => {
    event.preventDefault();
    if (!ProductModel.isEmpty(this.props.currentProduct)) {
      this.props.toggle(this.props.currentProduct.id);
    } else {
      console.log('TODO-can not toggle message');
    }    
  };

  changeCategory = (event) => {
    this.props.changeCategory(event.target.value);
    this.props.changeInputValue('category', this.props.categories.find(category => category.id === event.target.value));
  };

  handleChangeInput = (event) => {
    this.props.changeInputValue(event.target.id, event.target.value);
  };

  handleSave = (event) => {
    event.preventDefault();
    if (!ProductModel.isEmpty(this.props.currentProduct)) {
      this.props.saveEdit(this.props.currentProduct);
    } else {
      this.props.saveAdd(this.props.currentProduct);
    }
  };

  handleDelete = (event) => {
    event.preventDefault();
    if (!ProductModel.isEmpty(this.props.currentProduct)) {
      this.props.delete(this.props.currentProduct.id, false);
    } else {
      console.log('TODO-can not delete message');
    }
  };

  componentWillUnmount() {
    this.props.resetMessage();
  }

  render() {
    if (!this.props.currentProduct.exists) {
      return (
        <Redirect to={PathsApp.PRODUCTS_ROOT} />
      );
    } else {
      return (
        <Fragment>
          { this.props.messageAction ? window.scrollTo(0, 0) : null }
          <AlertContainer alert={this.props.messageAction} />
          <Container className="page-admin page-admin--feature" fluid>
            <HeaderSave title={"Product detail"} handleSave={this.handleSave} handleGetBack={this.handleGetBack} handleVisible={this.handleVisible} handleDelete={this.handleDelete} isVisible={this.props.currentProduct.isEnabled} />              
            <Container className="admin-tabs product-tabs" fluid>
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ 'admin-tabs__item': true, active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }} >Product info</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ 'admin-tabs__item': true, active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }} >Product features</NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane className="pt-4" tabId="1">
                    <Row>
                      <Col className="mb-1">
                        <FormGroup>
                          <Label for="productCategory">Product Category</Label>
                          <div className="select-admin__wrapper fa">
                            <select className="select-admin__control" onChange={this.changeCategory} value={this.props.currentProduct.category.id}>
                              {
                                this.props.categories ?
                                  this.props.categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)
                                  : null
                              }
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1">
                        <FormGroup>
                          <Label for="sku">Sku</Label>
                          <Input type="text" className="input-admin" name="sku" id="sku" onChange={this.handleChangeInput} value={this.props.currentProduct.sku} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-1">
                        <FormGroup>
                          <Label for="productName">Product Name</Label>
                          <Input type="text" className="input-admin" name="name" id="name" onChange={this.handleChangeInput} value={this.props.currentProduct.name} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-1">
                        <FormGroup>
                          <Label for="productFullDescription">Full Description</Label>
                          <Input type="textarea" className="textarea-admin" name="description" id="description" onChange={this.handleChangeInput} value={this.props.currentProduct.description} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-1">
                        <FormGroup>
                          <Label for="productUrl">Product Url</Label>
                          <Input type="text" className="input-admin" name="url" id="url" onChange={this.handleChangeInput} value={this.props.currentProduct.url} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-1">
                        <FormGroup>
                          <Label for="productImage">Standard Image</Label>
                          <UploadImageContainer keyImage={ProductConstants.IMAGE} mainClass="admin-tabs__image-wrapper" wrapperClass="admin-tabs__upload-wrapper" imageClass="admin-tabs__image-result" imageUrl={this.props.currentProduct.imageUrl} handleFile={this.props.uploadImage} showClose={true} closeImage={() => this.props.changeInputValue(ProductConstants.IMAGE, "")} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                        <Col className="mb-1">
                            <FormGroup>
                                <Label for="productOrderTag">Order Tag</Label>
                                <Input type="text" className="input-admin" name="order" id="order" onChange={this.handleChangeInput} value={this.props.currentProduct.order} />
                            </FormGroup>
                        </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2" className="pt-4">
                    <ProductFeatures items={this.props.features} />
                  </TabPane>
                </TabContent>
              </div>
            </Container>
          </Container>
        </Fragment>
      );
    }    
  }
}

const mapFeatures = (featuresFromCategory, productFeatures) => {
    
  if (featuresFromCategory) {
    return featuresFromCategory.map(f => {
        const options = mapOptions(f.id, productFeatures);
        return { featureCode: f.id, featureName: f.name, options: options, isEnabled: options.length === 0 ? false : true};
    });
  } else {
    return [];
  }
};

const mapOptions = (featureId, selectedOptions) => {
    return selectedOptions.filter(o => o.featureId === featureId);
};

const mapStateToProps = state => {
  return {
    categories: state.category.items,
    currentProduct: state.product.selected,
    features: mapFeatures(state.feature.items, state.product.selected.productFeatures),
    messageAction: state.product.messageAction
  };
};

const mapDispatchToProps = dispatch => {
  return {
    beginAdd: () => dispatch(actionBeginAddProduct()),
    changeCategory: (categoryId) => dispatch(actionChangeCategoryProduct(categoryId)),
    changeInputValue: (propertie, value) => dispatch(actionChangeValueProduct(propertie, value)),
    beginEdit: (productId) => dispatch(actionBeginEditProduct(productId)),
    toggle: (productId) => dispatch(actionToggleProduct(productId)),
    delete: (productId, isFromList) => dispatch(actionDeleteProduct(productId, isFromList)),
    uploadThumbnail: (file) => dispatch(actionUploadThumbnailProduct(file)),
    uploadImage: (file) => dispatch(actionUploadImageProduct(file)),
    saveAdd: (product) => dispatch(actionSaveAddProduct(product)),
    saveEdit: (product) => dispatch(actionSaveEditProduct(product)),
    resetMessage: () => dispatch(actionMessageProductDetail(null))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductContainer));
