import React, { Component } from 'react';
import { Col } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import './PaginationContainer.css';

export default class PaginationContainer extends Component {
  render() {
    return (
      <Col className="text-center" md={{ size: 4, offset: 4 }}>
        <ReactPaginate
          previousLabel={null}
          nextLabel={null}
          breakLabel={'...'}
          pageCount={this.props.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={this.props.handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          pageClassName={'paginator__item'}
          breakClassName={'paginator__break'}
          pageLinkClassName={'paginator__link'}
        />
      </Col>
    );
  }
}
