import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

const QuestionMessage = (props) => {
  if (props.isMultipleChoice) {
    return (
      <Container className="text-center mt-2 mb-2" fluid><span className="wizard__message">{props.message}</span></Container>
    );
  } else {
    return null;
  }
};

QuestionMessage.propTypes = {
  message: PropTypes.string,
  isMultipleChoice: PropTypes.bool
};

export default QuestionMessage;
