import React from 'react';
import ButtonAdd from '../buttons/ButtonAdd';
import { Container, Row, Col } from 'reactstrap';
import Back from '../../images/chevLeft.png';
import PropTypes from 'prop-types';

const HeaderChildAdd = (props) => {
  return (
    <Container className="page-admin__header" fluid>
      <Row>
        <Col>
          <div className="page-admin__group">
            <button className="page-admin__back" onClick={props.handleGetBack}>
              <img alt="back" src={Back} className="page-admin__icon" />
            </button>
            <h2 className="pl-3">{props.title}</h2>
          </div>
        </Col>
        <Col className="text-right">
          <ButtonAdd text={props.addText} handleAdd={props.handleAdd} />
        </Col>
      </Row>
    </Container>
  );
};

HeaderChildAdd.propTypes = {
  title: PropTypes.string,
  addText: PropTypes.string,
  handleAdd: PropTypes.func,
  handleGetBack: PropTypes.func
};

export default HeaderChildAdd;
