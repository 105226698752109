import React from 'react';
import { Container } from 'reactstrap';
import ResultItem from './ResultItem';
import uuid from 'uuid/v1';

const ResultList = (props) => {
  let listItems = null;
  if (props.items) {
    listItems = props.items.map(item =>
      <ResultItem key={uuid().toString()} data={item}  />);
  }

  return (
    <Container className="pl-0 pr-0" fluid>
      {listItems}
    </Container>
  );
};

export default ResultList;
