import React, { Component } from 'react';
import { Container, Row, Col, Button, Alert } from 'reactstrap';
import './ResultContainer.css';
import ResultList from './ResultList';
import Reset from '../../images/start_over.png';
import { withRouter } from 'react-router-dom';
import PathsApp from '../../utils/PathsApp';
import { connect } from 'react-redux';
import { actionResetResult, actionGetAllProductsResult } from './ResultAction';
import { actionGetWizardLayout } from '../home/HomeActions';
import { actionGetDistributorByToken } from '../distributor/DistributorClientAction';
import DistributorClientConstants from '../distributor/DistributorClientConstants';
import DistributorNotFound from '../distributor/DistributorNotFound';
import DistributorClienUtils from '../distributor/DistributorClientUtils';

class ResultContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldReset: false,
      notFound: false
    }
  }

  handleReset = (event) => {
    event.preventDefault();    
    this.props.history.push(PathsApp.WIZARD_PATH(PathsApp.WIZARD_HOME_ROOT, this.props.match.params.category, this.props.match.params.distributor));
    this.props.resetResult();
  };

  componentDidMount() {
    this.props.getDistributor(this.props.match.params.distributor, (distributor) => {
      if (distributor) {
        this.continueResult(distributor);
      } else {
        this.setState({ notFound: true });
      }
    });
  }

  continueResult(distributor) {
    if (!this.props.result || this.props.result.length === 0) {
      this.props.getAllProducts(this.props.match.params.category, distributor.id);
      this.setState({ shouldReset: true });
    }    
    this.props.getLayout();
  }

  componentWillUnmount() {
    if (this.state.shouldReset) {
      this.props.resetResult();
    }
  }
  
  handleBackWizard = (event) => {
    event.preventDefault();
    this.props.history.push(PathsApp.WIZARD_PATH(PathsApp.WIZARD_STEPS_ROOT, this.props.match.params.category, this.props.match.params.distributor));
  }

  getLayoutUrl = () => {
    return DistributorClienUtils.isDefault(this.props.distributor.id) ? this.props.layout.discoverMoreResult : this.props.distributor.layoutUrl;
  }

  render() {
    if (this.state.notFound) {
      return (
        <DistributorNotFound />
      );
    } else {
      return (
        <Container className="result" fluid>
          <Container className="result__wrapper" fluid>
            <div className="text-center mb-2">
              <h4>{this.props.layout.titleResult}</h4>
            </div>
            <Row className="result__message">
              <Col>
                {this.props.layout.descriptionResult}
              </Col>
            </Row>
            <Row className="result__list">
              {
                !this.props.loading ?
                  <Col className="pl-0 pr-0">
                    {
                      this.props.result ?
                        <ResultList items={this.props.result} />
                        : null
                    }
                    {
                      this.props.message ?
                        <Alert className="mt-2" color="warning">{this.props.message}</Alert>
                        : null
                    }
                  </Col>
                  : null
              }
            </Row>
            <div className="result__more">
              <a href={this.getLayoutUrl()} target="_blank" rel="noopener noreferrer">Discover more products</a>
            </div>
            <Row className="result__footer">
              <Col className="result__footer--wrapper">
                <Button className="button_main wizard__backWizard" color="primary" onClick={this.handleBackWizard}><i className="fas fa-chevron-left"></i><span>Back to wizard</span></Button>
                <Button onClick={this.handleReset} className="result__reset" color="primary"><img alt="reset" src={Reset} />Start Over</Button>
              </Col>
            </Row>
          </Container>
        </Container>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    questions: state.wizard.data,
    result: state.result.data,
    message: state.result.message,
    layout: state.home.layout,
    loading: state.result.loading,
    distributor: state.distributorClient.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetResult: () => dispatch(actionResetResult()),
    getLayout: () => dispatch(actionGetWizardLayout()),
    getAllProducts: (category, distributorId) => dispatch(actionGetAllProductsResult(category, distributorId)),
    getDistributor: (token, callback) => dispatch(actionGetDistributorByToken(token, callback))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultContainer));
