import React from 'react';
import { Container } from 'reactstrap';
import HOME from '../images/browser.png';
import './BrowserContainer.css';

const InternetExplorerContainer = () => {
  return (
    <Container className="browser-container">
      <div className="browser-container__image">
        <img alt="home" src={HOME} />
      </div>
      <div className="browser-container__message">
        <div>
          <h2 className="browser-container__title">Internet Alert</h2>
        </div>
        <div>
          <p className="browser-container__description">This platform doesn’t work with Explorer 11. Please use <b className="browser-container__black">another browser.</b></p>
        </div>
      </div>
    </Container>
  );
};

export default InternetExplorerContainer;
