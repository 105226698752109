import QuestionAnswerRequestModel from "./QuestionAnswerRequestModel";
import UuidUtil from "../../utils/UuidUtil";

class QuestionAnswerModel {
  constructor(id, questionId, option, isEnabled, imageUrl, orderNumber, minValue, maxValue, description) {
    this.id = id;
    this.questionId = questionId;
    this.option = option;
    this.isEnabled = isEnabled;
    this.imageUrl = imageUrl;
    this.orderNumber = orderNumber;
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.description = description;
  }

  toRequest() {
    return new QuestionAnswerRequestModel(this.id, this.option ? this.option.id : UuidUtil.EMPTY, this.isEnabled, this.imageUrl, this.orderNumber, this.minValue, this.maxValue, this.description);
  }
}

export default QuestionAnswerModel;
