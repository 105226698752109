export default class QuestionAnswerRequestModel {
  constructor(id, optionId, isEnabled, imageUrl, orderNumber, minValue, maxValue, description) {
    this.id = id;
    this.optionId = optionId;
    this.isEnabled = isEnabled;
    this.imageUrl = imageUrl;
    this.orderNumber = orderNumber;
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.description = description;
  }
}
