import React, { Component, Fragment } from 'react';
import { Container } from 'reactstrap';
import '../Admin.css';
import HeaderAdd from '../header/HeaderAdd';
import ListContainer from '../list/ListContainer';
import CategoryItem from './CategoryItem';
import { connect } from 'react-redux';
import { actionGetCategories, actionBeginAddCategory, actionSaveAddCategory, actionCancelAddCategory, actionSaveEditCategory, actionDeleteCategory, actionMessageCategory } from './CategoryActions';
import BaseModel from '../../models/BaseModel';
import AlertContainer from '../../alerts/AlertContainer';

class CategoryContainer extends Component {  

  componentDidMount() {
    this.props.getCategorires();
  }

  handleAdd = (event) => {
    event.preventDefault();
    const newItem = this.props.categories.find(category => BaseModel.isEmpty(category));
    if (newItem) {
      console.log('pending to save');//TODO - show a message to indicate a pending item to save
    } else {
      this.props.beginAdd();
    }
  };

  componentWillUnmount() {
    this.props.resetMessage();
  }

  render() {
    return (
      <Fragment>
        <AlertContainer alert={this.props.message} />
        <Container className="page-admin page-admin--feature" fluid>
          <HeaderAdd title={"Categories"} addText={"Add Category"} handleAdd={this.handleAdd} />
          <Container className="page-admin__list-container" fluid>
            <ListContainer
              items={this.props.categories}
              Component={CategoryItem}
              hasPagination={false}
              saveAdd={this.props.saveAdd}
              cancelAdd={this.props.cancelAdd}
              saveEdit={this.props.saveEdit}
              delete={this.props.delete} />
          </Container>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.category.items,
    message: state.category.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCategorires: () => dispatch(actionGetCategories()),
    beginAdd: () => dispatch(actionBeginAddCategory()),
    saveAdd: (newCategory) => dispatch(actionSaveAddCategory(newCategory)),
    cancelAdd: () => dispatch(actionCancelAddCategory()),
    saveEdit: (editedCategory) => dispatch(actionSaveEditCategory(editedCategory)),
    delete: (id) => dispatch(actionDeleteCategory(id)),
    resetMessage: () => dispatch(actionMessageCategory(null))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer);
