import LayoutModel from "./LayoutModel";
import LayoutConstants from "./LayoutConstants";

const initialState = {
  layout: LayoutModel.getEmpty(),
  message: null
};

const LayoutAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case LayoutConstants.GET_ACTION_TYPE: 
      return {
        ...state,
          layout: action.layout,
        category: action.category
      };
    case LayoutConstants.CHANGE_VALUE_ACTION_TYPE:
      return {
        ...state,
        layout: { ...state.layout, [action.propertie]: action.value }
      };
    case LayoutConstants.SAVE_ADD_ACTION_TYPE:
      return {
        ...state,
        layout: { ...state.layout, id: action.id }
      };
    case LayoutConstants.MESSAGE_ACTION_TYPE:
      return {
        ...state,
        message: action.message
      };
    default:
      return state;
  }
};

export default LayoutAdminReducer;