import React from 'react';
import { Col, Button } from 'reactstrap';
import Pencil from '../../images/edit.png';
import Trash from '../../images/twotone-delete-24px.png';
import View from '../../images/View.png';
import ViewDisabled from '../../images/View_disabled.png';
import Check from '../../images/check.png';
import './ButtonGroup.css';
import PropTypes from 'prop-types';

const ButtonGroup = (props) => {
  return (
    <Col className="text-right">
      {props.handleSave ? <Button className="button-group" onClick={props.handleSave} color="primary"><img className="button-group__image" alt="edit" src={Check} /></Button> : null}
      {props.handleEdit ? <Button className="button-group" onClick={props.handleEdit} color="primary"><img className="button-group__image" alt="edit" src={Pencil} /></Button> : null}
      {props.handleDelete ? <Button className="button-group" onClick={props.handleDelete} color="primary"><img className="button-group__image" alt="delete" src={Trash} /></Button> : null}
      {props.viewIsVisible ? <Button className="button-group" onClick={props.handleVisible} color="primary"><img className="button-group__image" alt="view" src={props.itemIsVisible ? View : ViewDisabled} /></Button> : null}
      {props.handleCancel ? <Button className="button-group" onClick={props.handleCancel} color="primary"><span aria-hidden="true">&times;</span></Button> : null}
    </Col>
  );
};

ButtonGroup.propTypes = {
  handleSave: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleVisible: PropTypes.func,
  handleCancel: PropTypes.func,
  viewIsVisible: PropTypes.bool,
  itemIsVisible: PropTypes.bool
};

export default ButtonGroup;