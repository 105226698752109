import OptionConstants from "./OptionConstants";
import BaseModel from '../../models/BaseModel';

const initialState = {
  items: null,
  message: null,
  totalItems: 0,
  totalPages: 0
};

const setOptionSaved = (items, newOption) => {
  return items.map((item, i) => {
    return i === 0 ? { ...item, id: newOption.id, name: newOption.name, description: newOption.description } : item;
  });
};

const removeOption = (items) => {
  return items.filter((item, i) => {
    return i !== 0;
  });
};

const setOptionEdited = (items, editedOption) => {
  return items.map((item) => {
    return item.id === editedOption.id ? { ...item, name: editedOption.name, description: editedOption.description } : item;
  });
};

const setOptionDeleted = (items, id) => {
  return items.filter((item) => {
    return item.id !== id;
  });
};

const OptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case OptionConstants.GET_ALL_ACTION_TYPE:
      return {
        ...state,
        items: action.items,
        totalItems: action.totalItems,
        totalPages: action.totalPages
      };
    case OptionConstants.BEGIN_ADD_ACTION_TYPE:
      return {
        ...state,
        items: [BaseModel.getEmpty(), ...state.items]
      };
    case OptionConstants.SAVE_ADD_ACTION_TYPE:
      return {
        ...state,
        items: setOptionSaved(state.items, action.newOption)
      };
    case OptionConstants.CANCEL_ADD_ACTION_TYPE:
      return {
        ...state,
        items: removeOption(state.items)
      };
    case OptionConstants.SAVE_EDIT_ACTION_TYPE:
      return {
        ...state,
        items: setOptionEdited(state.items, action.editedOption)
      };
    case OptionConstants.DELETE_ACTION_TYPE:
      return {
        ...state,
        items: setOptionDeleted(state.items, action.id)
      };
    case OptionConstants.RESET_ACTION_TYPE:
      return {
        ...state,
        items: null
      };
    case OptionConstants.MESSAGE_ACTION_TYPE:
      return {
        ...state,
        message: action.message
      };
    case OptionConstants.ORDER_ACTION_TYPE:
      return {
        ...state,
        items: action.options
      };
    default:
      return state;
  }
};

export default OptionReducer;
