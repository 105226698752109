import BaseModel from "../../models/BaseModel";

export default class DistributorRequestModel extends BaseModel {
  constructor(id, name, token, layoutUrl, isRegion, isEnabled, productsDistributor, exists, productDomain) {
    super(id, name);
    this.token = token;
    this.layoutUrl = layoutUrl;
    this.isRegion = isRegion;
    this.isEnabled = isEnabled;
    this.productsDistributor = productsDistributor;
    this.exists = exists;
    this.productDomain = productDomain;
  }

  static getEmpty = () => {
    return new DistributorRequestModel('', '', '', '', false, true, [], true, '');
  };
}
