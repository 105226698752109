import FeatureConstants from "./FeatureConstants";
import getAPIPath from '../../utils/PathResolver';
export default class FeatureApi {
  static GetPagedFeatures = (categoryId, page) => {
    return `${getAPIPath()}feature/GetPagedFeaturesByCategory?categoryId=${categoryId}&page=${page}&pageSize=${FeatureConstants.PAGE_SIZE}`;
  }
  static GetFeatures = (categoryId) => { return `${getAPIPath()}feature/GetAllByCategory?categoryId=${categoryId}`; }
  static get PostFeature() { return `${getAPIPath()}feature`; }
  static get PutFeature() { return `${getAPIPath()}feature`; }
  static DeleteFeature = (id) => { return `${getAPIPath()}feature?id=${id}`; }
  static GetFeature = (id) => { return `${getAPIPath()}feature?id=${id}`; }
}
