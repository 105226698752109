import FeatureConstants from "./FeatureConstants";
import axios from 'axios';
import FeatureModel from "./FeatureModel";
import FeatureApi from "./FeatureApi";
import { actionErrorAuth } from "../login/LoginAction";

const getFeatures = async (dispatch, categoryId, page) => {
  try {
      const isPaged = Number.isInteger(page);      
    const response = isPaged ? await axios.get(FeatureApi.GetPagedFeatures(categoryId, page)) : await axios.get(FeatureApi.GetFeatures(categoryId));
    if (response.data) {      
      dispatch({
        type: FeatureConstants.GET_ALL_ACTION_TYPE,
        items: isPaged ? response.data.list : getProductFeatures(response.data),
        totalItems: isPaged ? response.data.totalItems : response.data.length,
        totalPages: isPaged ? response.data.totalPages : 1
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: FeatureConstants.GET_ALL_ACTION_TYPE,
      items: null,
      totalItems: 0,
      totalPages: 0
    });
  }
};

const getProductFeatures = (features) => {
    return features.map(f => { return { ...f, isEnabled: false }; });
};

const getFeature = async (dispatch, id) => {
  try {
    const response = await axios.get(FeatureApi.GetFeature(id));
    if (response.data) {
      dispatch({
        type: FeatureConstants.GET_ACTION_TYPE,
        selected: response.data
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: FeatureConstants.GET_ACTION_TYPE,
      items: null
    });
  }
}

const saveFeature = async (dispatch, feature, categoryId) => {
  try {
    const response = await axios.post(FeatureApi.PostFeature, new FeatureModel(feature.id, feature.name, feature.name, categoryId));
    if (response.data) {
      dispatch(actionMessageFeature(FeatureConstants.MESSAGE_ADD_OK));
      dispatch({
        type: FeatureConstants.SAVE_ADD_ACTION_TYPE,
        newFeature: response.data
      });
    } else {
      dispatch(actionMessageFeature(FeatureConstants.MESSAGE_ADD_ERROR));
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch(actionMessageFeature(FeatureConstants.MESSAGE_ADD_ERROR));
  }
};

const editFeature = async (dispatch, feature, categoryId) => {
  try {
    const editedFeature = new FeatureModel(feature.id, feature.name, feature.name, categoryId);
    const response = await axios.put(FeatureApi.PutFeature, editedFeature);
    if (response.data) {
      dispatch(actionMessageFeature(FeatureConstants.MESSAGE_UPDATE_OK));
      dispatch({
        type: FeatureConstants.SAVE_EDIT_ACTION_TYPE,
        editedFeature: editedFeature
      });
    } else {
      dispatch(actionMessageFeature(FeatureConstants.MESSAGE_UPDATE_ERROR));
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch(actionMessageFeature(FeatureConstants.MESSAGE_UPDATE_ERROR));
  }
};

const deleteFeature = async (dispatch, id) => {
  try {
    const response = await axios.delete(FeatureApi.DeleteFeature(id));
    if (response.data) {
      dispatch({
        type: FeatureConstants.DELETE_ACTION_TYPE,
        id: id
      });
    } else {
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const actionGetFeatures = (categoryId) => {
  return dispatch => {
    getFeatures(dispatch, categoryId);
  };
};

const actionGetFeature = (id) => {
  return dispatch => {
    getFeature(dispatch, id);
  };
};

const actionBeginAddFeature = () => {
  return {
    type: FeatureConstants.BEGIN_ADD_ACTION_TYPE
  };
};

const actionSaveAddFeature = (feature, categoryId) => {
  return dispatch => {
    saveFeature(dispatch, feature, categoryId);
  };
};

const actionCancelAddFeature = () => {
  return {
    type: FeatureConstants.CANCEL_ADD_ACTION_TYPE
  };
};

const actionSaveEditFeature = (feature, categoryId) => {
  return dispatch => {
    editFeature(dispatch, feature, categoryId);
  };
};

const actionDeleteFeature = (id) => {
  return dispatch => {
    deleteFeature(dispatch, id);
  };
};

const actionMessageFeature = (message) => {
  return {
    type: FeatureConstants.MESSAGE_ACTION_TYPE,
    message: message
  };
};

const actionGetFeaturesByPage = (categoryId, page) => {
  return dispatch => {
    getFeatures(dispatch, categoryId, page);
  };
};

export {
  actionGetFeatures,
  actionGetFeature,
  actionBeginAddFeature,
  actionSaveAddFeature,
  actionCancelAddFeature,
  actionSaveEditFeature,
  actionDeleteFeature,
  actionMessageFeature,
  actionGetFeaturesByPage
};
