import React from 'react';
import { Container } from 'reactstrap';
import './HomeSetup.css';
import NOT_FOUND from '../../images/not-found-icon.jpg';

const HomeSetup = (props) => {
  return (
    <Container className="home-setup">
      <div className="home-setup__image">
        <img alt="home" onError={addDefaultSrc} src={props.layout.imageUrl} />
      </div>
      <div>
        <div className="">
          <h2 className="home-setup__title">{props.layout.title}</h2>
        </div>
        <div>
          <p className="home-setup__description">{props.layout.description}</p>
        </div>
      </div>
    </Container>
  );
};

const addDefaultSrc = (ev) => {
  ev.target.src = NOT_FOUND;
};

export default HomeSetup;
