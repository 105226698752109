import DistributorConstants from "./DistributorConstants";
import { actionErrorAuth } from "../login/LoginAction";
import axios from 'axios';
import DistributorApi from "./DistributorApi";
import UuidUtil from "../../utils/UuidUtil";
import DistributorRequestModel from "./DistributorRequestModel";
import ProductDistributorRequestModel from "./ProductDistributorRequestModel";
import BaseModel from "../../models/BaseModel";

const getDistributors = async (dispatch, page) => {
  try {
    const response = await axios.get(DistributorApi.GetDistributors(page));
    processGetResponse(dispatch, response);
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: DistributorConstants.GET_ALL_ACTION_TYPE,
      items: null,
      totalItems: 0,
      totalPages: 0
    });
  }
};

const processGetResponse = (dispatch, response) => {
  if (response.data && response.data.list) {
    dispatch({
      type: DistributorConstants.GET_ALL_ACTION_TYPE,
      items: response.data.list.length > 0 ? response.data.list : null,
      totalItems: response.data.totalItems,
      totalPages: response.data.totalPages
    });
    if (response.data.list.length > 0) {
      dispatch({
        type: DistributorConstants.MESSAGE_LIST_ACTION_TYPE,
        message: null
      });
    } else {
      dispatch({
        type: DistributorConstants.MESSAGE_LIST_ACTION_TYPE,
        message: DistributorConstants.MESSAGE_LIST_NOT_FOUND
      });
    }
  } else {
    dispatch({
      type: DistributorConstants.MESSAGE_LIST_ACTION_TYPE,
      message: DistributorConstants.MESSAGE_LIST_NOT_FOUND
    });
  }
};

const deleteDistributor = async (dispatch, distributorId, isFromList) => {
  try {
    const response = await axios.delete(DistributorApi.DeleteDistributor(distributorId));
    if (response.data) {
      dispatch({
        type: DistributorConstants.DELETE_ACTION_TYPE,
        id: distributorId,
        isFromList: isFromList
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const objectToDistributorRequest = (distributor, isAdd) => {
  const distributorRequest = {
    id: isAdd ? UuidUtil.EMPTY : distributor.id,
    name: distributor.name,
    token: distributor.token,
    layoutUrl: distributor.layoutUrl,
    isRegion: distributor.isRegion,
    isEnabled: true,
    productDomain: distributor.productDomain,
    productsDistributor: isAdd ? mapSaveProductToRequestObject(distributor.productsDistributor) : mapEditProductToRequestObject(distributor.productsDistributor)
  };  
  return distributorRequest;
};

const mapSaveProductToRequestObject = (productsDistributor) => {
  return productsDistributor.filter(pd => pd.isEnabled).map(pf => {
    return {
      id: UuidUtil.EMPTY,
      productId: pf.productId,
      url: pf.url,
      isEnabled: true
    };
  });
};

const mapEditProductToRequestObject = (productsDistributor) => {
  return productsDistributor.map(pf => {
    return {
      id: pf.id === pf.productId ? UuidUtil.EMPTY : pf.id,
      productId: pf.productId,
      url: pf.isEnabled ? pf.url : window.location.origin,
      isEnabled: pf.isEnabled
    };
  });
};

const saveDistributor = async (dispatch, distributor) => {
  try {
    const response = await axios.post(DistributorApi.PostDistributor, objectToDistributorRequest(distributor, true));
    if (response.data) {
      dispatch(actionMessageDistributor(DistributorConstants.MESSAGE_ADD_OK));
      dispatch({
        type: DistributorConstants.SAVE_ADD_ACTION_TYPE,
      });
    }
  } catch (e) {
    if (e && e.response && e.response.status === 400 && e.response.data) {
      dispatch(actionMessageDistributorDetail(DistributorConstants.MESSAGE_ADD_ERROR(e.response.data)));
    }
    dispatch(actionErrorAuth(e));
  }
};

const getDistributor = async (dispatch, distributorId, callback) => {
  try {
    const response = await axios.get(DistributorApi.GetDistributor(distributorId));
    if (response.data) {
      callback(response.data.token, response.data.isRegion);
      dispatch(actionLoadDistributor(response.data));      
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const editDistributor = async (dispatch, distributor) => {
  try {
    const response = await axios.put(DistributorApi.PutDistributor, objectToDistributorRequest(distributor, false));
    if (response.data) {
      dispatch(actionMessageDistributor(DistributorConstants.MESSAGE_UPDATE_OK));
      dispatch({
        type: DistributorConstants.SAVE_EDIT_ACTION_TYPE
      });
    }
  } catch (e) {
    if (e && e.response && e.response.status === 400 && e.response.data) {
      dispatch(actionMessageDistributorDetail(DistributorConstants.MESSAGE_UPDATE_ERROR(e.response.data)));
    }
    dispatch(actionErrorAuth(e));
  }
};

const toggleDistributor = async (dispatch, distributorId) => {
  try {
    const response = await axios.delete(DistributorApi.ToggleDistributor(distributorId));
    if (response.data) {
      dispatch({
        type: DistributorConstants.TOGGLE_ACTION_TYPE,
        id: distributorId
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const actionLoadDistributor = (data) => {
  const distributor = new DistributorRequestModel(
    data.id,
    data.name,
    data.token,
    data.layoutUrl,
    data.isRegion,
    data.isEnabled,
    data.productsDistributor.map(pd => {
      return new ProductDistributorRequestModel(
        pd.id,
        pd.productId,
        pd.productName,
        pd.distributorId,
        pd.url,
        true,
        new BaseModel('', pd.categoryName),
        pd.productSku
      )
    }),
    true,
    data.productDomain
  );
  return {
    type: DistributorConstants.GET_ACTION_TYPE,
    distributor: distributor
  };
};

const actionGetDistributors = (page) => {
  return dispatch => {
    getDistributors(dispatch, page);
  };
};

const actionBeginListDistributors = () => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.BEGIN_LIST_ACTION_TYPE
    });
    dispatch(actionGetDistributors(0));
  };
};

const actionToggleDistributor = (distributorId) => {
  return dispatch => {
    toggleDistributor(dispatch, distributorId);
  };
};

const actionDeleteDistributor = (distributorId, isFromList) => {
  return dispatch => {
    deleteDistributor(dispatch, distributorId, isFromList);
  };
};

const actionOpenProductsSelect = (products) => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.OPEN_SELECT_ACTION_TYPE,
      products: products
    });
  };
};

const actionCloseProductsSelect = () => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.CLOSE_SELECT_ACTION_TYPE
    });
  };
};

const actionOpenProductSelected = (product) => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.OPEN_SELECTED_ACTION_TYPE,
      product: product
    });
  };
};

const actionCloseProductSelected = () => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.CLOSE_SELECTED_ACTION_TYPE
    });
  };
};

const actionBeginAddDistributor = (token) => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.BEGIN_ADD_ACTION_TYPE
    });
    dispatch(actionChangeValueDistributor('token', token));
  }  
};

const actionChangeValueDistributor = (propertie, value) => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.CHANGE_VALUE_ACTION_TYPE,
      propertie: propertie,
      value: value
    });
  };
};

const actionUpdateProductsDistributor = (product, products) => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.UPDATE_PRODUCTS_ACTION_TYPE,
      product: product,
      products: products
    });
  };
};

const actionConfirmProductsDistributor = () => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.CONFIRM_PRODUCTS_ACTION_TYPE
    });
  };
};

const actionChangeValueProductDistributor = (propertie, value) => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.CHANGE_VALUE_PRODUCT_ACTION_TYPE,
      propertie: propertie,
      value: value
    });
  };
};

const actionUpdateProductDistributorSelected = () => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.UPDATE_PRODUCT_SELECTED_ACTION_TYPE
    });
  };
};

const actionRemoveProductDistributor = (productId) => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.REMOVE_PRODUCT_ACTION_TYPE,
      productId: productId
    });
  };
};

const actionSelectAllProductsDistributor = (flag, products) => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.SELECT_ALL_PRODUCTS_ACTION_TYPE,
      flag: flag,
      products: products
    });
  };
};

const actionChangeValueSelectAll = (flag) => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.CHANGE_VALUE_SELECT_ALL_ACTION_TYPE,
      flag: flag
    });
  };
};

const actionSaveAddDistributor = (distributor) => {
  return dispatch => {
    dispatch(actionMessageDistributorDetail(null));
    saveDistributor(dispatch, distributor);
  };
};

const actionMessageDistributorDetail = (message) => {
  return {
    type: DistributorConstants.MESSAGE_ACTION_ACTION_TYPE,
    messageAction: message
  };
};

const actionMessageDistributor = (message) => {
  return {
    type: DistributorConstants.MESSAGE_LIST_ACTION_ACTION_TYPE,
    messageListAction: message
  };
};

const actionBeginEditDistributor = (distributorId, callback) => {
  return dispatch => {
    dispatch(actionMessageDistributor(null));
    getDistributor(dispatch, distributorId, callback);
  };
};

const actionSaveEditDistributor = (distributor) => {
  return dispatch => {
    dispatch(actionMessageDistributorDetail(null));
    editDistributor(dispatch, distributor);
  };
};

const actionSetInvalidsProductsDistributor = (invalids) => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.SET_INVALIDS_PRODUCTS,
      invalids: invalids
    })
  };
};

const actionResetInvalidProductsDistributor = () => {
  return dispatch => {
    dispatch({
      type: DistributorConstants.RESET_INVALIDS_PRODUCTS
    });    
  };
};

export {
  actionBeginListDistributors,
  actionGetDistributors,
  actionToggleDistributor,
  actionDeleteDistributor,
  actionCloseProductsSelect,
  actionOpenProductsSelect,
  actionCloseProductSelected,
  actionOpenProductSelected,
  actionBeginAddDistributor,
  actionChangeValueDistributor,
  actionUpdateProductsDistributor,
  actionConfirmProductsDistributor,
  actionChangeValueProductDistributor,
  actionUpdateProductDistributorSelected,
  actionRemoveProductDistributor,
  actionSelectAllProductsDistributor,
  actionChangeValueSelectAll,
  actionMessageDistributorDetail,
  actionSaveAddDistributor,
  actionMessageDistributor,
  actionBeginEditDistributor,
  actionSaveEditDistributor,
  actionSetInvalidsProductsDistributor,
  actionResetInvalidProductsDistributor
};