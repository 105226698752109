import React from 'react';
import Modal from 'react-modal';
import { Row, Col } from 'reactstrap';
import { actionCloseHelper } from './HelperActions';
import { connect } from 'react-redux';
import './HelperContainer.css'
import HelperButton from './HelperButton';
import PropTypes from 'prop-types';

Modal.setAppElement('#root');

const HelperContainer = (props) => {
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeHelper}
      contentLabel="Example Modal"
      className="helper">
      <div className="helper__button-wrapper">
        <HelperButton className="helper__button" image={null} handleClick={props.closeHelper} />
      </div>
      <Row className="mb-4">
        <Col><h4>{props.title}</h4></Col>
      </Row>
      <Row>
        <Col dangerouslySetInnerHTML={{
            __html: props.text
        }}></Col>
      </Row>
    </Modal>
  );
};

HelperContainer.propTypes = {
  modalIsOpen: PropTypes.bool,  
  closeHelper: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string
};

const mapStateToProps = state => {
  return {
    modalIsOpen: state.helper.modalIsOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeHelper: () => dispatch(actionCloseHelper())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HelperContainer);
