import { Component } from 'react';

class BaseItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseInside: false,
      id: '',
      name: '',
      previousName: '',
      isEditing: false,
      pathRedirect: '',
      itemIsVisible: true
    };
  }

  mouseEnter = () => {
    this.setState({ isMouseInside: true });
  }

  mouseLeave = () => {
    this.setState({ isMouseInside: false });
  }

  handleVisible = (event) => {
    event.preventDefault();
    this.setState(({ itemIsVisible }) => ({
      itemIsVisible: !itemIsVisible
    }));
  }

  handleDelete = (event) => {
    throw new Error('You have to implement the method handleDelete!');
  }

  handleCancel = (event) => {
    throw new Error('You have to implement the method handleCancel!');
  }

  handleEdit = (event) => {
    throw new Error('You have to implement the method handleEdit!');
  }

  handleSave = (event) => {
    throw new Error('You have to implement the method handleSave!');
  }
}

export default BaseItem;
