import React, { Component } from 'react';
import { Container, Button } from 'reactstrap';
import NOT_FOUND from '../../images/not-found-icon.jpg';

class ResultItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseInside: true
    };
  }

  handleLearn = (event) => {
    event.preventDefault();
    try {
      window.open(this.props.data.url, "_blank")
    } catch (e) {
      console.log(e);
    }
  };

  addDefaultSrc = (ev) => {
    ev.target.src = NOT_FOUND;
  };

  render() {
    return (
      <Container className="result-item" fluid>
        <div className="result-item__wrapper">
          <div className="result-item__name">
            {this.props.data.name}
          </div>
          <div className="result-item__wrapper-image">
            {
              this.props.data.imageUrl
                ? <img className="result-item__image" alt="result" onError={this.addDefaultSrc} src={this.props.data.imageUrl} />
                : <img className="result-item__image" alt="result" src={NOT_FOUND} />
            }
          </div>
          <div className="result-item__description">
            {this.props.data.description}
          </div>
          {
            this.state.isMouseInside && this.props.data.url && this.props.data.url.length > 0
              ? <Button className="result-item__button" color="primary" onClick={this.handleLearn}>Learn more</Button>
              : null
          }
        </div>        
      </Container>
    );
  }
}

export default ResultItem;
