import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';
import { Container, Row, Col, CustomInput, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionCloseProductsSelect, actionUpdateProductsDistributor, actionConfirmProductsDistributor, actionSelectAllProductsDistributor, actionChangeValueSelectAll } from './DistributorAction';
import '../Admin.css';
import ProductFilter from '../products/ProductFilter';
import ProductSelectList from './ProductSelectList';
import PaginationContainer from '../pagination/PaginationContainer';
import { actionGetProductsResume, actionSetSearchProduct } from '../products/ProductAction';
import DistributorConstants from './DistributorConstants';
import ButtonModal from '../buttons/ButtonModal';

Modal.setAppElement('#root');

class ProductSelectorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0
    }
  }

  handleClose = () => {    
    this.props.close();
    this.setState({ currentPage: 0 });
  };

  handleContinue = () => {
    this.props.confirm();
    this.handleClose();
  };

  handleSort = (event) => {
    this.props.getProductsResume(this.props.search, event.target.value);
  };

  handleChangeSearch = (event) => {
    this.props.changeSearch(event.target.value);    
  };

  handleSearchEnter = (event) => {
    if (event.key === 'Enter') {
      this.props.getProductsResume(this.props.search, this.props.sortBy);
    }
  };

  handleSearchButton = (event) => {
    event.preventDefault();
    this.props.getProductsResume(this.props.search, this.props.sortBy);
  };

  handlePageClick = (data) => {
    this.setState({ currentPage: data.selected });
  };

  handleChangeSelectAll = () => {
    this.props.changeSelectAll(!this.props.selectedAll, this.props.productsComplete);
  };

  getProductsToList = (products, productsSelected, pageNumber) => {
    if (products) {
      let productsPaged = products.slice((pageNumber - 1) * DistributorConstants.PAGE_SIZE, pageNumber * DistributorConstants.PAGE_SIZE);
      return productsPaged.map(p => {
        const optionSelected = productsSelected.find(ps => ps.productId === p.id);
        if (optionSelected) {
          return { ...p, checked: true };
        } else {
          return { ...p, checked: false };
        }
      });
    } else {
      return [];
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.productsComplete !== this.props.productsComplete) {
      if (this.props.productsComplete) {
        this.props.changeValueSelectAll(this.props.productsComplete.every(p => this.props.productsSelected.find(ps => ps.productId === p.id)));
      } else {
        this.props.changeValueSelectAll(false);
      }      
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onRequestClose={this.handleClose}
        contentLabel="Select Products"
        className="preview-select">
        <Container className="page-admin page-admin--feature" fluid>
          <Container className="page-admin__header" fluid>
            <Row>
              <Col className="text-left">
                <h2>Add Products</h2>
              </Col>
              <Col className="text-right">
                <ButtonModal confirmText="Yes" cancelText="Cancel" confirm={this.handleContinue} cancel={this.handleClose} />
              </Col>
            </Row>
          </Container>
          <Container className="page-admin__list-container" fluid>
            <ProductFilter
              handleChangeSearch={this.handleChangeSearch}
              search={this.props.search}
              handleSearchEnter={this.handleSearchEnter}
              handleSearchButton={this.handleSearchButton}
              handleSort={this.handleSort}
              sortBy={this.props.sortBy} />
            {
              this.props.productsComplete ?
                <Fragment>
                  <Row className="pt-2">
                    <Col className="text-left pl-3">
                      <CustomInput className="ml-3" type="checkbox" id="selectAll" label="Select all" checked={this.props.selectedAll} onChange={this.handleChangeSelectAll} />
                    </Col>
                  </Row>
                  <ProductSelectList source={this.props.productsComplete} items={this.getProductsToList(this.props.productsComplete, this.props.productsSelected, this.state.currentPage + 1)} handleChangeItem={this.props.updateProducts} />
                  <Row className="pt-4">
                    <PaginationContainer pageCount={this.props.pageCount} handlePageClick={this.handlePageClick} />
                  </Row>
                </Fragment>
                : null
            }
            {
              this.props.message ?
                <Alert className="mt-2" color="warning">{this.props.message}</Alert>
                : null
            }
          </Container>
        </Container>
      </Modal>
    );
  }
}

const mapProductsSelect = (products, productsSelected, selectedAll) => {
  if (products) {
    return products.map(p => {
      if (selectedAll) {
        return { ...p, checked: true };
      } else {
        const optionSelected = productsSelected.find(ps => ps.productId === p.id);
        if (optionSelected) {
          return { ...p, checked: true };
        } else {
          return { ...p, checked: false };
        }
      }      
    });
  } else {
    return [];
  }
};

const mapStateToProps = state => {
  return {
    modalIsOpen: state.distributor.modalSelectIsOpen,
    sortBy: state.product.sortBy,
    search: state.product.search,
    pageCount: state.product.totalPages,
    productsComplete: state.product.items,
    productsSelected: state.distributor.productsTemp,
    products: mapProductsSelect(state.product.items, state.distributor.productsTemp, state.distributor.selectedAll),
    message: state.product.messageList,
    selectedAll: state.distributor.selectedAll
  };
};

const mapDispatchToProps = dispatch => {
  return {
    close: () => dispatch(actionCloseProductsSelect()),
    updateProducts: (product, products) => dispatch(actionUpdateProductsDistributor(product, products)),
    confirm: () => dispatch(actionConfirmProductsDistributor()),
    changeSelectAll: (flag, products) => dispatch(actionSelectAllProductsDistributor(flag, products)),
    getProductsResume: (name, sortBy) => dispatch(actionGetProductsResume(name, DistributorConstants.PAGE_SIZE, sortBy)),
    changeSearch: (search) => dispatch(actionSetSearchProduct(search)),
    changeValueSelectAll: (flag) => dispatch(actionChangeValueSelectAll(flag))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSelectorModal));
