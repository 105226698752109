import ErrorsApp from "../../utils/ErrorsApp";

export default class ProductConstants {
  static get BEGIN_LIST_ACTION_TYPE() { return 'product/BEGIN_LIST'; }
  static get GET_ALL_ACTION_TYPE() { return 'product/GET_ALL'; }
  static get SET_ORDER_BY_ACTION_TYPE() { return 'product/SET_ORDER_BY'; }
  static get SET_SEARCH_ACTION_TYPE() { return 'product/SET_SEARCH'; }
  static get BEGIN_ADD_ACTION_TYPE() { return 'product/BEGIN_ADD'; }
  static get TOGGLE_ACTION_TYPE() { return 'product/TOGGLE'; }
  static get DELETE_ACTION_TYPE() { return 'product/DELETE'; }
  static get GET_ACTION_TYPE() { return 'product/GET'; }
  static get CHANGE_VALUE_ACTION_TYPE() { return 'product/CHANGE_VALUE'; }
  static get SAVE_ADD_ACTION_TYPE() { return 'product/SAVE_ADD'; }
  static get SAVE_EDIT_ACTION_TYPE() { return 'product/SAVE_EDIT'; }
  static get MESSAGE_LIST_ACTION_TYPE() { return 'product/MESSAGE_LIST'; }
  static get MESSAGE_LIST_ACTION_ACTION_TYPE() { return 'product/MESSAGE_LIST_ACTION'; }
  static get MESSAGE_ACTION_ACTION_TYPE() { return 'product/MESSAGE_ACTION'; }
  static get MESSAGE_OPTIONS_ACTION_TYPE() { return 'product/MESSAGE_OPTIONS'; }
  static get OPEN_OPTIONS_ACTION_TYPE() { return 'product/OPEN_OPTIONS'; }
  static get CLOSE_OPTIONS_ACTION_TYPE() { return 'product/CLOSE_OPTIONS'; }
  static get UPDATE_OPTION_ACTION_TYPE() { return 'product/UPDATE_OPTION'; }
  static get UPDATE_FEATURE_ACTION_TYPE() { return 'product/UPDATE_FEATURE'; }
  static get CANCEL_OPTIONS_ACTION_TYPE() { return 'product/CANCEL_OPTIONS'; }
  static get GET_ALL_RESUME_ACTION_TYPE() { return 'product/GET_ALL_RESUME'; }
  static get MESSAGE_LIST_NOT_FOUND() { return 'No products found'; }
  static get MESSAGE_OPTIONS_NOT_FOUND() { return 'No options found'; }
  static get MESSAGE_ADD_OK() { return { type: 'success', text: 'Product added succesfully.' }; }
  static MESSAGE_ADD_ERROR = (data) => { return { type: 'danger', text: ErrorsApp.GET_MESSAGE_TEXT(data) }; }
  static get MESSAGE_UPDATE_OK() { return { type: 'success', text: 'Product updated succesfully.' }; }
  static MESSAGE_UPDATE_ERROR = (data) => { return { type: 'danger', text: ErrorsApp.GET_MESSAGE_TEXT(data) }; }
  static get MESSAGE_LIST_ADD_ERROR() { return { type: 'danger ', text: 'Error creating product.' }; }  
  static get PAGE_SIZE() { return 10; }
  static get IMAGE() { return 'imageUrl'; }
  static get THUMBNAIL() { return 'thumbnailImageUrl'; }  
}
