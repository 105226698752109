import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import ButtonGroup from '../buttons/ButtonGroup';
import BaseItem from '../items/BaseItem';
import { connect } from 'react-redux';
import { actionOpenProductSelected, actionRemoveProductDistributor } from './DistributorAction';

class ProductDistributorItem extends BaseItem {

  handleEdit = (event) => {
    event.preventDefault();
    this.props.openSelected(this.props.item);
  };

  handleDelete = (event) => {
    event.preventDefault();
    this.props.removeProduct(this.props.item.productId);
  };

  render() {
    return (
      <Row className={this.props.isError ? "error-item item-admin product-feature" : "item-admin product-feature" } onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} >
        <Col lg="4" className="item-admin__text" onClick={this.handleEdit}>
          {this.props.item.productName}
        </Col>
        <Col lg="5" className="item-admin__text" onClick={this.handleEdit}>
          {this.props.url}
        </Col>
        <Col lg="3" className="product-feature__buttons">
          <Row>
            {
              this.state.isMouseInside ? <ButtonGroup handleEdit={this.handleEdit} handleDelete={this.handleDelete} handleVisible={null} itemIsVisible={this.props.item.isEnabled} viewIsVisible={false} /> : null
            }
          </Row>
        </Col>
      </Row>
    );
  }
}

ProductDistributorItem.propTypes = {
  item: PropTypes.object
};

const mapDispatchToProps = dispatch => {
  return {
    openSelected: (product) => dispatch(actionOpenProductSelected(product)),
    removeProduct: (productId) => dispatch(actionRemoveProductDistributor(productId))
  };
};

export default connect(null, mapDispatchToProps)(ProductDistributorItem);
