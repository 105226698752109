import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import Search from '../../images/Shape.png';
import ProductSortEnum from './ProductSortEnum';

const ProductFilter = (props) => {
  return (
    <Row className="pb-2">
      <Col lg="7">
        <div className="product-container__search">
          <Input name="search" id="search" placeholder="Search product" onChange={props.handleChangeSearch} value={props.search} onKeyDown={props.handleSearchEnter} className="input-admin" />
          <button className="product-container__button-search" onClick={props.handleSearchButton}>
            <img alt="search" src={Search} className="product-container__icon" />
          </button>
        </div>
      </Col>
      <Col className="text-right" lg="5">
        <div className="select-admin__wrapper fa">
          <select className="product-container__sort select-admin__control" onChange={props.handleSort} value={props.sortBy}>
            <option value={ProductSortEnum.SKU}>Sort by Sku</option>
            <option value={ProductSortEnum.NAME}>Sort by Name</option>
          </select>
        </div>
      </Col>
    </Row>
  );
};

export default ProductFilter;
