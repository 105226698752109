import React from 'react';
import { Container, Alert } from 'reactstrap';
import DistributorClientConstants from './DistributorClientConstants';

const DistributorNotFound = () => {
  return (
    <Container className="home-container page-client">
      <Alert className="mt-2" color="danger">{DistributorClientConstants.NOT_FOUND}</Alert>
    </Container>
  );
};

export default DistributorNotFound;