import LoginApi from "./LoginApi";
import LoginConstants from "./LoginConstants";
import axios from 'axios';
import UserModel from "./UserModel";

const authenticate = async (dispatch, username, password) => {
  try {
    const response = await axios.post(LoginApi.Authenticate, { username: username, password: password });    
    if (response.data) {      
      const userSession = new UserModel(response.data.username, response.data.token);
      localStorage.setItem(LoginConstants.LOCAL_STORAGE, JSON.stringify(userSession));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
      dispatch({
        type: LoginConstants.AUTHENTICATE_ACTION_TYPE,
        user: userSession
      });
    } else {
      axios.defaults.headers.common['Authorization'] = '';
    }
  } catch (e) {
    dispatch(actionMessageAuthentication(LoginConstants.MESSAGE_ERROR));
  }
};

const actionAuthenticate = (username, password) => {
  return dispatch => {
    authenticate(dispatch, username, password);
  };
};

const isValidSession = (session) => {
  return session && session.username && session.token;
};

const actionGetSession = () => {
  let userSession = null;
  try {
    const session = JSON.parse(localStorage.getItem(LoginConstants.LOCAL_STORAGE));
    if (isValidSession(session)) {
      userSession = session;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + session.token;
    } else {
      axios.defaults.headers.common['Authorization'] = '';
    }
  } catch (e) {
    console.log('TODO - error session', e);
  }  
  return {
    type: LoginConstants.AUTHENTICATE_ACTION_TYPE,
    user: userSession
  };
};

const actionLogout = () => {
  localStorage.removeItem(LoginConstants.LOCAL_STORAGE);
  return {
    type: LoginConstants.LOGOUT_ACTION_TYPE
  };
};

const actionErrorAuth = (error) => {
  return dispatch => {
    if (error && error.response && error.response.status === LoginConstants.UNAUTHORIZED_CODE) {
      dispatch(actionLogout());
    }
    dispatch({
      type: LoginConstants.LAST_ERROR_ACTION_TYPE,
      error: error
    });
  };
};

const actionMessageAuthentication = (message) => {
  return {
    type: LoginConstants.MESSAGE_ACTION_TYPE,
    message: message
  };
};

export { actionAuthenticate, actionGetSession, actionLogout, actionErrorAuth, actionMessageAuthentication }
