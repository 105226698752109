import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const HelperButton = (props) => {
  return (
    <Button className={props.className} color="primary" onClick={props.handleClick} >
      {props.image ? <img alt="help" src={props.image} /> : <span aria-hidden="true">&times;</span>}
    </Button>
  );
};

HelperButton.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
  image: PropTypes.string
};

export default HelperButton;
