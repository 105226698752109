import React, { Component, Fragment } from 'react';
import { Container } from 'reactstrap';
import '../Admin.css';
import HeaderChildAdd from '../header/HeaderChildAdd';
import { withRouter } from 'react-router-dom';
import ListContainer from '../list/ListContainer';
import OptionItem from './OptionItem';
import PathsApp from '../../utils/PathsApp';
import { connect } from 'react-redux';
import { actionGetFeature } from '../features/FeatureActions';
import { actionBeginAddOption, actionCancelAddOption, actionSaveAddOption, actionSaveEditOption, actionDeleteOption, actionMessageOption, actionGetOptionsByPage } from './OptionActions';
import AlertContainer from '../../alerts/AlertContainer';

class OptionContainer extends Component {

  componentDidMount() {
    this.props.getFeature(this.props.match.params.id);
    this.props.getOptions(this.props.match.params.id, 0);
  }

  handleAdd = (event) => {
    event.preventDefault();
    const newItem = this.props.options.find(i => i.id === '');
    if (newItem) {
      console.log('todo integrate'); //TODO INTEGRATE REDUX TO UPDATE LIST ITEMS
    } else {
      this.props.beginAdd();
    }
  };

  handleGetBack = (event) => {
    event.preventDefault();
    this.props.history.push(PathsApp.FEATURES_BASE + this.props.feature.category.id);
  };

  componentWillUnmount() {
    this.props.resetMessage();
  }

  handlePageClick = (data) => {
    this.props.getOptions(this.props.match.params.id, data.selected);
  };

  render() {
    return (
      <Fragment>
        <AlertContainer alert={this.props.message} />
        <Container className="page-admin page-admin--feature" fluid>
        {
          this.props.feature && this.props.options
            ? <Fragment>
                <HeaderChildAdd title={this.props.feature.name} addText={"Add Option"} handleAdd={this.handleAdd} handleGetBack={this.handleGetBack} />
                <Container className="page-admin__list-container" fluid>
                  <ListContainer
                    items={this.props.options}
                    Component={OptionItem}
                    hasPagination={true}
                    saveAdd={this.props.saveAdd}
                    cancelAdd={this.props.cancelAdd}
                    parentId={this.props.feature.id}
                    saveEdit={this.props.saveEdit}
                    delete={this.props.delete}
                    pageCount={this.props.pageCount}
                    handlePageClick={this.handlePageClick}/>
                </Container>
            </Fragment>
            : null
        }        
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    feature: state.feature.selected,
    options: state.option.items,
    message: state.option.message,
    pageCount: state.option.totalPages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFeature: (id) => dispatch(actionGetFeature(id)),
    getOptions: (featureId, page) => dispatch(actionGetOptionsByPage(featureId, page)),
    beginAdd: () => dispatch(actionBeginAddOption()),
    saveAdd: (newOption, featureId) => dispatch(actionSaveAddOption(newOption, featureId)),
    cancelAdd: () => dispatch(actionCancelAddOption()),
    saveEdit: (optionEdited, featureId) => dispatch(actionSaveEditOption(optionEdited, featureId)),
    delete: (id) => dispatch(actionDeleteOption(id)),
    resetMessage: () => dispatch(actionMessageOption(null))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OptionContainer));
