import React, { Component, Fragment } from 'react';
import { Container, Button } from 'reactstrap';
import './PreviewContainer.css';
import { withRouter } from 'react-router-dom';
import WizardSetup from '../../client/wizard/WizardSetup';
import PathsApp from '../../utils/PathsApp';
import { connect } from 'react-redux';
import WizardConstants from '../../client/wizard/WizardConstants';
import { actionGetSetup, actionGetNumberProducts } from '../../client/wizard/WizardActions';
import { actionDeployPreview, actionMessagePreview, actionSetCategoryPreview } from './PreviewActions';
import AlertContainer from '../../alerts/AlertContainer';
import PreviewConstants from './PreviewConstants';
import { actionResetResult } from '../../client/result/ResultAction';

class PreviewContainer extends Component {
  constructor(props) {
    super(props);
    this.props.getSetup(this.props.categoryId, WizardConstants.PREVIEW);
  }

  componentDidMount() {
    this.props.getNumberProducts(this.props.categoryId, WizardConstants.PREVIEW);
  }

  handleEdit = (event) => {
    event.preventDefault();
    this.props.history.push(PathsApp.QUESTIONS_EDIT_BASE + this.props.question.id);
  };

  handleDeploy = (event) => {
    event.preventDefault();
    this.props.deploy(this.props.categoryId);
  };

  componentWillUnmount() {
    this.props.resetMessage();
    this.props.resetCategorySelected();
    this.props.resetResults();
  }

  render() {
    return (
      <Container className="preview" fluid>
        <Container className="preview__wrapper">
          <div className="preview__buttons">
          {
            this.props.question && this.props.question.id
              ? <Fragment>
                  <Button className="button-save button-save--small text-center mr-3" color="primary" onClick={this.handleEdit}>Edit</Button>
                  <Button className="button_main mr-0 preview__action" color="primary" onClick={this.handleDeploy}>Publish</Button>
                </Fragment>                
              : !this.props.loadingWizard
                  ? <Fragment>
                      <Button className="button-save button-save--small text-center mr-3" color="primary" onClick={this.handleEdit} disabled>Edit</Button>
                      <Button className="button_main mr-0 preview__action" color="primary" onClick={this.handleDeploy} disabled>Publish</Button>
                    </Fragment>
                  : null
          }
          </div>
          <AlertContainer alert={this.props.message} />
          {            
            this.props.questions
              ? <WizardSetup type={WizardConstants.PREVIEW} max={this.props.questions.length - 1} numberProducts={this.props.numberProducts} />
              : !this.props.loadingWizard ? <AlertContainer alert={PreviewConstants.MESSAGE_NO_QUESTIONS_ERROR} /> : null
          }
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    questions: state.wizard.data,
    categoryId: state.preview.categoryId,
    message: state.preview.message,
    question: state.wizard.currentQuestion,
    numberProducts: state.wizard.numberProducts,
    loadingWizard: state.wizard.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSetup: (category, typeSetup) => dispatch(actionGetSetup(category, typeSetup)),
    deploy: (categoryId) => dispatch(actionDeployPreview(categoryId)),
    resetMessage: () => dispatch(actionMessagePreview(null)),
    resetCategorySelected: () => dispatch(actionSetCategoryPreview('')),
    getNumberProducts: (category, typeSetup) => dispatch(actionGetNumberProducts(category, typeSetup)),
    resetResults: () => dispatch(actionResetResult())
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewContainer));
