import React, { Component, Fragment } from 'react';
import { Collapse, Button, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import './NavAdmin.css';
import NavApp from '../../nav/NavApp';
import UserProfile from '../../images/profile-def.png';
import Logo from '../../images/Netgear_Business2x.png';
import { withRouter } from 'react-router-dom';
import PathsApp from '../../utils/PathsApp';
import { connect } from 'react-redux';
import { actionLogout } from '../login/LoginAction';
import { actionOpenPreviewSelect } from '../preview/PreviewActions';
import PreviewSelectModal from '../preview/PreviewSelectModal';

class NavAdmin extends Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  handlePreview = (event) => {
    event.preventDefault();
    this.props.openPreviewSelect();
  };

  handleLogout = (event) => {
    event.preventDefault();
    this.props.logout();
  };

  render() {
    return (
      <Fragment>
        <NavApp>
          <Navbar className="navbar-expand-lg navadmin" light>
            <NavbarBrand tag={RRNavLink} to={PathsApp.QUESTIONS_ROOT}>
              <img src={Logo} alt="logo" className="navadmin__logo" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="navadmin__collapse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav mr-auto navadmin__nav">              
                <NavItem className="navadmin__navitem">
                  <NavLink tag={RRNavLink} className="navadmin__link" to={PathsApp.QUESTIONS_ROOT}>Questions</NavLink>
                </NavItem>
                <NavItem className="navadmin__navitem">
                  <NavLink tag={RRNavLink} className="navadmin__link" to={PathsApp.PRODUCTS_ROOT}>Products</NavLink>
                </NavItem>
                <NavItem className="navadmin__navitem">
                  <NavLink tag={RRNavLink} className="navadmin__link" to={PathsApp.CATEGORIES_ROOT}>Categories</NavLink>
                </NavItem>
                <NavItem className="navadmin__navitem">
                  <NavLink tag={RRNavLink} className="navadmin__link" to={PathsApp.LAYOUT_ROOT}>Layout</NavLink>
                </NavItem>
                <NavItem className="navadmin__navitem">
                  <NavLink tag={RRNavLink} className="navadmin__link" to={PathsApp.DISTRIBUTORS_ROOT}>Distributors</NavLink>
                </NavItem>
              </ul>            
              <Button className="button_main" color="primary" onClick={this.handlePreview}>Preview</Button>
              <img src={UserProfile} alt="profile" className="rounded-circle navadmin__imgprofile" />
              <Button className="navadmin__logout" onClick={this.handleLogout} color="link">Logout</Button>
            </Collapse>
          </Navbar>
        </NavApp>
        <PreviewSelectModal />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actionLogout()),
    openPreviewSelect: () => dispatch(actionOpenPreviewSelect())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(NavAdmin));
