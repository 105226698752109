import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Check from '../../images/check_rounded.png';
import classnames from 'classnames';
import OptionBase from './OptionBase';
import PropTypes from 'prop-types';
import NOT_FOUND from '../../images/not-found-icon.jpg';

class OptionIcon extends OptionBase {
  addDefaultSrc = (ev) => {
    ev.target.src = NOT_FOUND;
  };

  render() {
    return (
      <Container className={classnames({ 'wizard__option': true, 'wizard__option--icontext': true, 'wizard__option--checked': this.props.checked })} onClick={this.handleCheck} fluid >
        <div className={classnames({ 'wizard__check': true, 'wizard__check--icontext': true, 'wizard__check--active': this.props.checked })}>
          <img alt="active" src={Check} />
        </div>
        <Row className="wizard__option--icontext-content">
          <Col xs="3" className="wizard__option--icontext-elem"><img alt="icon" onError={this.addDefaultSrc} src={this.props.imageUrl} /></Col>
          <Col xs="8" className="wizard__option--icontext-elem pl-0"><span>{this.props.text}</span></Col>
        </Row>
      </Container>
    );
  }
}

OptionIcon.propTypes = {
  text: PropTypes.string,
  imageUrl: PropTypes.string
};

export default OptionIcon;
