import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, CustomInput } from 'reactstrap';

class ProductSelectItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseInside: false
    };
  }

  mouseEnter = () => {
    this.setState({ isMouseInside: true });
  };

  mouseLeave = () => {
    this.setState({ isMouseInside: false });
  };

  handleChange = (event) => {
    this.props.handleChangeItem(this.props.item, this.props.source);
  };

  render() {
    return (
      <Row className="item-admin" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        <Col className="item-admin__text text-left">
          <CustomInput className="checkItem" onChange={this.handleChange} type="checkbox" id={this.props.item.id} checked={this.props.item.checked} label={`${this.props.item.sku} - ${this.props.item.name}`} />
        </Col>
      </Row>
    );
  }
}

export default withRouter(ProductSelectItem);
