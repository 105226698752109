import { Component } from 'react';

class QuestionAnswerBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      isVisible: true
    };
  }

  componentDidMount() {
    this.setState({
      id: this.props.item.id,
      name: this.props.item.option.name,
      isVisible: this.props.item.isEnabled
    });
  }

  handleVisible = (event) => {
    event.preventDefault();
    this.props.updateList(this.props.item, !this.state.isVisible);
    this.setState(({ isVisible }) => ({
      isVisible: !isVisible
    }));    
  }
}

export default QuestionAnswerBase;