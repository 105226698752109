import UploadImageConstants from "./UploadImageConstants";

const initialState = {
  mapErrors: {}
};

const UploadImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case UploadImageConstants.ERROR_ACTION_TYPE:
      return {
        ...state,
        mapErrors: { ...state.mapErrors, [action.key]: action.isError }
      };
    default:
      return state;
  }
};

export default UploadImageReducer;
