import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';
import ButtonGroup from '../buttons/ButtonGroup';

class QuestionRangeItem extends Component {

  componentDidMount() {
    this.setState({
      key: this.props.item.key
    });
  }

  handleVisible = (event) => {
    event.preventDefault();
    this.props.changeValue(this.state.key, 'isEnabled', !this.props.item.isEnabled);
  };

  handleChange = (event) => {
    this.props.changeValue(this.state.key, event.target.id, event.target.value);
  };

  handleDelete = (event) => {
    event.preventDefault();
    this.props.deleteRange(this.state.key, this.props.item.id);
  };

  render() {
    return (
      <Row className="answer-item">
        <Col lg="12" className="item-admin pt-0 pb-0">
          <Row className="range-admin">
            <Col lg="7" className="my-auto">
              <Row>
                <Col className="item-admin__text pr-0">
                  <Input type="number" name="minValue" id="minValue" className="input-admin input-admin--small range-input" placeholder="Min" onChange={this.handleChange} value={this.props.item.minValue} />
                </Col>
                <Col lg="1" className="item-admin__text pl-0 pr-0 text-center my-auto">
                  <span>To</span>
                </Col>
                <Col className="item-admin__text pl-0">
                  <Input type="number" name="maxValue" id="maxValue" className="input-admin input-admin--small range-input" placeholder="Max" onChange={this.handleChange} value={this.props.item.maxValue} />
                </Col>
                <Col lg="6" className="item-admin__text pl-0">
                  <Input type="text" name="description" id="description" className="input-admin input-admin--small range-input" placeholder="Description" onChange={this.handleChange} value={this.props.item.description} />
                </Col>
              </Row>
            </Col>            
            <Col lg="2" className="pr-0 my-auto">
              <ButtonGroup handleEdit={null} handleDelete={this.handleDelete} handleSave={null} handleCancel={null} handleVisible={null} itemIsVisible={this.props.item.isEnabled} viewIsVisible={false} />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default QuestionRangeItem;
