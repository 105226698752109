import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

const OptionList = (props) => {
  let listItems = null;
  if (props.items && props.Component) {
    listItems = props.items.map(item =>
      <props.Component key={item.id} id={item.id} text={item.name} imageUrl={item.imageUrl} updateOption={props.updateOption} checked={item.checked} />);
  }

  return (
    <Container className={props.className} fluid>
      {listItems}      
    </Container>
  );
};

OptionList.propTypes = {
  items: PropTypes.array,
  Component: PropTypes.elementType,
  updateOption: PropTypes.func,
  className: PropTypes.string
};

export default OptionList;
