import React from 'react';
import Modal from 'react-modal';
import { Button } from 'reactstrap';
import Reset from '../../images/start_over_big.png';
import { actionCloseStartOver } from './StartOverActions';
import { connect } from 'react-redux';
import './StartOverContainer.css';

Modal.setAppElement('#root');

const StartOverContainer = (props) => {
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.close}
      contentLabel="Example Modal"
      className="startover">
      <div className="startover__image">
        <img alt="start over" src={Reset} />
      </div>
      <div className="startover__message">
        Are you sure you want to start over?
      </div>
      <div className="mb-3">
        <Button className="startover__proceed" color="primary" onClick={props.onProceed}>Yes, proceed</Button>
      </div>
      <div>
        <Button className="startover__cancel" onClick={props.close} color="link">Cancel</Button>
      </div>      
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    modalIsOpen: state.startover.modalIsOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    close: () => dispatch(actionCloseStartOver())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(StartOverContainer);
