import DistributorClientConstants from "./DistributorClientConstants";
import DistributorClientModel from "./DistributorClientModel";

const initialState = {
  selected: DistributorClientModel.getEmpty()
};

const DistributorClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case DistributorClientConstants.GET_ACTION_TYPE:
      return {
        ...state,
        selected: action.distributor
      };
    default:
      return state;
  }
};

export default DistributorClientReducer;
