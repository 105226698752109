import React from 'react';
import { Container } from 'reactstrap';
import HelperContainer from '../helper/HelperContainer';
import OptionList from '../options/OptionsList';
import OptionCard from '../options/OptionCard';
import OptionGrid from '../options/OptionGrid';
import OptionIcon from '../options/OptionIcon';
import OptionPlain from '../options/OptionPlain';
import QuestionMessage from './QuestionMessage';
import PropTypes from 'prop-types';
import TypeLayoutEnum from '../../models/TypeLayoutEnum';

const QuestionContainer = (props) => {
  const layoutSetup = getLayoutSetup(props.data.layoutTypeCode);
  if (layoutSetup) {
    return (
      <Container className="wizard__wrapper" fluid>
        <Container className="wizard__title" fluid>
          <h4>{props.data.text}</h4>
        </Container>
        <QuestionMessage isMultipleChoice={props.data.isMultipleChoice} message="Click all that apply" />
        <OptionList className={layoutSetup.styles} items={props.data.options} Component={layoutSetup.component} updateOption={props.updateOption} />        
        <HelperContainer title={props.data.text} text={props.data.helpInfo} />
      </Container>
    );
  } else {
    return null;
  }
};

QuestionContainer.propTypes = {
  data: PropTypes.object,
  updateOption: PropTypes.func
};

const getLayoutSetup = (typeLayout) => {
  switch (typeLayout) {
    case TypeLayoutEnum.CARD:
      return {
        component: OptionCard,
        styles: 'wizard__layout wizard__layout--card'
      };
    case TypeLayoutEnum.BOX:
      return {
        component: OptionGrid,
        styles: 'wizard__layout wizard__layout--grid'
      };
    case TypeLayoutEnum.ICON:
      return {
        component: OptionIcon,
        styles: 'wizard__layout wizard__layout--icon'
      };
    case TypeLayoutEnum.PLAIN:
      return {
        component: OptionPlain,
        styles: 'wizard__layout wizard__layout--plain'
      };
    case TypeLayoutEnum.RANGE:
      return {
        component: OptionGrid,
        styles: 'wizard__layout wizard__layout--grid'
      };
    default:
      return null;
  }
};

export default QuestionContainer;
