import React, { Component } from 'react';
import Modal from 'react-modal';
import { Container, Button, Row, Col} from 'reactstrap';
import { connect } from 'react-redux';
import { actionClosePreviewSelect, actionSetCategoryPreview } from './PreviewActions';
import { actionGetCategoriesToPreview, actionResetWizardSelected, actionUpdateOption } from '../../client/wizard/WizardActions';
import QuestionContainer from '../../client/question/QuestionContainer';
import PathsApp from '../../utils/PathsApp';
import { withRouter } from 'react-router-dom';

Modal.setAppElement('#root');

class PreviewSelectModal extends Component {  
  handleClose = () => {
    this.props.close();
    this.props.reset();
  };

  handleContinue = () => {
    if (this.props.question && this.props.question.options) {
      const category = this.props.question.options.find(option => option.checked);
      if (category && category.id) {
        this.handleClose();
        this.props.selectCategory(category.id)
        this.props.history.push(PathsApp.PREVIEW_ROOT);
      }
    }
  };

    render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onRequestClose={this.handleClose}
        onAfterOpen={this.props.getCategories}
        contentLabel="Select Category"
        className="preview-select">
        <Container className="wizard" fluid>
          {this.props.question ? <QuestionContainer data={this.props.question} updateOption={this.props.updateOption} /> : null}
          <div className="wizard__navigation">
            <Row>
              <Col className="text-left">
                <Button className="wizard__secondary wizard__secondary--back" color="primary" onClick={this.handleClose}>
                  <i className="fas fa-chevron-left"></i><span>Back</span>
                </Button>
              </Col>
              <Col className="text-right">
                <Button className="button_main wizard__right" color="primary" onClick={this.handleContinue}>Yes, proceed<i className="fas fa-chevron-right"></i></Button>
              </Col>
            </Row>
          </div>
        </Container>
      </Modal>
    );
  }  
}

const mapStateToProps = state => {
  return {
    modalIsOpen: state.preview.modalIsOpen,
    categories: state.category.items,
    question: state.wizard.currentQuestion
  };
};

const mapDispatchToProps = dispatch => {
  return {
    close: () => dispatch(actionClosePreviewSelect()),
    getCategories: () => dispatch(actionGetCategoriesToPreview()),
    reset: () => dispatch(actionResetWizardSelected()),
    updateOption: (optionId, isChecked) => dispatch(actionUpdateOption(optionId, isChecked)),
    selectCategory: (categoryId) => dispatch(actionSetCategoryPreview(categoryId))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewSelectModal));
