import getAPIPath from '../../utils/PathResolver';
export default class QuestionApi {
  static GetQuestion = (id) => { return `${getAPIPath()}question/GetById?id=${id}`; }
  static GetQuestions = (categoryId) => { return `${getAPIPath()}question/GetAllByCategory?categoryId=${categoryId}`; }
  static ToggleQuestion = (id) => { return `${getAPIPath()}question/Toggle?id=${id}`; }
  static DeleteQuestion = (id) => { return `${getAPIPath()}question?id=${id}`; }
  static get GetLayoutTypes() { return `${getAPIPath()}product/GetLayoutTypes`; }
  static get PostQuestion() { return `${getAPIPath()}question`; }
  static get PutQuestion() { return `${getAPIPath()}question`; }
  static get PutQuestionOrder() { return `${getAPIPath()}question/UpdateOrderNumber`; }
}
