import WizardConstants from './WizardConstants';
import WizardApi from './WizardApi';
import axios from 'axios';
import { actionErrorAuth } from '../../admin/login/LoginAction';
import CategoryApi from '../../admin/categories/CategoryApi';

const errorData = (dispatch) => {
  dispatch({
    type: WizardConstants.GET_SETUP_ACTION_TYPE,
    data: null
  });
  dispatch({
    type: WizardConstants.MESSAGE_ACTION_TYPE,
    message: WizardConstants.MESSAGE_ERROR
  });
}

const getData = async (dispatch, category, typeSetup) => {
  try {
    dispatch({
      type: WizardConstants.SET_CATEGORY_ACTION_TYPE,
      category: category
    });
    const endpoint = typeSetup === WizardConstants.CLIENT ? WizardApi.Get : WizardApi.GetDraft;
    const response = await axios.get(endpoint(category));
    if (response.data && response.data.length > 0) {
      response.data = response.data.map(question => {
        question.options = question.options.map(option => {
          option.checked = false;
          return option;
        });
        return question;
      });      
      dispatch({
        type: WizardConstants.GET_SETUP_ACTION_TYPE,
        data: response.data
      });
      dispatch({
        type: WizardConstants.MESSAGE_ACTION_TYPE,
        message: null
      });
    } else {
      errorData(dispatch);
    }
  } catch (e) {
    if (typeSetup === WizardConstants.PREVIEW) {
      dispatch(actionErrorAuth(e));
    }
    errorData(dispatch);
  }
};

const convertCategoryToQuestion = (categories) => {
  let question = [{
    id: "0",
    text: "Which category do you want to preview?",
    isMultipleChoice: false,
    layoutTypeCode: "CARD",
  }];
  question[0].options = categories.map(category => {
    return {
      id: category.id,
      name: category.name,
    }
  });
  return question;
};

const getCategoriesToPreview = async (dispatch) => {
  try {
    const response = await axios.get(CategoryApi.GetCategories);
    if (response.data && response.data) {
      let questions = convertCategoryToQuestion(response.data);      
      questions = questions.map(question => {
        question.options = question.options.map(option => {
          option.checked = false;
          return option;
        });
        return question;
      });
      dispatch({
        type: WizardConstants.GET_SETUP_ACTION_TYPE,
        data: questions
      });
      dispatch({
        type: WizardConstants.MESSAGE_ACTION_TYPE,
        message: null
      });
      dispatch(actionGetQuestion(0));
    } else {
      dispatch({
        type: WizardConstants.GET_SETUP_ACTION_TYPE,
        data: null
      });
    }
  } catch (e) {
    dispatch({
      type: WizardConstants.GET_SETUP_ACTION_TYPE,
      data: null
    });
  }
};

const getNumberProductsEndpoint = (typeSetup, category, distributorId) => {
  if (typeSetup === WizardConstants.CLIENT) {
    return WizardApi.GetNumberProductsClient(category, distributorId);
  } else {
    return WizardApi.GetNumberProductsPreview(category);
  }
}

const getNumberProducts = async (dispatch, category, typeSetup, distributorId) => {
  try {
    const response = await axios.get(getNumberProductsEndpoint(typeSetup, category, distributorId));
    if (response.data) {
      dispatch({
        type: WizardConstants.GET_NUMBER_PRODUCTS_ACTION_TYPE,
        numberProducts: response.data
      });
    }    
  } catch (e) {
    console.log('TODO-SERVER', e.message);
  }
};

const actionGetSetup = (category, typeSetup) => {
  return dispatch => {
    dispatch({
      type: WizardConstants.LOADING_ACTION_TYPE
    });
    getData(dispatch, category, typeSetup);
  };
};

const actionGetQuestion = (index) => {
  return {
    type: WizardConstants.GET_QUESTION_ACTION_TYPE,
    index: index
  };
};

const actionUpdateOption = (optionId, isChecked) => {
  return {
    type: WizardConstants.UPDATE_OPTION_ACTION_TYPE,
    optionId: optionId,
    isChecked: isChecked
  };
};

const actionResetWizardSelected = () => {
  return {
    type: WizardConstants.RESET_SELECTED_ACTION_TYPE
  };
};

const actionGetCategoriesToPreview = () => {
  return dispatch => {
    getCategoriesToPreview(dispatch);
  };
};

const actionGetNumberProducts = (category, typeSetup, distributorId) => {
  return dispatch => {
    getNumberProducts(dispatch, category, typeSetup, distributorId);
  };
};

const actionBackWizard = (indexBack) => {
  return dispatch => {    
    dispatch({
      type: WizardConstants.BACK_ACTION_TYPE,
      indexBack: indexBack
    });
  };
};

export { actionGetSetup, actionGetQuestion, actionUpdateOption, actionResetWizardSelected, actionGetCategoriesToPreview, actionGetNumberProducts, actionBackWizard };
