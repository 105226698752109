import axios from 'axios';
import DistributorClientApi from './DistributorClientApi';
import DistributorClientConstants from './DistributorClientConstants';
import DistributorClientModel from './DistributorClientModel';
import DistributorClienUtils from './DistributorClientUtils';

const getDistributor = async (dispatch, token, callback) => {
  try {
    let distributorData = {};
    if (DistributorClienUtils.isDefault(token)) {
      distributorData = new DistributorClientModel(DistributorClientConstants.DEFAULT, '', '');
      dispatch({
        type: DistributorClientConstants.GET_ACTION_TYPE,
        distributor: distributorData
      });
    } else {
      const response = await axios.get(DistributorClientApi.GetDistributor(token));
      if (response.data) {
        distributorData = new DistributorClientModel(response.data.id, response.data.name, response.data.layoutUrl);
        dispatch({
          type: DistributorClientConstants.GET_ACTION_TYPE,
          distributor: distributorData
        });
      } else {
        distributorData = null
        dispatch({
          type: DistributorClientConstants.GET_ACTION_TYPE,
          distributor: DistributorClientModel.getEmpty()
        });
      }
    }    
    callback(distributorData);
  } catch (e) {
    console.log('error', e);
  }
};

const actionGetDistributorByToken = (token, callback) => {
  return dispatch => {
    getDistributor(dispatch, token, callback);
  };
};

export { actionGetDistributorByToken }