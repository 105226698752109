import React from 'react';
import { Button } from 'reactstrap';
import './ButtonAdd.css';
import Plus from '../../images/c-add.png';
import PropTypes from 'prop-types';

const ButtonAdd = (props) => {
  return (
    <Button className="button-add text-left" color="primary" onClick={props.handleAdd}>
      {props.text}
      <img className="button-add__icon" alt="add" src={Plus} />
    </Button>
  );
};

ButtonAdd.propTypes = {
  text: PropTypes.string,
  handleAdd: PropTypes.func
};

export default ButtonAdd;