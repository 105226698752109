import React from 'react';
import './NavApp.css'

const NavApp = (props) => {
  return (
    <header className="navapp">
      {props.children}
    </header>
  );
}

export default NavApp;
