import ProductConstants from "./ProductConstants";
import ProductSortEnum from "./ProductSortEnum";
import ProductModel from "./ProductModel";
import UuidUtil from "../../utils/UuidUtil";

const initialState = {
  items: null,
  messageList: null,
  sortBy: ProductSortEnum.SKU,
  search: '',
  selected: ProductModel.getEmpty(),
  modalIsOpen: false,
  totalItems: 0,
  totalPages: 0,
  featureSelected: '',
  tempOptions: [],
  messageListAction: null,
  messageAction: null
};

const setProductToggle = (items, id) => {
  return items.map((item) => {
    return item.id === id ? { ...item, isEnabled: !item.isEnabled } : item;
  });
};

const setProductDeleted = (items, id) => {
  return items.filter((item) => {
    return item.id !== id;
  });
};

const updateProductFeatureOptions = (productFeatures, optionSelected, featureSelected, productId) => {

    if (productFeatures.find(pf => pf.optionId === optionSelected.id)) {
        productFeatures = productFeatures.filter(pf => pf.optionId !== optionSelected.id);        
        return productFeatures;
    } else {
        productFeatures.push({
            featureId: optionSelected.featureId,
            id: UuidUtil.EMPTY,
            optionId: optionSelected.id,
            optionName: optionSelected.name,
            productId: productId
        });
       
    return productFeatures;
  }
};

const updateProductFeatures = (productFeatures, featureSelected) => {
    if (productFeatures.find(pf => pf.featureId === featureSelected)) {
        return productFeatures.filter(pf => {
            return pf.featureId !== featureSelected;
        });
    }
    else {
        return productFeatures;
    }
};

const cancelOptions = (productFeatures, tempOptions, featureSelected) => {
  if (tempOptions.length === 0) {
    return productFeatures.filter(pf => {
      return pf.featureId !== featureSelected;
    });
  } else {
      const options = productFeatures.filter(pf => pf.featureId !== featureSelected);
      options.push(...tempOptions);
      return options;
  }
};

const ProductAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProductConstants.GET_ALL_ACTION_TYPE:
      return {
        ...state,
        items: action.items,
        selected: ProductModel.getEmpty(),
        totalItems: action.totalItems,
        totalPages: action.totalPages
      };
    case ProductConstants.MESSAGE_LIST_ACTION_TYPE:
      return {
        ...state,
        messageList: action.message
      };
    case ProductConstants.SET_ORDER_BY_ACTION_TYPE:
      return {
        ...state,
        sortBy: action.sortBy
      };
    case ProductConstants.SET_SEARCH_ACTION_TYPE:
      return {
        ...state,
        search: action.search
      };
    case ProductConstants.TOGGLE_ACTION_TYPE:
      return {
        ...state,
        items: setProductToggle(state.items, action.id),
        selected: { ...state.selected, isEnabled: !state.selected.isEnabled }
      };
    case ProductConstants.DELETE_ACTION_TYPE:
      return {
        ...state,
        items: setProductDeleted(state.items, action.id),
        selected: !action.isFromList ? { ...state.selected, exists: false } : { ...state.selected }
      };
    case ProductConstants.BEGIN_ADD_ACTION_TYPE:
      return {
        ...state,
        selected: ProductModel.getEmpty()
      };
    case ProductConstants.CHANGE_VALUE_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, [action.propertie]: action.value }
      };
    case ProductConstants.GET_ACTION_TYPE:
      return {
        ...state,
        selected: action.product
      };
    case ProductConstants.OPEN_OPTIONS_ACTION_TYPE:
      return {
        ...state,
        modalIsOpen: true,
        featureSelected: action.featureSelected,
        tempOptions: action.options
      };
    case ProductConstants.CLOSE_OPTIONS_ACTION_TYPE:
      return {
        ...state,
        modalIsOpen: false
      };
    case ProductConstants.UPDATE_OPTION_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, productFeatures: updateProductFeatureOptions(state.selected.productFeatures, action.optionSelected, state.featureSelected, state.selected.id) }
      };
    case ProductConstants.UPDATE_FEATURE_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, productFeatures: updateProductFeatures(state.selected.productFeatures, action.featureSelected, state.selected.id) }
      };
    case ProductConstants.CANCEL_OPTIONS_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, productFeatures: cancelOptions(state.selected.productFeatures, state.tempOptions, state.featureSelected) }
      };
    case ProductConstants.SAVE_ADD_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, exists: false }
      };
    case ProductConstants.SAVE_EDIT_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, exists: false }
      };
    case ProductConstants.MESSAGE_LIST_ACTION_ACTION_TYPE:
      return {
        ...state,
        messageListAction: action.messageListAction
      };
    case ProductConstants.MESSAGE_ACTION_ACTION_TYPE:
      return {
        ...state,
        messageAction: action.messageAction
      };
    case ProductConstants.GET_ALL_RESUME_ACTION_TYPE:
      return {
        ...state,
        items: action.items,
        selected: ProductModel.getEmpty(),
        totalItems: action.totalItems,
        totalPages: action.totalPages
      };
    default:
      return state;
  }
};

export default ProductAdminReducer;
