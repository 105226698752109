export default class PreviewConstants {
  static get SET_CATEGORY_ACTION_TYPE() { return 'preview/SET_CATEGORY'; }
  static get DEPLOY_ACTION_TYPE() { return 'preview/DEPLOY'; }
  static get MESSAGE_ACTION_TYPE() { return 'preview/MESSAGE'; }
  static get SELECT_OPEN_ACTION_TYPE() { return 'preview/SELECT_OPEN'; }
  static get SELECT_CLOSE_ACTION_TYPE() { return 'preview/SELECT_CLOSE'; }
  static get MESSAGE_DEPLOY_OK() { return { type: 'success', text: 'Deployed succesfully.' }; }
  static get MESSAGE_DEPLOY_ERROR() { return { type: 'danger', text: 'Error deploying.' }; }
  static get MESSAGE_NO_QUESTIONS_ERROR() { return { type: 'danger', text: 'No questions found.' }; }
}
