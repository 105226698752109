import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const ButtonModal = (props) =>  {
  return (
    <Fragment>
      <Button onClick={props.confirm} className="button_main" color="primary">{props.confirmText}</Button>
      <Button onClick={props.cancel} color="link" className="product-modal__cancel">{props.cancelText}</Button>
    </Fragment>
  );
};

ButtonModal.propTypes = {
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  confirm: PropTypes.func,
  cancel: PropTypes.func
};

export default ButtonModal;
