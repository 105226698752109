import React, { Component, Fragment } from 'react';
import { Container, Alert } from 'reactstrap';
import './QuestionListContainer.css';
import '../Admin.css';
import HeaderAdd from '../header/HeaderAdd';
import QuestionItem from './QuestionItem';
import { withRouter } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Row, Col } from 'reactstrap';
import PathsApp from '../../utils/PathsApp';
import { connect } from 'react-redux';
import { actionGetQuestions, actionBeginListQuestion, actionToggleQuestion, actionDeleteQuestion, actionOrderQuestion, actionLoadQuestion, actionMessageQuestion } from './QuestionAction';
import AlertContainer from '../../alerts/AlertContainer';

const SortableItem = SortableElement(({ value, handleVisible, handleDelete, handleLoad }) => <QuestionItem item={value} handleVisible={handleVisible} handleDelete={handleDelete} handleLoad={handleLoad} />);

const SortableList = SortableContainer(({ items, handleVisible, handleDelete, handleLoad }) => {
  if (items) {
    return (
      <div>
        <Row className="list-admin">
          <Col className="pl-0 pr-0">
            {items.map((value, index) => (
              <SortableItem key={`item-${index}`} index={index} value={value} handleVisible={handleVisible} handleDelete={handleDelete} handleLoad={handleLoad} />
            ))}
          </Col>
        </Row>
      </div>
    );
  } else {
    return null;
  }
});

class QuestionListContainer extends Component {

  componentDidMount() {
    this.props.beginList();
  }

  handleAdd = (event) => {
    event.preventDefault();
    this.props.history.push(PathsApp.QUESTIONS_ADD);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.order(oldIndex, newIndex, this.props.questions);
  };

  changeCategory = (event) => {
    this.props.selectCategory(event.target.value);
  };

  componentWillUnmount() {
    this.props.resetMessage();
  }

  render() {
    return (
      <Fragment>
        <AlertContainer alert={this.props.messageListAction} />
        <Container className="page-admin page-admin--feature" fluid>
          <HeaderAdd title={"Questions"} addText={"Add Question"} handleAdd={this.handleAdd} />
          <Container className="page-admin__list-container pb-5" fluid>
            <Row className="pb-2">
              <Col lg="3">
              {
                this.props.categories && this.props.categorySelected ?
                  <div className="select-admin__wrapper fa">
                    <select className="select-admin__control" onChange={this.changeCategory} value={this.props.categorySelected}>
                      {
                        this.props.categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)
                      }
                    </select>
                  </div>
                  : null
              }
              </Col>
            </Row>
            {
            this.props.questions ?
              <SortableList items={this.props.questions} onSortEnd={this.onSortEnd} distance={10} handleVisible={this.props.toggle} handleDelete={this.props.delete} handleLoad={this.props.load} />
                : null
            }
            {
            this.props.message ?
              <Alert className="mt-2" color="warning">{this.props.message}</Alert>
                : null
            }
          </Container>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.category.items,
    questions: state.question.items,
    categorySelected: state.question.category,
    message: state.question.messageList,
    messageListAction: state.question.messageListAction
  };
};

const mapDispatchToProps = dispatch => {
  return {
    beginList: () => dispatch(actionBeginListQuestion()),
    selectCategory: (category) => dispatch(actionGetQuestions(category)),
    toggle: (questionId) => dispatch(actionToggleQuestion(questionId)),
    delete: (questionId, isFromList) => dispatch(actionDeleteQuestion(questionId, isFromList)),
    order: (oldIndex, newIndex, oldQuestions) => dispatch(actionOrderQuestion(oldIndex, newIndex, oldQuestions)),
    load: (question) => dispatch(actionLoadQuestion(question)),
    resetMessage: () => dispatch(actionMessageQuestion(null))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionListContainer));
