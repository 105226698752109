import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import ButtonGroup from '../buttons/ButtonGroup';
import BaseItem from '../items/BaseItem';
import { withRouter } from 'react-router-dom';
import BaseModel from '../../models/BaseModel';
import uuid from 'uuid/v1';
import PropTypes from 'prop-types';

class ItemParent extends BaseItem {

  handleEdit = (event) => {
    event.preventDefault();
    const isEditing = this.state.isEditing;
    const name = this.state.name;
    this.setState({ isEditing: !isEditing, previousName: name });
  }

  continueDetails = () => {
    if (!this.state.isEditing && this.state.pathRedirect) {
      this.props.history.push(this.state.pathRedirect + this.state.id);
    }
  }

  handleSave = (event) => {
    event.preventDefault();
    const isEditing = this.state.isEditing;
    if (this.state.id === '') {
      const tempid = uuid().toString();
      this.setState({ isEditing: !isEditing, id: tempid });
      this.props.saveAdd(new BaseModel(tempid, this.state.name), this.props.parentId);
    } else {
      this.setState({ isEditing: !isEditing });
      this.props.saveEdit(new BaseModel(this.state.id, this.state.name), this.props.parentId);
    }
  }

  handleDelete = (event) => {
    event.preventDefault();
    this.props.delete(this.state.id);
  }

  handleCancel = (event) => {
    event.preventDefault();    
    if (this.state.id === '') {
      this.props.cancelAdd();
    } else {
      const isEditing = this.state.isEditing;
      const previuos = this.state.previousName;
      this.setState({ isEditing: !isEditing, name: previuos });
    }
  }

  handleChangeItem = (event) => {
    this.setState({ name: event.target.value });
  }

  componentDidMount() {
    this.setState({
      id: this.props.item.id,
      name: this.props.item.name,
      previousName: this.props.item.name,
      isEditing: this.props.item.id === '',
      pathRedirect: this.props.path
    });
  }

  render() {
    return (
      <Row className="item-admin" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        <Col lg="9" className="item-admin__text" onClick={this.continueDetails}>
          {
            this.state.isEditing ? <Input type="text" name="newItem" className="input-admin input-admin--small" id="newItem" placeholder={this.props.placeholderText} onChange={this.handleChangeItem} value={this.state.name} /> : this.state.name
          }
        </Col>
        <Col lg="3">
          <Row>
            {
              this.state.isMouseInside ?
                <ButtonGroup
                  handleEdit={!this.state.isEditing ? this.handleEdit : null}
                  handleDelete={!this.state.isEditing ? this.handleDelete : null}
                  handleSave={this.state.isEditing ? this.handleSave : null}
                  handleCancel={this.state.isEditing ? this.handleCancel : null}
                  viewIsVisible={false} />
                : null
            }
          </Row>
        </Col>
      </Row>
    );
  }
}

ItemParent.propTypes = {
  placeholderText: PropTypes.string,
  item: PropTypes.object,
  path: PropTypes.string,
  saveAdd: PropTypes.func,
  cancelAdd: PropTypes.func,
  saveEdit: PropTypes.func,
  delete: PropTypes.func
};

export default withRouter(ItemParent);
