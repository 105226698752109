import QuestionConstants from "./QuestionConstants";
import axios from 'axios';
import QuestionApi from "./QuestionApi";
import CategoryApi from "../categories/CategoryApi";
import CategoryConstants from "../categories/CategoryConstants";
import FeatureApi from "../features/FeatureApi";
import FeatureConstants from "../features/FeatureConstants";
import { actionGetCategories } from "../categories/CategoryActions";
import { actionGetFeatures } from "../features/FeatureActions";
import QuestionModel from "./QuestionModel";
import QuestionAnswerModel from "./QuestionAnswerModel";
import uuid from 'uuid/v1';
import OptionApi from "../options/OptionApi";
import OptionConstants from "../options/OptionConstants";
import arrayMove from 'array-move';
import UuidUtil from "../../utils/UuidUtil";
import UploadFile from "../../utils/UploadFile";
import { actionErrorAuth } from "../login/LoginAction";
import { actionSetErrorUploadImage } from "../uploadImage/UploadImageActions";
import TypeLayoutEnum from "../../models/TypeLayoutEnum";
import OptionModel from "../options/OptionModel";
import RangeModel from "./RangeModel";
import BaseModel from "../../models/BaseModel";

const sortOptions = (options, answers) => {
  if (answers.every(answer => answer.option)) {
    answers.forEach(answer => {
      const tempIndexOption = options.findIndex(option => {
        return option.id === answer.option.id;
      });
      if (tempIndexOption > -1) {
        const tempAnswer = options[answer.orderNumber];
        options[answer.orderNumber] = options[tempIndexOption];
        options[tempIndexOption] = tempAnswer;
      }
    });
  }
  return options.filter(option => typeof option === 'object');
};

const getOptions = async (dispatch, featureId, answers) => {
  try {
    const response = await axios.get(OptionApi.GetOptions(featureId));
    if (response.data) {      
      dispatch({
        type: OptionConstants.GET_ALL_ACTION_TYPE,
        items: answers && answers.length > 0 ? sortOptions(response.data, answers) : response.data
      });
      if (response.data.length > 0) {
        dispatch({
          type: QuestionConstants.MESSAGE_OPTIONS_ACTION_TYPE,
          message: null
        });
      } else {
        dispatch({
          type: QuestionConstants.MESSAGE_OPTIONS_ACTION_TYPE,
          message: QuestionConstants.MESSAGE_OPTIONS_NOT_FOUND
        });        
      }  
    } else {
      dispatch({
        type: QuestionConstants.MESSAGE_OPTIONS_ACTION_TYPE,
        message: QuestionConstants.MESSAGE_OPTIONS_NOT_FOUND
      });
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: OptionConstants.GET_ALL_ACTION_TYPE,
      items: null
    });
  }
};

const getQuestions = async (dispatch, categoryId) => {
  try {
    const response = await axios.get(QuestionApi.GetQuestions(categoryId));
    if (response.data) {      
      dispatch({
        type: QuestionConstants.GET_ALL_BY_CATEGORY_ACTION_TYPE,
        items: response.data.length > 0 ? response.data : null,
        category: categoryId
      });
      if (response.data.length > 0) {
        dispatch({
          type: QuestionConstants.MESSAGE_LIST_ACTION_TYPE,
          message: null
        });
      } else {
        dispatch({
          type: QuestionConstants.MESSAGE_LIST_ACTION_TYPE,
          message: QuestionConstants.MESSAGE_LIST_NOT_FOUND
        });
      }  
    } else {
      dispatch({
        type: QuestionConstants.MESSAGE_LIST_ACTION_TYPE,
        message: QuestionConstants.MESSAGE_LIST_NOT_FOUND
      });
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: QuestionConstants.GET_ALL_BY_CATEGORY_ACTION_TYPE,
      items: null,
      category: categoryId
    });
  }
};

const beginList = async (dispatch) => {
  try {
    const response = await axios.get(CategoryApi.GetCategories);
    if (response.data) {
      dispatch({
        type: CategoryConstants.GET_ALL_ACTION_TYPE,
        items: response.data.length > 0 ? response.data : null
      });      
      if (response.data.length > 0) {        
        dispatch(actionGetQuestions(response.data[0].id));//TODO - There should be a way to get main category or endpoint get categories in order , ie. first category result is the main one
      }
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: QuestionConstants.BEGIN_LIST_ACTION_TYPE,
      categories: null,
    });
  }
};

const changeCategory = async (dispatch, categoryId) => {
  try {    
    const response = await axios.get(FeatureApi.GetFeatures(categoryId));
    if (response.data) {
      dispatch({
        type: FeatureConstants.GET_ALL_ACTION_TYPE,
        items: response.data,
        totalItems: response.data.length,
        totalPages: 1
      });        
        if (response.data.length > 0) {
            dispatch(actionChangeValueQuestion('feature', response.data[0]));
            dispatch(actionChangeFeature(response.data[0].id));
        }
        else {
          dispatch({
              type: OptionConstants.RESET_ACTION_TYPE
          });
          dispatch({
              type: QuestionConstants.MESSAGE_OPTIONS_ACTION_TYPE,
              message: QuestionConstants.MESSAGE_OPTIONS_NOT_FOUND
          });
          dispatch(actionChangeValueQuestion('feature', BaseModel.getEmpty()));
          dispatch(actionChangeFeature(''));
        }
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: FeatureConstants.GET_ALL_ACTION_TYPE,
      items: null,
      totalItems: 0,
      totalPages: 0
    });
  }
};

const getDataToQuestions = async (dispatch) => {
  try {
    dispatch(actionGetLayoutTypes(true));
    const response = await axios.get(CategoryApi.GetCategories);
    if (response.data) {
      dispatch({
        type: CategoryConstants.GET_ALL_ACTION_TYPE,
        items: response.data.length > 0 ? response.data : null
      });
      if (response.data.length > 0) {
        dispatch(actionChangeValueQuestion('category', response.data[0]));
        changeCategory(dispatch, response.data[0].id);
      }
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const toggleQuestion = async (dispatch, questionId) => {
  try {
    const response = await axios.delete(QuestionApi.ToggleQuestion(questionId));
    if (response.data) {
      dispatch({
        type: QuestionConstants.TOGGLE_ACTION_TYPE,
        id: questionId
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const deleteQuestion = async (dispatch, questionId, isFromList) => {
  try {
    const response = await axios.delete(QuestionApi.DeleteQuestion(questionId));
    if (response.data) {
      dispatch({
        type: QuestionConstants.DELETE_ACTION_TYPE,
        id: questionId,
        isFromList: isFromList
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const getQuestion = async (dispatch, questionId) => {
  try {
    const response = await axios.get(QuestionApi.GetQuestion(questionId));
    if (response.data && response.data.length > 0) {
      const question = response.data[0];
      initImages(dispatch, question);
      dispatch(actionLoadQuestion(question));
      dispatch(actionGetLayoutTypes(false));
      if (question.ranges && question.ranges.length === 0) {
        dispatch(actionAddRange(RangeModel.getEmpty()));
      }
      dispatch(actionGetCategories());
      dispatch(actionGetFeatures(response.data[0].category.id));
      getOptions(dispatch, response.data[0].feature.id, question.answers);
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const initImages = (dispatch, question) => {
  try {
    question.answers.forEach(answer => {
      dispatch(actionSetErrorUploadImage(answer.option.id, answer.imageUrl && answer.imageUrl.length > 0 ? false : true));
    });
  } catch (e) {
    console.log("TODO - error answer initImages");
  }  
}

const getLayoutTypes = async (dispatch, isAdd) => {
  try {
    const response = await axios.get(QuestionApi.GetLayoutTypes);
    if (response.data && response.data.length > 0) {
      dispatch({
        type: QuestionConstants.GET_LAYOUT_TYPES_ACTION_TYPE,
        layoutTypes: response.data
      });
      if (isAdd) {
        dispatch(actionChangeValueQuestion('layoutType', response.data[0]));
      }
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - NO DATA ON SERVER");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const uploadFile = async (dispatch, file, optionId) => {
  try {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await axios.post(UploadFile.Api, formData);
    if (response.data) {
      dispatch({
        type: QuestionConstants.UPLOAD_IMAGE_ACTION_TYPE,
        optionId: optionId,
        imageUrl: response.data.dbPath
      });
      dispatch(dispatch(actionSetErrorUploadImage(optionId, false)));
    } else {
      dispatch(dispatch(actionSetErrorUploadImage(optionId, true)));
    }
  } catch (e) {
    if (e && e.response && e.response.status === 400 && e.response.data) {
      dispatch(actionMessageQuestionDetail(QuestionConstants.MESSAGE_ADD_ERROR(e.response.data)));
    }
    dispatch(actionErrorAuth(e));
  }
};

const actionGetQuestions = (categoryId) => {
  return dispatch => {
    getQuestions(dispatch, categoryId)
  };
};

const actionBeginListQuestion = () => {
  return dispatch => {
    dispatch({
      type: QuestionConstants.BEGIN_LIST_ACTION_TYPE
    });
    beginList(dispatch);
  };
};

const actionBeginAddQuestion = () => {
  return dispatch => {
    dispatch({
      type: QuestionConstants.MESSAGE_LIST_ACTION_ACTION_TYPE,
      messageListAction: null
    });
    dispatch({
      type: QuestionConstants.BEGIN_ADD_ACTION_TYPE
    });
    dispatch(actionAddRange(RangeModel.getEmpty()));
    dispatch({
      type: QuestionConstants.MESSAGE_OPTIONS_ACTION_TYPE,
      message: null
    });
    getDataToQuestions(dispatch);
  };
};

const actionBeginEditQuestion = (questionId) => {
  return dispatch => {
    dispatch({
      type: QuestionConstants.MESSAGE_LIST_ACTION_ACTION_TYPE,
      messageListAction: null
    });
    dispatch({
      type: QuestionConstants.MESSAGE_OPTIONS_ACTION_TYPE,
      message: null
    });
    getQuestion(dispatch, questionId);
  };
};

const actionToggleQuestion = (questionId) => {
  return dispatch => {
    toggleQuestion(dispatch, questionId);
  };
};

const actionDeleteQuestion = (questionId, isFromList) => {
  return dispatch => {
    deleteQuestion(dispatch, questionId, isFromList);
  };
};

const actionChangeCategory = (categoryId) => {
  return dispatch => {
    changeCategory(dispatch, categoryId);
  };
};

const actionChangeValueQuestion = (propertie, value) => {
  return dispatch => {
    dispatch({
      type: QuestionConstants.CHANGE_VALUE_ACTION_TYPE,
      propertie: propertie,
      value: value
    });
  };
};

const actionChangeAnswer = (answer, isEnabled) => {
  return dispatch => {
    dispatch({
      type: QuestionConstants.CHANGE_ANSWER_ACTION_TYPE,
      answer: answer,
      isEnabled: isEnabled
    });
  };
};

const actionChangeFeature = (featureId) => {
  return dispatch => {
    dispatch({
      type: QuestionConstants.RESET_ANSWERS_ACTION_TYPE
    });
    getOptions(dispatch, featureId);
  };
};

const actionGetLayoutTypes = (isAdd) => {
  return dispatch => {
    getLayoutTypes(dispatch, isAdd);
  };
};

const objectToQuestionRequest = (question, isAdd) => {
  let answers = [];
  if (isAdd && question.layoutType.code === TypeLayoutEnum.RANGE) {
    answers = question.ranges.map((range, index) => new QuestionAnswerModel(range.id, question.id, new OptionModel(UuidUtil.EMPTY, '', '', UuidUtil.EMPTY), range.isEnabled, '', index + 1, range.minValue === '' ? 0 : range.minValue, range.maxValue === '' ? 0 : range.maxValue, range.description))
  } else {
    answers = question.answers.map(answer => new QuestionAnswerModel(answer.id, answer.questionId, answer.option, answer.isEnabled, answer.imageUrl, answer.orderNumber, answer.minValue, answer.maxValue, answer.description));
  }  
  const questionRequest = new QuestionModel(
    isAdd ? uuid().toString() : question.id,
    question.text,
    question.isMultipleChoice,
    question.layoutType,
    question.category,
    question.feature,
    question.helpInfo,
    answers,
    question.isEnabled,
    question.orderNumber,
    question.exists).toRequest();
  return questionRequest;
}

const saveQuestion = async (dispatch, question) => {  
  try {
    const response = await axios.post(QuestionApi.PostQuestion, objectToQuestionRequest(question, true));
    if (response.data) {
      dispatch(actionMessageQuestion(QuestionConstants.MESSAGE_ADD_OK));
      dispatch({
        type: QuestionConstants.SAVE_ADD_ACTION_TYPE,
      });
    }
  } catch (e) {
    if (e && e.response && e.response.status === 400 && e.response.data) {
      dispatch(actionMessageQuestionDetail(QuestionConstants.MESSAGE_ADD_ERROR(e.response.data)));
    }
    dispatch(actionErrorAuth(e));
  }  
};

const getAnswerToUpdate = (originalAnwers, newAnswers) => {
  if (originalAnwers.length === newAnswers.length && originalAnwers.every(original => newAnswers.find(newanswer => newanswer.option.id === original.option.id && newanswer.isEnabled === original.isEnabled))) {//IF THERE ARE SAME ANSWERS
    originalAnwers = originalAnwers.map(original => {
      let found = newAnswers.find(newanswer => newanswer.option.id === original.option.id);
      original.imageUrl = found.imageUrl;
      original.orderNumber = found.orderNumber;
      return original;
    });//UPDATE IMAGEURL, ORDERNUMBER
    return originalAnwers;//RETURN THE ORIGINAL
  } else {//FIND DIFFERENCES
    let intersection = originalAnwers.filter(original => newAnswers.find(newanswer => newanswer.option.id === original.option.id) ? true : false);
    intersection = intersection.map(inter => {
      let found = newAnswers.find(newanswer => newanswer.option.id === inter.option.id);
      inter.isEnabled = found.isEnabled;
      inter.imageUrl = found.imageUrl;
      inter.orderNumber = found.orderNumber;
      return inter;
    });
    if (intersection) { //THERE ARE COMMON ANSWERS BETWEEN ORIGINAL AND NEWS
      let originalMinusNews = originalAnwers.filter(original => newAnswers.find(newanswer => newanswer.option.id === original.option.id) ? false : true);
      originalMinusNews = originalMinusNews.map(answer => {
        answer.isEnabled = false;
        return answer;
      });
      originalMinusNews = originalMinusNews.concat(intersection);
      let newsMinusOriginal = newAnswers.filter(newanswer => originalAnwers.find(original => original.option.id === newanswer.option.id) ? false : true);
      newsMinusOriginal = newsMinusOriginal.map(answer => new QuestionAnswerModel(UuidUtil.EMPTY, answer.questionId, answer.option, answer.isEnabled, answer.imageUrl, answer.orderNumber, answer.minValue, answer.maxValue, answer.description));
      return originalMinusNews.concat(newsMinusOriginal);
    } else { //NEW ANSWERS ARE TOTALLY DIFFERENTS
      originalAnwers = originalAnwers.map(answer => {
        answer.isEnabled = false;
        return answer;
      });
      newAnswers = newAnswers.map(answer => new QuestionAnswerModel(UuidUtil.EMPTY, answer.questionId, answer.option, answer.isEnabled, answer.imageUrl, answer.orderNumber, answer.minValue, answer.maxValue, answer.description));
      return originalAnwers.concat(newAnswers);
    }
  }
};

const editQuestion = async (dispatch, question) => {
  try {
    if (question.layoutType.code === TypeLayoutEnum.RANGE) {
      question.answers = question.originalAnwers.map(answer => {
        if (answer.option) {
          answer.isEnabled = false;
        }
        return answer;
      });
      let rangesToAnswers = question.ranges.map((r, index) => new QuestionAnswerModel(r.id, question.id, new OptionModel(UuidUtil.EMPTY, '', '', UuidUtil.EMPTY), r.isEnabled, '', index + 1, r.minValue === '' ? 0 : r.minValue, r.maxValue === '' ? 0 : r.maxValue, r.description));
      rangesToAnswers.forEach(r => {
        if (r.id === UuidUtil.EMPTY) {
          question.answers.push(r);
        } else {
          const indexAnswer = question.answers.findIndex(a => a.id === r.id);
          if (indexAnswer > -1) {
            question.answers[indexAnswer] = r;
          }
        }        
      });
    } else {
      const answersOriginalWithOptions = question.originalAnwers.filter(answer => answer.option);
      const rangesOriginal = question.originalAnwers.filter(answer => !answer.option).map(answer => {
        answer.isEnabled = false;
        return answer;
      });
      const answersWithOptions = question.answers.filter(answer => answer.option);
      question.answers = rangesOriginal.concat(getAnswerToUpdate(answersOriginalWithOptions, answersWithOptions));
    }        
    const request = objectToQuestionRequest(question, false);
    const response = await axios.put(QuestionApi.PutQuestion, request);
    if (response.data) {
      dispatch(actionMessageQuestion(QuestionConstants.MESSAGE_UPDATE_OK));
      dispatch({
        type: QuestionConstants.SAVE_EDIT_ACTION_TYPE,
      });
    }
  } catch (e) {
    if (e && e.response && e.response.status === 400 && e.response.data) {
      dispatch(actionMessageQuestionDetail(QuestionConstants.MESSAGE_UPDATE_ERROR(e.response.data)));
    }
    dispatch(actionErrorAuth(e));
  }  
};

const actionSaveAddQuestion = (question) => {
  return dispatch => {
    dispatch(actionMessageQuestionDetail(null));
    saveQuestion(dispatch, question);
  };
};

const actionSaveEditQuestion = (question) => {
  return dispatch => {
    dispatch(actionMessageQuestionDetail(null));
    editQuestion(dispatch, question);
  };
};

const saveOrder = async (dispatch, oldIndex, newIndex, oldQuestions) => {
  try {
    const newQuestions = arrayMove(oldQuestions, oldIndex, newIndex);
    dispatch({
      type: QuestionConstants.ORDER_ACTION_TYPE,
      questions: newQuestions
    });
    const requestOrder = newQuestions.map((question, index) => ({ id: question.id, orderNumber: index + 1 }));    
    const response = await axios.put(QuestionApi.PutQuestionOrder, requestOrder);    
    if (response.data) {
      console.log("TODO - message to user", response.data);
    } else {
      dispatch({
        type: QuestionConstants.ORDER_ACTION_TYPE,
        questions: oldQuestions
      });
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: QuestionConstants.ORDER_ACTION_TYPE,
      questions: oldQuestions
    });
  }
};

const actionOrderQuestion = (oldIndex, newIndex, oldQuestions) => {
  return dispatch => {
    saveOrder(dispatch, oldIndex, newIndex, oldQuestions);
  };
};

const actionLoadQuestion = (question) => {
  question.exists = true;
  question.originalAnwers = question.answers;
  question.answers = question.answers.filter(answer => answer.isEnabled);
  if (question.layoutType.code === TypeLayoutEnum.RANGE) {
    question.ranges = question.answers.map(answer => new RangeModel(answer.id, answer.id, answer.minValue, answer.maxValue, answer.description, answer.isEnabled));
    question.answers = [];
  } else {
    question.ranges = [];
  }
  // if there are answers with same order. Bad data. So fix it
  if (question.answers && question.answers.length > 0) {
    const firstOrder = question.answers[0].orderNumber;
    if (question.answers.every(answer => answer.orderNumber === firstOrder)) {
      question.answers = question.answers.map((answer, index) => {
        answer.orderNumber = index;
        return answer;
      })
    }
  }
  return {
    type: QuestionConstants.GET_ACTION_TYPE,
    question: question,
    tempAnswers: question.layoutType.code === TypeLayoutEnum.RANGE ? [] : question.answers.map(answer => { return { optionId: answer.option.id, imageUrl: answer.imageUrl } })
  };
};

const actionUploadImageQuestion = (file, optionId) => {
  return dispatch => {
    dispatch(actionMessageQuestionDetail(null));
    uploadFile(dispatch, file, optionId);
  };
};

const actionMessageQuestion = (message) => {
  return {
    type: QuestionConstants.MESSAGE_LIST_ACTION_ACTION_TYPE,
    messageListAction: message
  };
};

const actionMessageQuestionDetail = (message) => {
  return {
    type: QuestionConstants.MESSAGE_ACTION_ACTION_TYPE,
    messageAction: message
  };
};

const actionOrderAnswers = (newOptions) => {
  return {
    type: QuestionConstants.ORDER_ANSWERS_ACTION_TYPE,
    newOptions: newOptions
  };
};

const actionAddRange = (range) => {
  return {
    type: QuestionConstants.ADD_RANGE_ACTION_TYPE,
    range: range
  }
};

const actionChangeRangeValue = (rangeKey, field, value) => {
  return {
    type: QuestionConstants.CHANGE_VALUE_RANGE_ACTION_TYPE,
    rangeKey: rangeKey,
    field: field,
    value: value
  };
};

const actionDeleteRange = (rangeKey, rangeId) => {
  return {
    type: QuestionConstants.DELETE_RANGE_ACTION_TYPE,
    rangeKey: rangeKey,
    rangeId: rangeId
  };
};

export {
  actionGetQuestions,
  actionBeginListQuestion,
  actionBeginAddQuestion,
  actionToggleQuestion,
  actionDeleteQuestion,
  actionBeginEditQuestion,
  actionChangeCategory,
  actionChangeValueQuestion,
  actionChangeAnswer,
  actionChangeFeature,
  actionGetLayoutTypes,
  actionSaveAddQuestion,
  actionSaveEditQuestion,
  actionOrderQuestion,
  actionLoadQuestion,
  actionUploadImageQuestion,
  actionMessageQuestion,
  actionMessageQuestionDetail,
  actionOrderAnswers,
  actionAddRange,
  actionChangeRangeValue,
  actionDeleteRange
};
