import BaseModel from "../../models/BaseModel";

class LayoutTypeModel extends BaseModel {
  constructor(id, name, code, description) {
    super(id, name);
    this.code = code;
    this.description = description;    
  }

  static getEmpty = () => {
    return {
      id: '',
      name: '',
      code: '',
      description: ''
    };
  };
}

export default LayoutTypeModel;