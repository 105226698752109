import React from 'react';
import { Row, Col } from 'reactstrap';
import ButtonGroup from '../buttons/ButtonGroup';
import BaseItem from '../items/BaseItem';
import { withRouter } from 'react-router-dom';
import Group from '../../images/Group.png';
import PathsApp from '../../utils/PathsApp';

class QuestionItem extends BaseItem {

  componentDidMount() {
    this.setState({
      id: this.props.item.id,
      name: this.props.item.text,
      itemIsVisible: this.props.item.isEnabled
    });
  }

  handleEdit = (event) => {
    event.preventDefault();
    this.props.handleLoad(this.props.item);
    this.props.history.push(PathsApp.QUESTIONS_EDIT_BASE + this.props.item.id);    
  }

  handleDelete = (event) => {
    event.preventDefault();
    this.props.handleDelete(this.props.item.id, true);
  };

  handleVisible = (event) => {
    event.preventDefault();
    this.props.handleVisible(this.props.item.id);
  };

  render() {
    return (
      <Row className="item-admin" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        <Col lg="1" className="item-admin__text text-center"><img alt="order" src={Group} /></Col>
        <Col lg="8" className="item-admin__text pl-0" onClick={this.handleEdit}>{this.props.item.text}</Col>
        <Col lg="3">
          <Row>
            {
              this.state.isMouseInside ? <ButtonGroup handleEdit={this.handleEdit} handleDelete={this.handleDelete} handleVisible={this.handleVisible} itemIsVisible={this.props.item.isEnabled} viewIsVisible={true} /> : null
            }
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withRouter(QuestionItem);
