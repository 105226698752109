import LayoutTypeModel from './LayoutTypeModel';
import BaseModel from '../../models/BaseModel';
import FeatureModel from '../features/FeatureModel';
import QuestionRequestModel from './QuestionRequestModel';

class QuestionModel {
  constructor(id, text, isMultipleChoice, layoutType, category, feature, helpInfo, answers, isEnabled, orderNumber, exists, originalAnwers) {
    this.id = id;
    this.text = text;
    this.isMultipleChoice = isMultipleChoice;
    this.layoutType = layoutType;
    this.category = category;
    this.feature = feature;
    this.helpInfo = helpInfo;
    this.answers = answers;
    this.originalAnwers = originalAnwers;
    this.isEnabled = isEnabled;
    this.orderNumber = orderNumber;
    this.exists = exists;
    this.ranges = [];
  }

  static getEmpty = () => {
    return {
      id: '',
      text: '',
      isMultipleChoice : false,
      layoutType: LayoutTypeModel.getEmpty(),
      category: BaseModel.getEmpty(),
      feature: FeatureModel.getEmpty(),
      helpInfo: '',
      answers: [],
      originalAnwers: [],
      isEnabled: true,
      orderNumber: 1,
      exists: true,
      ranges: []
    };
  };

  static isEmpty = (question) => {
    return question.id === '';
  };

  toRequest() {
    return new QuestionRequestModel(
      this.id,
      this.orderNumber,
      this.text,
      this.isMultipleChoice,
      this.layoutType.id,
      this.category.id,
      this.feature.id,
      this.helpInfo,
      this.isEnabled,
      this.answers.map(answer => answer.toRequest())
    )
  }
}

export default QuestionModel;
