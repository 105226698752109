import CategoryConstants from "./CategoryConstants";
import BaseModel from '../../models/BaseModel';

const initialState = {
  items: null,
  selected: {},
  message: null
};

const setCategorySaved = (items, newCategory) => {
  return items.map((item, i) => {
    return i === 0 ? { ...item, id: newCategory.id, name: newCategory.name } : item;
  });
};

const removeCategory = (items) => {
  return items.filter((item, i) => {
    return i !== 0;
  });
};

const setCategoryEdited = (items, editedCategory) => {
  return items.map((item) => {
    return item.id === editedCategory.id ? { ...item, name: editedCategory.name } : item;
  });
};

const setCategoryDeleted = (items, id) => {
  return items.filter((item) => {
    return item.id !== id;
  });
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CategoryConstants.GET_ALL_ACTION_TYPE:
      return {
        ...state,
        items: action.items
      };
    case CategoryConstants.GET_ACTION_TYPE:
      return {
        ...state,
        selected: action.selected
      };
    case CategoryConstants.BEGIN_ADD_ACTION_TYPE:
      return {
        ...state,
        items: [BaseModel.getEmpty(), ...state.items]
      };
    case CategoryConstants.SAVE_ADD_ACTION_TYPE:
      return {
        ...state,
        items: setCategorySaved(state.items, action.newCategory)
      };
    case CategoryConstants.CANCEL_ADD_ACTION_TYPE:
      return {
        ...state,
        items: removeCategory(state.items)
      };
    case CategoryConstants.SAVE_EDIT_ACTION_TYPE:
      return {
        ...state,
        items: setCategoryEdited(state.items, action.editedCategory)
      };
    case CategoryConstants.DELETE_ACTION_TYPE:
      return {
        ...state,
        items: setCategoryDeleted(state.items, action.id)
      };
    case CategoryConstants.MESSAGE_ACTION_TYPE:
      return {
        ...state,
        message: action.message
      };
    default:
      return state;
  }
};

export default CategoryReducer;