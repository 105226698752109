import React, { Component, Fragment } from 'react';
import { Container, Button, Row, Col, Progress } from 'reactstrap';
import './WizardSetup.css';
import Reset from '../../images/start_over.png';
import Info from '../../images/information.png';
import { actionOpenHelper } from '../helper/HelperActions';
import { connect } from 'react-redux';
import HelperButton from '../helper/HelperButton';
import QuestionContainer from '../question/QuestionContainer';
import { actionGetQuestion, actionUpdateOption, actionBackWizard } from './WizardActions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import WizardConstants from './WizardConstants';
import PathsApp from '../../utils/PathsApp';
import { actionOpenStartOver, actionCloseStartOver } from '../startover/StartOverActions';
import StartOverContainer from '../startover/StartOverContainer';
import { actionGetResult } from '../result/ResultAction';
import MDSpinner from "react-md-spinner";

class WizardSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: this.props.lastIndex,
      max: this.props.max,
      progress: 100 * (this.props.lastIndex + 1) / (this.props.max + 1),
      textNext: this.getNextButtonText() // TODO: Detect category id to change text accordingly
    };
  }

  componentDidMount() {
    this.props.getQuestion(this.props.lastIndex);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.questionData !== this.props.questionData) {
      this.props.getResult(this.props.questionData, this.props.type, this.props.distributorId);
    }
  }

  handleNext = (event) => {
    event.preventDefault();
    const previous = this.state.current + 1;
    if (previous <= this.state.max) {
      this.setState((state) => ({
        current: state.current + 1,
        progress: (100 * (state.current + 2) / (state.max + 1)),
        textNext: this.getNextButtonText() // TODO: Detect category id to change text accordingly
      }));
      this.props.getQuestion(previous);
      if (previous === this.state.max) {
        this.setState({ textNext: 'Result' });
      }
    } else {      
      if (this.props.type === WizardConstants.CLIENT) {
        this.props.history.push(PathsApp.WIZARD_PATH(PathsApp.WIZARD_RESULT_ROOT, this.props.match.params.category, this.props.match.params.distributor));
      }
    }
  };

  handleBack = (event) => {
    event.preventDefault();
    const previous = this.state.current - 1;
    if (previous >= 0) {
      this.setState((state) => ({
        current: state.current - 1,
        progress: (100 * (state.current) / (state.max + 1)),
        textNext: this.getNextButtonText()  // TODO: Detect category id to change text accordingly
      }));
      this.props.back(previous + 1);
      this.props.getQuestion(previous);      
      this.props.getResult(this.props.questionData, this.props.type);
    }
  };

  handleRestart = (event) => {
    event.preventDefault();
    this.props.closeStartOver();
    this.props.history.push(PathsApp.WIZARD_PATH(PathsApp.WIZARD_HOME_ROOT, this.props.match.params.category, this.props.match.params.distributor));
  };

  handleReset = (event) => {
    event.preventDefault();
    if (this.props.type === WizardConstants.CLIENT) {
      this.props.openStartOver();
    }
  };

  handleSeeResults = (event) => {
    event.preventDefault();
    if (this.props.type === WizardConstants.CLIENT) {
      this.props.history.push(PathsApp.WIZARD_PATH(PathsApp.WIZARD_RESULT_ROOT, this.props.match.params.category, this.props.match.params.distributor));
    }
    }

    getNextButtonText = () => {
        switch (this.props.match.params.category) {
            case "wireless":
                return "Continue Wizard";
            default:
                return "Next step";
        }        
    }

  render() {
    return (
      <Container className="wizard" fluid>
        <Progress className="wizard__progress" value={this.state.progress} />
        {
          this.props.showHelper ?
            <div className="wizard__help">
              <HelperButton className="wizard__help-button" image={Info} handleClick={this.props.openHelper} />
            </div>
          : null
        }        
        {this.props.question ? <QuestionContainer data={this.props.question} updateOption={this.props.updateOption} /> : null}
        <div className="wizard__navigation">
          <Row>
            <Col xs="5" lg="4" className="pr-0">
              <Button className="wizard__secondary wizard__secondary--back" color="primary" onClick={this.handleBack}><i className="fas fa-chevron-left"></i><span>Back</span></Button>
              {
                this.props.type === WizardConstants.CLIENT && this.props.showReset
                  ? <Button className="wizard__secondary wizard__secondary--reset" color="primary" onClick={this.handleReset}><img alt="reset" src={Reset} /></Button>
                  : null
              }
            </Col>
            <Col xs="4" lg="4" className="wizard__result">
              <Row className="wizard__resume">
                {
                  this.props.loadingResult
                    ? <Col className="my-auto text-center">
                        <MDSpinner singleColor="#6E2585" size={20} />
                      </Col>                    
                    : <Col className="my-auto wizard__resume--details">
                        {
                          this.props.result && this.props.result.length > 0
                            ? <Fragment>
                                <span className="wizard__resume--value">{this.props.result.length} /</span> <span className="wizard__products">{this.props.numberProducts}</span> <span className="wizard__products--devices"> devices</span>
                                {
                                  this.props.type === WizardConstants.CLIENT
                                    ? <Button className="wizard__secondary wizard__secondary--result wizard__seeresults" color="primary" onClick={this.handleSeeResults}><span className="wizard__result--desktop">See results</span><span className="wizard__result--mobile">Results</span></Button>
                                    : null
                                }
                              </Fragment>
                            : this.props.isNoResult
                                ? <span className="wizard__result--none">No results found</span>
                                : <Fragment><span className="wizard__products--number">{this.props.numberProducts}</span> <span className="wizard__products--devices"> devices</span></Fragment>
                        }
                      </Col>
                }
              </Row>
            </Col>
            <Col xs="3" lg="4" className="text-right pl-0">
              <Button className="button_main wizard__right" color="primary" onClick={this.handleNext} disabled={this.props.isDisabled}><span>{this.state.textNext}</span><i className="fas fa-chevron-right"></i></Button>             
            </Col>
          </Row>
        </div>
        <StartOverContainer onProceed={this.handleRestart} />
      </Container>      
    );
  }
}

WizardSetup.propTypes = {
  type: PropTypes.string,
  max: PropTypes.number,
  numberProducts: PropTypes.number
};

const stepIsDisabled = (resultData, questionData, currentQuestion) => {
  let isDisabled = true;
  if (currentQuestion) {    
    if (resultData && resultData.length > 0) {
      isDisabled = false;
    } else {
      const indexCurrent = questionData.findIndex(q => q.id === currentQuestion.id);
      if (indexCurrent === questionData.length - 1) {
        isDisabled = currentQuestion.options.filter(o => o.checked).length > 0;
      } else {
        if (currentQuestion.options.filter(o => o.checked).length === 0) {
          isDisabled = false;
        } else {
          const nextQuestions = questionData.filter((q, index) => index > indexCurrent);
          isDisabled = !nextQuestions.filter(q => q.options.filter(o => o.checked).length > 0).length > 0;
        }
      }
    }
  }
  return isDisabled;
};

const isNoResultMessage = (questionsData) => {
  let showNoResult = false;
  if (questionsData) {
    showNoResult = questionsData.filter(q => q.options.filter(o => o.checked).length > 0).length > 0;
  }
  return showNoResult;
};

const isVisibleHelper = (question) => {
  let show = false;
  if (question && question.helpInfo.length > 0) {
    show = true;
  }
  return show;
};

const isVisibleReset = (questionsData) => {
  let isVisible = false;
  if (questionsData) {
    isVisible = questionsData.filter(q => q.options.filter(o => o.checked).length > 0).length > 0;
  }
  return isVisible;
}

const mapStateToProps = state => {
  return {
    question: state.wizard.currentQuestion,
    isDisabled: stepIsDisabled(state.result.data, state.wizard.data, state.wizard.currentQuestion),
    showHelper: isVisibleHelper(state.wizard.currentQuestion),
    questionData: state.wizard.data,
    result: state.result.data,
    lastIndex: state.wizard.lastIndex,
    showReset: isVisibleReset(state.wizard.data),
    loadingResult: state.result.loading,
    isNoResult: isNoResultMessage(state.wizard.data),
    distributorId: state.distributorClient.selected.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openHelper: () => dispatch(actionOpenHelper()),
    openStartOver: () => dispatch(actionOpenStartOver()),
    closeStartOver: () => dispatch(actionCloseStartOver()),
    getQuestion: (index) => dispatch(actionGetQuestion(index)),
    updateOption: (optionId, isChecked) => dispatch(actionUpdateOption(optionId, isChecked)),
    getResult: (questions, typeSetup, distributorId) => dispatch(actionGetResult(questions, typeSetup, distributorId)),
    back: (indexBack) => dispatch(actionBackWizard(indexBack))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WizardSetup));
