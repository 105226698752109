import React from 'react';
import { Row, Col } from 'reactstrap';
import ButtonGroup from '../buttons/ButtonGroup';
import QuestionAnswerBase from './QuestionAnswerBase';
import UploadImageContainer from '../uploadImage/UploadImageContainer';
import Group from '../../images/Group.png';

class QuestionAnswerIconItem extends QuestionAnswerBase {
  render() {
    return (
      <Row className="answer-item">
        <Col lg="11" className="item-admin answer-item__name">
          <Row>
            <Col lg="1" className="item-admin__text text-center"><img alt="order" src={Group} /></Col>
            <Col lg="8" className="item-admin__text pl-0">{this.props.item.option.name}</Col>
            <Col lg="3" className="pr-0">
              <ButtonGroup handleEdit={null} handleDelete={null} handleSave={null} handleCancel={null} handleVisible={this.handleVisible} itemIsVisible={this.props.item.isEnabled} viewIsVisible={true} />
            </Col>
          </Row>
        </Col>
        <Col lg="1" className="item-admin">
          {
            this.props.item.isEnabled ?
              <UploadImageContainer keyImage={this.props.item.option.id} wrapperClass="answer-item__upload" imageClass="answer-item__image-result" imageUrl={this.props.item.imageUrl} itemId={this.props.item.option.id} handleFile={this.props.handleFile} showClose={false} />
              : <Row><Col className="answer-item__disabled"><i className="fas fa-images"></i></Col></Row>
          }          
        </Col>
      </Row>      
    );
  }
}

export default QuestionAnswerIconItem;
