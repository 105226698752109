import BaseModel from "../../models/BaseModel";

class FeatureModel extends BaseModel {
  constructor(id, name, description, categoryId) {
    super(id, name);
    this.description = description;
    this.categoryId = categoryId;
  }
}

export default FeatureModel;
