import React, { Component } from 'react';
import Modal from 'react-modal';
import { Row, Col, CustomInput, ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux';
import { actionCloseProductOptions, actionUpdateProductFeatureOption, actionCancelProductFeatureOption } from './ProductAction';
import ButtonModal from '../buttons/ButtonModal';

Modal.setAppElement('#root');

class ProductOptions extends Component {

  handleChangeOption = (event) => {
    this.props.updateOption(this.props.optionsStore.find(o => o.id === event.target.id));
  };

  handleCancel = () => {    
    this.props.closeOptions();
    this.props.cancelOptions();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onRequestClose={this.handleCancel}
        contentLabel="Product Options"
        className="product-modal">
        <div className="product-modal__header">
          <h4>{this.props.featureName}</h4>
        </div>
        <div className="product-modal__options">
          <ListGroup flush>
            {
              this.props.options ?
                this.props.options.map(option => <ListGroupItem key={option.id} className="border-0"><CustomInput type="checkbox" onChange={this.handleChangeOption} checked={option.checked} id={option.id} label={option.name} /></ListGroupItem>)
                : null
            }
          </ListGroup>
        </div>
        <div className="product-modal__footer">
          <Row className="ml-0 mr-0">
            <Col lg="4" className="product-modal__resume"></Col>
            <Col lg="8" className="product-modal__buttons">
              <ButtonModal confirmText="Yes, proceed" cancelText="Cancel" confirm={this.props.closeOptions} cancel={this.handleCancel} />
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

const mapOptions = (options, optionsSelected) => {
  if (options) {    
    return options.map(o => {
      const optionSelected = optionsSelected.find(os => os.optionId === o.id);
      if (optionSelected) {
        return { id: o.id, name: o.name, checked: true };
      } else {
        return { id: o.id, name: o.name, checked: false };
      }
    });
  } else {
    return [];
  }
};

const getFeatureName = (features, featureSelected) => {
  let featureName = '';
  if (features) {
    const feature = features.find(pf => pf.id === featureSelected);
    if (feature) {
      featureName = feature.name;
    }
  }
  return featureName;
};

const mapStateToProps = state => {
  return {
    modalIsOpen: state.product.modalIsOpen,
    optionsStore: state.option.items,
    featureName: getFeatureName(state.feature.items, state.product.featureSelected),
    options: mapOptions(state.option.items, state.product.selected.productFeatures)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeOptions: () => dispatch(actionCloseProductOptions()),
    updateOption: (option) => dispatch(actionUpdateProductFeatureOption(option)),
    cancelOptions: () => dispatch(actionCancelProductFeatureOption())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductOptions);
