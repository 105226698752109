import React from 'react';
import { Row, Col } from 'reactstrap';
import ProductFeatureItem from './ProductFeatureItem';
import PropTypes from 'prop-types';
import ProductOptions from './ProductOptions';

const ProductFeatures = (props) => {
  let featureItems = null;
  if (props.items) {
    featureItems = props.items.map(item => <ProductFeatureItem key={item.featureCode} item={item} />);
  }

  return (
    <React.Fragment>
      <Row className = "list-admin" >
        <Col className="pl-0 pr-0">
          {featureItems}
        </Col>
      </Row>
      <ProductOptions />
    </React.Fragment>
  );
};

ProductFeatures.propTypes = {
  items: PropTypes.array
};

export default ProductFeatures;
