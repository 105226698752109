import DistributorConstants from "./DistributorConstants";
import DistributorRequestModel from "./DistributorRequestModel";
import ProductDistributorRequestModel from "./ProductDistributorRequestModel";
import BaseModel from "../../models/BaseModel";

const initialState = {
  items: null,
  messageList: null,
  search: '',
  totalItems: 0,
  totalPages: 0,
  messageListAction: null,
  messageAction: null,
  modalSelectIsOpen: false,
  modalSelectedIsOpen: false,
  selected: DistributorRequestModel.getEmpty(),
  productsTemp: [],
  productSelected: ProductDistributorRequestModel.getEmpty(),
  selectedAll: false
};

const setDistributorToggle = (items, id) => {
  return items.map((item) => {
    return item.id === id ? { ...item, isEnabled: !item.isEnabled } : item;
  });
};

const setDistributorDeleted = (items, id) => {
  return items.filter((item) => {
    return item.id !== id;
  });
};

const updateProductsDistributor = (productsDistributor, product) => {
  if (productsDistributor.find(p => p.productId === product.id)) {
    productsDistributor = productsDistributor.filter(p => p.productId !== product.id);
  } else {
    productsDistributor.push(new ProductDistributorRequestModel(product.id, product.id, product.name, '', product.url, true, new BaseModel(product.category.id, product.category.name), product.sku));
  }
  return productsDistributor;
};

const updateProductSelected = (productsDistributor, productSelected) => {
  productsDistributor = productsDistributor.map(pd => {
    if (pd.productId === productSelected.productId) {
      pd = productSelected;
      pd.isError = false;
    }
    return pd;
  });
  return productsDistributor;
};

const removeProductDistributor = (productsDistributor, productId) => {
  productsDistributor = productsDistributor.map(pd => {
    if (pd.productId === productId) {
      pd.isEnabled = false;
      pd.isError = false;
      pd.url = '';
    }
    return pd;
  });
  return productsDistributor;
};

const fixProductDomain = (productDomain) => {
  if (productDomain.slice(-1) === '/') {
    return fixProductDomain(productDomain.substring(0, productDomain.length - 1));
  } else {
    return productDomain;
  }
};

const replaceUrl = (productDomain, url) => {
  if (url && url.length > 0) {
    try {
      const productUrl = new URL(url);
      return url.replace(productUrl.origin, productDomain);
    } catch (error) {
      return url;
    }
  } else {
    return productDomain;
  }
};

const confirmProductsDistributor = (productsDistributor, productsTemp, urlProductDomain, isRegion) => {
  let productDomain = '';
  if (isRegion && urlProductDomain && urlProductDomain.length > 0) {
    productDomain = fixProductDomain(urlProductDomain);
  }
  if (productsDistributor.length === productsTemp.length && productsDistributor.every(pd => productsTemp.find(pt => pt.productId === pd.productId && pt.isEnabled === pd.isEnabled))) {
    if (isRegion && productDomain && productDomain.length > 0) {
      return productsDistributor.map(pd => {
        pd.url = replaceUrl(productDomain, pd.url);
        return pd;
      });
    } else {
      return productsDistributor;
    }
  } else {
    let intersection = productsDistributor.filter(pd => productsTemp.find(pt => pt.productId === pd.productId) ? true : false);
    if (isRegion && productDomain && productDomain.length > 0) {
      intersection = intersection.map(pd => {
        if (!pd.isEnabled) {
          pd.url = replaceUrl(productDomain, productsTemp.find(pt => pt.productId === pd.productId).url);
        } else {
          pd.url = replaceUrl(productDomain, pd.url);
        }
        pd.isEnabled = true;
        return pd;
      });
    } else {
      intersection = intersection.map(pd => {
        if (!pd.isEnabled) {
          pd.url = productsTemp.find(pt => pt.productId === pd.productId).url;
        }
        pd.isEnabled = true;
        return pd;
      });
    }
    if (intersection) {
      let originalMinusNews = productsDistributor.filter(pd => productsTemp.find(pt => pt.productId === pd.productId) ? false : true);
      originalMinusNews = originalMinusNews.map(pd => {
        pd.isEnabled = false;
        return pd;
      });
      originalMinusNews = originalMinusNews.concat(intersection);
      let newsMinusOriginal = productsTemp.filter(pt => productsDistributor.find(pd => pd.productId === pt.productId) ? false : true);
      if (isRegion && productDomain && productDomain.length > 0) {
        newsMinusOriginal = newsMinusOriginal.map(pd => {
          pd.isEnabled = true;
          pd.url = replaceUrl(productDomain, pd.url);
          return pd;
        });
      } else {
        newsMinusOriginal = newsMinusOriginal.map(pd => {
          pd.isEnabled = true;
          return pd;
        });
      }
      return originalMinusNews.concat(newsMinusOriginal);
    } else {
      return productsTemp;
    }
  }
};

const setAllProducts = (products) => {
  return products.map(product => {
    return new ProductDistributorRequestModel(product.id, product.id, product.name, '', product.url, true, new BaseModel(product.category.id, product.category.name), product.sku);
  });
};

const isSelected = (products, productsTemp) => {
  return products.every(p => productsTemp.find(pt => pt.productId === p.id));
};

const setInvalids = (productsDistributor, invalids) => {
  return productsDistributor.map(pd => {
    const productInvalid = invalids.find(invalid => invalid.id === pd.id);
    if (productInvalid) {
      pd.isError = true;
    }
    return pd;
  });
};

const resetInvalids = (productsDistributor) => {
  return productsDistributor.map(pd => {
    pd.isError = false;
    return pd;
  });
};

const DistributorAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case DistributorConstants.GET_ALL_ACTION_TYPE:
      return {
        ...state,
        items: action.items,
        totalItems: action.totalItems,
        totalPages: action.totalPages,
        selected: DistributorRequestModel.getEmpty(),
      };
    case DistributorConstants.TOGGLE_ACTION_TYPE:
      return {
        ...state,
        items: setDistributorToggle(state.items, action.id),
        selected: { ...state.selected, isEnabled: !state.selected.isEnabled }
      };
    case DistributorConstants.DELETE_ACTION_TYPE:
      return {
        ...state,
        items: setDistributorDeleted(state.items, action.id),
        selected: !action.isFromList ? { ...state.selected, exists: false } : { ...state.selected }
      };
    case DistributorConstants.MESSAGE_LIST_ACTION_TYPE:
      return {
        ...state,
        messageList: action.message
      };
    case DistributorConstants.OPEN_SELECT_ACTION_TYPE:
      return {
        ...state,
        modalSelectIsOpen: true,
        productsTemp: state.selected.productsDistributor.filter(pd => pd.isEnabled),
        selectedAll: action.products ? action.products.every(p => state.selected.productsDistributor.filter(pd => pd.isEnabled).find(pf => pf.productId === p.id)) : false
      };
    case DistributorConstants.CLOSE_SELECT_ACTION_TYPE:
      return {
        ...state,
        modalSelectIsOpen: false,
        productsTemp: [],
        selectedAll: false
      };
    case DistributorConstants.OPEN_SELECTED_ACTION_TYPE:
      return {
        ...state,
        modalSelectedIsOpen: true,
        productSelected: action.product
      };
    case DistributorConstants.CLOSE_SELECTED_ACTION_TYPE:
      return {
        ...state,
        modalSelectedIsOpen: false,
        productSelected: ProductDistributorRequestModel.getEmpty()
      };
    case DistributorConstants.BEGIN_ADD_ACTION_TYPE:
      return {
        ...state,
        selected: DistributorRequestModel.getEmpty()
      };
    case DistributorConstants.CHANGE_VALUE_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, [action.propertie]: action.value }
      };
    case DistributorConstants.UPDATE_PRODUCTS_ACTION_TYPE:
      const newProductsTemp = updateProductsDistributor(state.productsTemp, action.product);
      return {
        ...state,
        productsTemp: newProductsTemp,
        selectedAll: isSelected(action.products, newProductsTemp)
      };
    case DistributorConstants.CONFIRM_PRODUCTS_ACTION_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          productsDistributor: confirmProductsDistributor(state.selected.productsDistributor, state.productsTemp, state.selected.productDomain, state.selected.isRegion)
        }
      };
    case DistributorConstants.CHANGE_VALUE_PRODUCT_ACTION_TYPE:
      return {
        ...state,
        productSelected: { ...state.productSelected, [action.propertie]: action.value }
      };
    case DistributorConstants.UPDATE_PRODUCT_SELECTED_ACTION_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          productsDistributor: updateProductSelected(state.selected.productsDistributor, state.productSelected)
        }
      };
    case DistributorConstants.REMOVE_PRODUCT_ACTION_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          productsDistributor: removeProductDistributor(state.selected.productsDistributor, action.productId)
        }
      };
    case DistributorConstants.SELECT_ALL_PRODUCTS_ACTION_TYPE:
      return {
        ...state,
        selectedAll: action.flag,
        productsTemp: action.flag ? setAllProducts(action.products) : []
      };
    case DistributorConstants.CHANGE_VALUE_SELECT_ALL_ACTION_TYPE:
      return {
        ...state,
        selectedAll: action.flag
      };
    case DistributorConstants.MESSAGE_ACTION_ACTION_TYPE:
      return {
        ...state,
        messageAction: action.messageAction
      };
    case DistributorConstants.MESSAGE_LIST_ACTION_ACTION_TYPE:
      return {
        ...state,
        messageListAction: action.messageListAction
      };
    case DistributorConstants.SAVE_ADD_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, exists: false }
      };
    case DistributorConstants.GET_ACTION_TYPE:
      return {
        ...state,
        selected: action.distributor
      };
    case DistributorConstants.SAVE_EDIT_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, exists: false }
      };
    case DistributorConstants.SET_INVALIDS_PRODUCTS:
      return {
        ...state,
        selected: {
          ...state.selected,
          productsDistributor: setInvalids(state.selected.productsDistributor, action.invalids)
        }
      };
    case DistributorConstants.RESET_INVALIDS_PRODUCTS:
      return {
        ...state,
        selected: {
          ...state.selected,
          productsDistributor: resetInvalids(state.selected.productsDistributor)
        }
      };
    default:
      return state;
  }
};

export default DistributorAdminReducer;
