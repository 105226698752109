import PreviewApi from "./PreviewApi";
import PreviewConstants from "./PreviewConstants";
import axios from 'axios';
import { actionErrorAuth } from "../login/LoginAction";

const deployPreview = async (dispatch, categoryId) => {
  try {
    const response = await axios.put(PreviewApi.Deploy(categoryId));
    dispatch(actionMessagePreview(response.data ? PreviewConstants.MESSAGE_DEPLOY_OK : PreviewConstants.MESSAGE_DEPLOY_ERROR));
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch(actionMessagePreview(PreviewConstants.MESSAGE_DEPLOY_ERROR));
  }
  dispatch({
    type: PreviewConstants.DEPLOY_ACTION_TYPE
  });
};

const actionDeployPreview = (categoryId) => {
  return dispatch => {
    deployPreview(dispatch, categoryId)
  };
};

const actionMessagePreview = (message) => {
  return {
    type: PreviewConstants.MESSAGE_ACTION_TYPE,
    message: message
  };
};

const actionOpenPreviewSelect = () => {
  return {
    type: PreviewConstants.SELECT_OPEN_ACTION_TYPE
  };
};

const actionClosePreviewSelect = () => {
  return {
    type: PreviewConstants.SELECT_CLOSE_ACTION_TYPE
  };
};

const actionSetCategoryPreview = (categoryId) => {
  return {
    type: PreviewConstants.SET_CATEGORY_ACTION_TYPE,
    categoryId: categoryId
  };
};

export { actionDeployPreview, actionMessagePreview, actionOpenPreviewSelect, actionClosePreviewSelect, actionSetCategoryPreview };