import React from 'react';
import { Container } from 'reactstrap';
import Check from '../../images/check_rounded.png';
import classnames from 'classnames';
import OptionBase from './OptionBase';
import PropTypes from 'prop-types';

class OptionGrid extends OptionBase {
  render() {
    return (
      <Container className={classnames({ 'wizard__option': true, 'wizard__option--grid': true, 'wizard__option--checked': this.props.checked })} onClick={this.handleCheck} fluid >
        <div className={classnames({ 'wizard__check': true, 'wizard__check--grid': true, 'wizard__check--active': this.props.checked })}>
          <img alt="active" src={Check} />
        </div>
        <div className="wizard__option--grid-text">
          {this.props.text}
        </div>
      </Container>
    );
  }
}

OptionGrid.propTypes = {
  text: PropTypes.string
};

export default OptionGrid;
