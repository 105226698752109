import StartOverConstants from "./StartOverConstants";

const actionOpenStartOver = () => {
  return {
    type: StartOverConstants.OPEN_ACTION_TYPE
  };
};

const actionCloseStartOver = () => {
  return {
    type: StartOverConstants.CLOSE_ACTION_TYPE
  };
};

export { actionOpenStartOver, actionCloseStartOver };
