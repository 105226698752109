import React from 'react';
import ItemParent from '../items/ItemParent';
import PropTypes from 'prop-types';

const OptionItem = (props) => {
  return (
    <ItemParent
      placeholderText={"Add option name"}
      item={props.item}
      path={null}
      saveAdd={props.saveAdd}
      cancelAdd={props.cancelAdd}
      saveEdit={props.saveEdit}
      parentId={props.parentId}
      delete={props.delete} />
  );
};

OptionItem.propTypes = {
  item: PropTypes.object,
  saveAdd: PropTypes.func,
  cancelAdd: PropTypes.func,
  parentId: PropTypes.string,
  saveEdit: PropTypes.func,
  delete: PropTypes.func
};

export default OptionItem;
