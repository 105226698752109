import QuestionConstants from "./QuestionConstants";
import QuestionModel from "./QuestionModel";
import UuidUtil from "../../utils/UuidUtil";

const initialState = {
  items: null,
  category: null,
  selected: QuestionModel.getEmpty(),
  layoutTypes: [],
  messageList: null,
  messageOptions: null,
  tempAnswers: [],
  messageListAction: null,
  messageAction: null
};

const setQuestionDeleted = (items, id) => {
  return items.filter((item) => {
    return item.id !== id;
  });
};

const setQuestionToggle = (items, id) => {
  return items.map((item) => {
    return item.id === id ? { ...item, isEnabled: !item.isEnabled } : item;
  });
};

const updateAnswers = (answers, answerUpdated, isEnabled) => {
  if (answers.length === 0) {
    answers.push({ ...answerUpdated, isEnabled: true });
  } else {
    if (isEnabled) {
      const found = answers.find(answer => answer.option.id === answerUpdated.option.id);
      if (!found) {
        answers.push({ ...answerUpdated, isEnabled: true });
      }
    } else {
      answers = answers.filter(answer => answer.option.id !== answerUpdated.option.id);
    }
  }
  return answers;
};

const updateImage = (answers, optionId, imageUrl) => {
  return answers.map(answer => {
    return answer.option.id === optionId ? { ...answer, imageUrl: imageUrl } : answer
  });
};

const updateTempAnswers = (tempAnswers, optionId, imageUrl) => {
  if (tempAnswers.length === 0) {
    tempAnswers.push({ optionId: optionId, imageUrl: imageUrl });
  } else {
    const found = tempAnswers.find(answer => answer.optionId === optionId);
    if (found) {
      tempAnswers = tempAnswers.map(answer => {
        return answer.optionId === optionId ? { ...answer, imageUrl: imageUrl } : answer
      });
    } else {
      tempAnswers.push({ optionId: optionId, imageUrl: imageUrl });
    }
  }
  return tempAnswers;
};

const updateOrderAnswers = (answers, newOptions) => {
  if (answers && answers.length > 0) {
    answers = answers.map(answer => {
      const orderAnswer = newOptions.findIndex(option => {
        return option.id === answer.option.id;
      });
      if (orderAnswer > -1) {
        answer.orderNumber = orderAnswer;
      }
      return answer;
    });
  }
  return answers;
};

const addRange = (ranges, range) => {
  ranges.push(range);
  return ranges;
};

const updateRanges = (ranges, rangeKey, field, value) => {
  return ranges.map(range => {
    if (range.key === rangeKey) {
      range[field] = value;
    }
    return range;
  });
};

const deleteRange = (ranges, rangeKey, rangeId) => {
  if (rangeId === UuidUtil.EMPTY) {    
    return ranges.filter(range => range.key !== rangeKey);
  } else {
    return ranges.map(range => {
      if (range.id === rangeId) {
        range.isEnabled = false;
      }      
      return range;
    });
  }
};

const QuestionAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case QuestionConstants.GET_ALL_BY_CATEGORY_ACTION_TYPE:
      return {
        ...state,
        items: action.items,
        category: action.category,
        selected: QuestionModel.getEmpty(),
        tempAnswers: []
      };
    case QuestionConstants.BEGIN_ADD_ACTION_TYPE:
      return {
        ...state,
        selected: QuestionModel.getEmpty(),
        tempAnswers: []
      };
    case QuestionConstants.TOGGLE_ACTION_TYPE:
      return {
        ...state,
        items: setQuestionToggle(state.items, action.id),
        selected: { ...state.selected, isEnabled: !state.selected.isEnabled }
      };
    case QuestionConstants.DELETE_ACTION_TYPE:
      return {
        ...state,
        items: setQuestionDeleted(state.items, action.id),
        selected: !action.isFromList ? { ...state.selected, exists: false } : { ...state.selected }
      };
    case QuestionConstants.GET_ACTION_TYPE:
      return {
        ...state,
        tempAnswers: action.tempAnswers,
        selected: action.question
      };
    case QuestionConstants.CHANGE_VALUE_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, [action.propertie]: action.value }
      };
    case QuestionConstants.CHANGE_ANSWER_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, answers: updateAnswers(state.selected.answers, action.answer, action.isEnabled) }
      };
    case QuestionConstants.RESET_ANSWERS_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, answers: [] }
      };
    case QuestionConstants.GET_LAYOUT_TYPES_ACTION_TYPE:
      return {
        ...state,
        layoutTypes: action.layoutTypes
      };
    case QuestionConstants.SAVE_ADD_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, exists: false }
      };
    case QuestionConstants.SAVE_EDIT_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, exists: false }
      };
    case QuestionConstants.MESSAGE_LIST_ACTION_TYPE:
      return {
        ...state,
        messageList: action.message
      };
    case QuestionConstants.MESSAGE_OPTIONS_ACTION_TYPE:
      return {
        ...state,
        messageOptions: action.message
      };
    case QuestionConstants.ORDER_ACTION_TYPE:
      return {
        ...state,
        items: action.questions
      };
    case QuestionConstants.UPLOAD_IMAGE_ACTION_TYPE:
      return {
        ...state,
        selected: { ...state.selected, answers: updateImage(state.selected.answers, action.optionId, action.imageUrl) },
        tempAnswers: updateTempAnswers(state.tempAnswers, action.optionId, action.imageUrl)
      };
    case QuestionConstants.MESSAGE_LIST_ACTION_ACTION_TYPE:
      return {
        ...state,
        messageListAction: action.messageListAction
      };
    case QuestionConstants.MESSAGE_ACTION_ACTION_TYPE:
      return {
        ...state,
        messageAction: action.messageAction
      };    
    case QuestionConstants.ORDER_ANSWERS_ACTION_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          answers: updateOrderAnswers(state.selected.answers, action.newOptions)
        }
      };
    case QuestionConstants.ADD_RANGE_ACTION_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          ranges: addRange(state.selected.ranges, action.range)
        }
      };
    case QuestionConstants.CHANGE_VALUE_RANGE_ACTION_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          ranges: updateRanges(state.selected.ranges, action.rangeKey, action.field, action.value)
        }
      };
    case QuestionConstants.DELETE_RANGE_ACTION_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          ranges: deleteRange(state.selected.ranges, action.rangeKey, action.rangeId)
        }
      };
    default:
      return state;
  }
};

export default QuestionAdminReducer;
