import React, { Component, Fragment } from 'react';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Label, Input } from 'reactstrap';
import '../Admin.css';
import classnames from 'classnames';
import HeaderSaveSimple from '../header/HeaderSaveSimple';
import './LayoutContainer.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionGetLayout, actionGetLayoutByCategory, actionChangeValueLayout, actionSaveAddLayout, actionSaveEditLayout, actionUploadLayout, actionMessageLayout } from './LayoutActions';
import UuidUtil from '../../utils/UuidUtil';
import UploadImageContainer from '../uploadImage/UploadImageContainer';
import AlertContainer from '../../alerts/AlertContainer';
import LayoutConstants from './LayoutConstants';


class LayoutContainer extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1'
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount() {
        this.props.getLayout();
    }

    handleChange = (event) => {
        this.props.changeInputValue(event.target.id, event.target.value);
    };

    handleSave = (event) => {
        event.preventDefault();
        if (this.props.layout === "" || this.props.layout.id === UuidUtil.EMPTY || this.props.layout.id === '') {
            this.props.save(this.props.layout);
        } else {
            this.props.edit(this.props.layout);
        }
    };

    changeCategory = (event) => {
        this.props.selectCategory(event.target.value);
    };

    componentWillUnmount() {
        this.props.resetMessage();
    }

    render() {
        return (
            <Fragment>
                <AlertContainer alert={this.props.message} />
                <Container className="page-admin page-admin--feature" fluid>
                    <HeaderSaveSimple title={"Layout"} handleSave={this.handleSave} />
                    <Container className="page-admin__list-container admin-tabs" fluid>
                        <div>
                            <Row className="pb-2">
                                <Col lg="3">
                                    {
                                        this.props.categories && this.props.categorySelected ?
                                            <div className="select-admin__wrapper fa">
                                                <select className="select-admin__control" onChange={this.changeCategory} value={this.props.categorySelected}>
                                                    {
                                                        this.props.categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)
                                                    }
                                                </select>
                                            </div>
                                            : null
                                    }
                                </Col>
                            </Row>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ 'layout-tabs__item': true, 'admin-tabs__item': true, active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }} >Main Page</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ 'layout-tabs__item': true, 'admin-tabs__item': true, active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }} >Result Page</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane className="pt-4" tabId="1">
                                    <Row>
                                        <Col className="mb-1" lg="4">
                                            <FormGroup>
                                                <Label for="layoutThumbnail">Image</Label>
                                                <UploadImageContainer keyImage={LayoutConstants.IMAGE} mainClass="admin-tabs__image-wrapper" wrapperClass="admin-tabs__upload-wrapper" imageClass="admin-tabs__image-result" imageUrl={this.props.layout.imageUrl} handleFile={this.props.uploadFile} showClose={false} />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="8">
                                            <Row>
                                                <Col className="mb-1">
                                                    <FormGroup>
                                                        <Label for="title">Title</Label>
                                                        <Input type="text" className="input-admin" name="title" onChange={this.handleChange} id="title" value={this.props.layout.title} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-1">
                                                    <FormGroup>
                                                        <Label for="description">Description</Label>
                                                        <Input type="textarea" className="input-admin" name="description" onChange={this.handleChange} id="description" value={this.props.layout.description} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2" className="pt-4">
                                    <Row>
                                        <Col lg="8" className="mb-1">
                                            <FormGroup>
                                                <Label for="title">Title</Label>
                                                <Input type="text" className="input-admin" name="titleResult" onChange={this.handleChange} id="titleResult" value={this.props.layout.titleResult} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="8" className="mb-1">
                                            <FormGroup>
                                                <Label for="description">Description</Label>
                                                <Input type="textarea" className="input-admin" name="descriptionResult" onChange={this.handleChange} id="descriptionResult" value={this.props.layout.descriptionResult} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="8" className="mb-1">
                                            <FormGroup>
                                                <Label for="discovermore-url">Discover More Url</Label>
                                                <Input type="text" className="input-admin" name="discoverMoreResult" onChange={this.handleChange} id="discoverMoreResult" value={this.props.layout.discoverMoreResult} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        categories: state.category.items,
        categorySelected: state.layout.category,
        layout: state.layout.layout,
        message: state.layout.message
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getLayout: () => dispatch(actionGetLayout()),
        selectCategory: (category) => dispatch(actionGetLayoutByCategory(category)),
        changeInputValue: (property, value) => dispatch(actionChangeValueLayout(property, value)),
        save: (layout) => dispatch(actionSaveAddLayout(layout)),
        edit: (layout) => dispatch(actionSaveEditLayout(layout)),
        uploadFile: (file) => dispatch(actionUploadLayout(file)),
        resetMessage: () => dispatch(actionMessageLayout(null))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutContainer));
