import React from 'react';
import { Col, Button } from 'reactstrap';
import Trash from '../../images/twotone-delete-24px.png';
import View from '../../images/View.png';
import ViewDisabled from '../../images/View_disabled.png';
import './ButtonGroup.css';
import './ButtonGroupDetail.css';
import PropTypes from 'prop-types';

const ButtonGroupDetail = (props) => {
  return (
    <Col className="text-right">
      <Button className="button-group" color="primary" onClick={props.handleDelete}><img alt="delete" src={Trash} /></Button>
      <Button className="button-group mr-3" color="primary" onClick={props.handleVisible}><img alt="view" src={props.isVisible ? View : ViewDisabled} /></Button>
      <Button className="button-save text-center" onClick={props.handleSave} color="primary">Save</Button>
    </Col>
  );
};

ButtonGroupDetail.propTypes = {
  handleVisible: PropTypes.func,
  isVisible: PropTypes.bool
};

export default ButtonGroupDetail;