import LayoutModel from "../../admin/layout/LayoutModel";
import HomeConstants from "./HomeConstants";

const initialState = {
  layout: LayoutModel.getEmpty()
};

const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case HomeConstants.GET_ACTION_TYPE:
      return {
        ...state,
        layout: action.layout
      };
    default:
      return state;
  }
};

export default HomeReducer;
