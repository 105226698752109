import StartOverConstants from "./StartOverConstants";

const initialState = {
  modalIsOpen: false
};

const StartOverReducer = (state = initialState, action) => {
  switch (action.type) {
    case StartOverConstants.OPEN_ACTION_TYPE:
      return {
        ...state,
        modalIsOpen: true
      };
    case StartOverConstants.CLOSE_ACTION_TYPE:
      return {
        ...state,
        modalIsOpen: false
      };
    default:
      return state;
  }
};

export default StartOverReducer;
