import ProductConstants from "./ProductConstants";
import ProductApi from "./ProductApi";
import axios from 'axios';
import CategoryApi from "../categories/CategoryApi";
import CategoryConstants from "../categories/CategoryConstants";
import FeatureApi from "../features/FeatureApi";
import FeatureConstants from "../features/FeatureConstants";
import { actionGetCategories } from "../categories/CategoryActions";
import { actionGetFeatures } from "../features/FeatureActions";
import UploadFile from "../../utils/UploadFile";
import { actionErrorAuth } from "../login/LoginAction";
import UuidUtil from "../../utils/UuidUtil";
import { actionResetOptions } from "../options/OptionActions";
import { actionSetErrorUploadImage } from "../uploadImage/UploadImageActions";
import ProductSortEnum from "./ProductSortEnum";

const getProductsResume = async (dispatch, name, pageSize, sortBy) => {
  try {
    const response = await axios.get(ProductApi.GetProductsResume(name, sortBy));
    processGetResponseResume(dispatch, response, pageSize);
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: ProductConstants.GET_ALL_RESUME_ACTION_TYPE,
      items: null,
      totalItems: 0,
      totalPages: 0
    });
  }
};

const processGetResponseResume = (dispatch, response, pageSize) => {
  if (response.data) {
    dispatch({
      type: ProductConstants.GET_ALL_RESUME_ACTION_TYPE,
      items: response.data.length > 0 ? response.data : null,
      totalItems: response.data.length,
      totalPages: Math.ceil(response.data.length / pageSize)
    });
    if (response.data.length > 0) {
      dispatch({
        type: ProductConstants.MESSAGE_LIST_ACTION_TYPE,
        message: null
      });
    } else {
      dispatch({
        type: ProductConstants.MESSAGE_LIST_ACTION_TYPE,
        message: ProductConstants.MESSAGE_LIST_NOT_FOUND
      });
    }
  } else {
    dispatch({
      type: ProductConstants.MESSAGE_LIST_ACTION_TYPE,
      message: ProductConstants.MESSAGE_LIST_NOT_FOUND
    });
  }
};

const getProducts = async (dispatch, sortBy, page, pageSize) => {
  try {
    const response = await axios.get(ProductApi.GetProducts(sortBy, page, pageSize));
    processGetResponse(dispatch, response);
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: ProductConstants.GET_ALL_ACTION_TYPE,
      items: null,
      totalItems: 0,
      totalPages: 0
    });
  }
};

const processGetResponse = (dispatch, response) => {
  if (response.data && response.data.list) {
    dispatch({
      type: ProductConstants.GET_ALL_ACTION_TYPE,
      items: response.data.list.length > 0 ? response.data.list : null,
      totalItems: response.data.totalItems,
      totalPages: response.data.totalPages
    });
    if (response.data.list.length > 0) {
      dispatch({
        type: ProductConstants.MESSAGE_LIST_ACTION_TYPE,
        message: null
      });
    } else {
      dispatch({
        type: ProductConstants.MESSAGE_LIST_ACTION_TYPE,
        message: ProductConstants.MESSAGE_LIST_NOT_FOUND
      });
    }
  } else {
    dispatch({
      type: ProductConstants.MESSAGE_LIST_ACTION_TYPE,
      message: ProductConstants.MESSAGE_LIST_NOT_FOUND
    });
  }
};

const searchProducts = async (dispatch, search, sortBy, page, pageSize) => {
  try {
    const response = await axios.get(ProductApi.SearchProducts(search, sortBy, page, pageSize));
    processGetResponse(dispatch, response);
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: ProductConstants.GET_ALL_ACTION_TYPE,
      items: null,
      totalItems: 0,
      totalPages: 0
    });
  }
};

const toggleProduct = async (dispatch, productId) => {
  try {
    const response = await axios.delete(ProductApi.ToggleProduct(productId));
    if (response.data) {
      dispatch({
        type: ProductConstants.TOGGLE_ACTION_TYPE,
        id: productId
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const deleteProduct = async (dispatch, productId, isFromList) => {
  try {
    const response = await axios.delete(ProductApi.DeleteProduct(productId));
    if (response.data) {
      dispatch({
        type: ProductConstants.DELETE_ACTION_TYPE,
        id: productId,
        isFromList: isFromList
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const changeCategory = async (dispatch, categoryId) => {
  try {
    const response = await axios.get(FeatureApi.GetFeatures(categoryId));
    if (response.data) {
      dispatch({
        type: FeatureConstants.GET_ALL_ACTION_TYPE,
        items: response.data,
        totalItems: response.data.length,
        totalPages: 1
      });
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
    dispatch({
      type: FeatureConstants.GET_ALL_ACTION_TYPE,
      items: null,
      totalItems: 0,
      totalPages: 0
    });
  }
};

const getDataToProducts = async (dispatch) => {
  try {
    const response = await axios.get(CategoryApi.GetCategories);
    if (response.data) {
      dispatch({
        type: CategoryConstants.GET_ALL_ACTION_TYPE,
        items: response.data.length > 0 ? response.data : null
      });
      if (response.data.length > 0) {
        dispatch(actionChangeValueProduct('category', response.data[0]));
        changeCategory(dispatch, response.data[0].id);
      }
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const getProduct = async (dispatch, productId) => {
  try {
    const response = await axios.get(ProductApi.GetProduct(productId));
    if (response.data) {      
      dispatch(actionGetCategories());
      dispatch(actionGetFeatures(response.data.category.id));
      dispatch(actionSetErrorUploadImage(ProductConstants.IMAGE, response.data.imageUrl && response.data.imageUrl.length > 0 ? false : true));
      dispatch(actionSetErrorUploadImage(ProductConstants.THUMBNAIL, response.data.thumbnailImageUrl && response.data.thumbnailImageUrl.length > 0 ? false : true));
      dispatch(actionLoadProduct(response.data));
    } else {
      //TODO: NO DATA ON SERVER
      console.log("TODO - error server");
    }
  } catch (e) {
    dispatch(actionErrorAuth(e));
  }
};

const uploadFile = async (dispatch, file, property, keyImage) => {
  try {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await axios.post(UploadFile.Api, formData);
    if (response.data) {
      dispatch(actionChangeValueProduct(property, response.data.dbPath));
      dispatch(dispatch(actionSetErrorUploadImage(keyImage, false)));
    } else {
      dispatch(dispatch(actionSetErrorUploadImage(keyImage, true)));
    }
  } catch (e) {
    if (e && e.response && e.response.status === 400 && e.response.data) {
      dispatch(actionMessageProductDetail(ProductConstants.MESSAGE_ADD_ERROR(e.response.data)));
    }
    dispatch(actionErrorAuth(e));
  }
};

const objectToProductRequest = (product, isAdd) => {
  const productRequest = {
    id: isAdd ? UuidUtil.EMPTY : product.id,
    categoryId: product.category.id,
    name: product.name,
    sku: product.sku,
    description: product.description,
    shortDescription: product.shortDescription,
    url: product.url,
    order: product.order,
    imageUrl: product.imageUrl,
    thumbnailImageUrl: product.thumbnailImageUrl,
    isEnabled: true,
    productFeatures: isAdd ? mapFeaturesToRequestObject(product.productFeatures) : mapFeaturesToRequestObjectEdit(product.productFeatures, product.originalProductFeatures)
  };
  return productRequest;
};

const mapFeaturesToRequestObjectEdit = (productFeatures, originalProductFeatures) => {
  productFeatures = productFeatures.map(pf => {
    pf.isEnabled = true;
    return pf;
  });

  if (originalProductFeatures.length === productFeatures.length && originalProductFeatures.every(original => productFeatures.find(pf => pf.optionId === original.optionId))) {//IF THERE ARE SAME PRODUCT FEATURES
    const featureIdList = [...new Set(originalProductFeatures.map(f => f.featureId))];
    return featureIdList.map(f => {
      return {
        featureId: f,
        isEnabled: true,
        featureOptionList: mapOptionsToRequestObject(originalProductFeatures, f, false)
      }
    });
  } else {//FIND DIFERENCES    
    let intersection = originalProductFeatures.filter(original => productFeatures.find(pf => pf.optionId === original.optionId) ? true : false);
    if (intersection) {//THERE ARE COMMON OPTIONS
      let originalMinusNews = originalProductFeatures.filter(original => productFeatures.find(pf => pf.optionId === original.optionId) ? false : true);
      originalMinusNews = originalMinusNews.map(original => {
        original.isEnabled = false;
        return original;
      });
      originalMinusNews = originalMinusNews.concat(intersection);
      let newsMinusOriginal = productFeatures.filter(pf => originalProductFeatures.find(original => original.optionId === pf.optionId) ? false : true);
      newsMinusOriginal = newsMinusOriginal.map(minus => {
        minus.id = UuidUtil.EMPTY;
        return minus;
      });
      originalMinusNews = originalMinusNews.concat(newsMinusOriginal);
      const featureIdList = [...new Set(originalMinusNews.map(f => f.featureId))];
      return featureIdList.map(f => {
        return {
          featureId: f,
          isEnabled: originalMinusNews.filter(original => original.featureId === f).every(pf => !pf.isEnabled) ? false :  true,
          featureOptionList: mapOptionsToRequestObject(originalMinusNews, f, false)
        }
      });
    } else {//new features are totally differemts
      originalProductFeatures = originalProductFeatures.map(original => {
        original.isEnabled = false;
        return original;
      });
      productFeatures = productFeatures.map(pf => {
        pf.id = UuidUtil.EMPTY;
        return pf;
      });
      originalProductFeatures = originalProductFeatures.concat(productFeatures);
      const featureIdList = [...new Set(originalProductFeatures.map(f => f.featureId))];
      return featureIdList.map(f => {
        return {
          featureId: f,
          isEnabled: originalProductFeatures.filter(original => original.featureId === f).every(pf => !pf.isEnabled) ? false : true,
          featureOptionList: mapOptionsToRequestObject(originalProductFeatures, f, false)
        }
      });
    }
  }
};

const mapFeaturesToRequestObject = (productFeatures) => {
  const featureIdList = [...new Set(productFeatures.map(f => f.featureId))];
    return featureIdList.map(f => {
        return {
            featureId: f,
            isEnabled: true,
            featureOptionList: mapOptionsToRequestObject(productFeatures, f, true)
        }
    });
};

const mapOptionsToRequestObject = (productFeatures, featureId, isAdd) => {
  return productFeatures.filter(pf => pf.featureId === featureId).map(o => {
        return {
            id: o.id,
            featureId: o.featureId,
            optionId: o.optionId,
            isEnabled: isAdd ? true : o.isEnabled
        }
    });
};

const saveProduct = async (dispatch, product) => {
  try {    
    const response = await axios.post(ProductApi.PostProduct, objectToProductRequest(product, true));
    if (response.data) {
      dispatch(actionMessageProduct(ProductConstants.MESSAGE_ADD_OK));
      dispatch({
        type: ProductConstants.SAVE_ADD_ACTION_TYPE,
      });
    }    
  } catch (e) {
    if (e && e.response && e.response.status === 400 && e.response.data) {
      dispatch(actionMessageProductDetail(ProductConstants.MESSAGE_ADD_ERROR(e.response.data)));
    }
    dispatch(actionErrorAuth(e));
  }  
};

const editProduct = async (dispatch, product) => {
  try {
    const response = await axios.put(ProductApi.PutProduct, objectToProductRequest(product, false));
    if (response.data) {
      dispatch(actionMessageProduct(ProductConstants.MESSAGE_UPDATE_OK));
      dispatch({
        type: ProductConstants.SAVE_EDIT_ACTION_TYPE,
      });
    }    
  } catch (e) {
    if (e && e.response && e.response.status === 400 && e.response.data) {
      dispatch(actionMessageProductDetail(ProductConstants.MESSAGE_UPDATE_ERROR(e.response.data)));
    }
    dispatch(actionErrorAuth(e));
  }  
};

const actionGetProducts = (sortBy, page, pageSize) => {
  return dispatch => {
    dispatch({
      type: ProductConstants.SET_ORDER_BY_ACTION_TYPE,
      sortBy: sortBy
    });
    getProducts(dispatch, sortBy, page, pageSize);
  };
};

const actionBeginListProducts = (sortBy, pageSize) => {
  return dispatch => {
    dispatch({
      type: ProductConstants.BEGIN_LIST_ACTION_TYPE
    });
    dispatch(actionGetProducts(sortBy, 0, pageSize));
  };
};

const actionSetSearchProduct = (search) => {
  return {
    type: ProductConstants.SET_SEARCH_ACTION_TYPE,
    search: search
  };
};

const actionSearchProducts = (search, sortBy, page, pageSize) => {
  return dispatch => {
    searchProducts(dispatch, search, sortBy, page, pageSize);
  };
};

const actionToggleProduct = (productId) => {
  return dispatch => {
    toggleProduct(dispatch, productId);
  };
};

const actionDeleteProduct = (productId, isFromList) => {
  return dispatch => {
    deleteProduct(dispatch, productId, isFromList);
  };
};

const actionBeginAddProduct = () => {
  return dispatch => {
    dispatch(actionMessageProduct(null));
    dispatch({
      type: ProductConstants.BEGIN_ADD_ACTION_TYPE
    });
    getDataToProducts(dispatch);
  };
};

const actionChangeValueProduct = (propertie, value) => {
  return dispatch => {
    dispatch({
      type: ProductConstants.CHANGE_VALUE_ACTION_TYPE,
      propertie: propertie,
      value: value
    });
  };
};

const actionChangeCategoryProduct = (categoryId) => {
  return dispatch => {
    changeCategory(dispatch, categoryId);
  };
};

const actionBeginEditProduct = (productId) => {
  return dispatch => {
    dispatch(actionMessageProduct(null));
    getProduct(dispatch, productId);
  };
};

const actionLoadProduct = (product) => {
  product.exists = true;
  if (!product.url) {
    product.url = '';
  }
  product.originalProductFeatures = product.productFeatures.map(pf => {
    pf.isEnabled = true;
    return pf;
  });
  return {
    type: ProductConstants.GET_ACTION_TYPE,
    product: product
  };
};

const actionOpenProductOptions = (featureId, options) => {
  return {
    type: ProductConstants.OPEN_OPTIONS_ACTION_TYPE,
    featureSelected: featureId,
    options: options
  };
};

const actionCloseProductOptions = () => {
  return dispatch => {
    dispatch({ type: ProductConstants.CLOSE_OPTIONS_ACTION_TYPE });
    dispatch(actionResetOptions());
  };
};

const actionUploadThumbnailProduct = (file) => {
  return dispatch => {
    dispatch(actionMessageProductDetail(null));
    uploadFile(dispatch, file, 'thumbnailImageUrl', ProductConstants.THUMBNAIL);
  };
};

const actionUploadImageProduct = (file) => {
  return dispatch => {
    dispatch(actionMessageProductDetail(null));
    uploadFile(dispatch, file, 'imageUrl', ProductConstants.IMAGE);
  };
};

const actionUpdateProductFeatureOption = (optionSelected) => {
  return {
    type: ProductConstants.UPDATE_OPTION_ACTION_TYPE,
    optionSelected: optionSelected
  };
};

const actionUpdateProductFeature = (featureSelected) => {
  return {
    type: ProductConstants.UPDATE_FEATURE_ACTION_TYPE,
    featureSelected: featureSelected
  };
};

const actionCancelProductFeatureOption = () => {
  return {
    type: ProductConstants.CANCEL_OPTIONS_ACTION_TYPE
  };
};

const actionSaveAddProduct = (product) => {
  return dispatch => {
    dispatch(actionMessageProductDetail(null));
    saveProduct(dispatch, product);
  };
};

const actionSaveEditProduct = (product) => {
  return dispatch => {
    dispatch(actionMessageProductDetail(null));
    editProduct(dispatch, product);
  };
};

const actionMessageProduct = (message) => {
  return {
    type: ProductConstants.MESSAGE_LIST_ACTION_ACTION_TYPE,
    messageListAction: message
  };
};

const actionMessageProductDetail = (message) => {
  return {
    type: ProductConstants.MESSAGE_ACTION_ACTION_TYPE,
    messageAction: message
  };
};

const actionGetProductsResume = (name, pageSize, sortBy) => {
  return dispatch => {
    dispatch({
      type: ProductConstants.SET_ORDER_BY_ACTION_TYPE,
      sortBy: sortBy
    });
    getProductsResume(dispatch, name, pageSize, sortBy);
  };
};

const actionResetOrderByProduct = () => {
  return dispatch => {
    dispatch({
      type: ProductConstants.SET_ORDER_BY_ACTION_TYPE,
      sortBy: ProductSortEnum.SKU
    });
  };
};

const actionResetMessageList = () => {
  return dispatch => {
    dispatch({
      type: ProductConstants.MESSAGE_LIST_ACTION_TYPE,
      message: null
    });
  };
};

export {
  actionBeginListProducts,
  actionGetProducts,
  actionSetSearchProduct,
  actionSearchProducts,
  actionToggleProduct,
  actionDeleteProduct,
  actionBeginAddProduct,
  actionChangeValueProduct,
  actionChangeCategoryProduct,
  actionBeginEditProduct,
  actionOpenProductOptions,
  actionCloseProductOptions,
  actionUploadThumbnailProduct,
  actionUploadImageProduct,
  actionUpdateProductFeatureOption,
  actionUpdateProductFeature,
  actionCancelProductFeatureOption,
  actionSaveAddProduct,
  actionSaveEditProduct,
  actionMessageProduct,
  actionMessageProductDetail,
  actionGetProductsResume,
  actionResetOrderByProduct,
  actionResetMessageList
};
