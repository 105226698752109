import React from 'react';
import { Row, Col } from 'reactstrap';
import ButtonGroup from '../buttons/ButtonGroup';
import QuestionAnswerBase from './QuestionAnswerBase';
import Group from '../../images/Group.png';

class QuestionAnswerTextItem extends QuestionAnswerBase {
  render() {
    return (
      <Row className="answer-item">
        <Col lg="12" className="item-admin">
          <Row>
            <Col lg="1" className="item-admin__text text-center"><img alt="order" src={Group} /></Col>
            <Col lg="8" className="item-admin__text pl-0">{this.props.item.option.name}</Col>
            <Col lg="3" className="pr-0">
              <ButtonGroup handleEdit={null} handleDelete={null} handleSave={null} handleCancel={null} handleVisible={this.handleVisible} itemIsVisible={this.props.item.isEnabled} viewIsVisible={true} />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default QuestionAnswerTextItem;
