import ErrorsApp from "../../utils/ErrorsApp";

export default class DistributorConstants {
  static get BEGIN_LIST_ACTION_TYPE() { return 'distributor/BEGIN_LIST'; }
  static get GET_ALL_ACTION_TYPE() { return 'distributor/GET_ALL'; }
  static get MESSAGE_LIST_ACTION_TYPE() { return 'distributor/MESSAGE_LIST'; }
  static get MESSAGE_LIST_NOT_FOUND() { return 'No distributors found'; }
  static get MESSAGE_PRODUCTSDISTRIBUTOR_NOT_SELECTED() { return 'No products selected'; }
  static get TOGGLE_ACTION_TYPE() { return 'distributor/TOGGLE'; }
  static get DELETE_ACTION_TYPE() { return 'distributor/DELETE'; }
  static get OPEN_SELECT_ACTION_TYPE() { return 'distributor/OPEN_SELECT'; }
  static get CLOSE_SELECT_ACTION_TYPE() { return 'distributor/CLOSE_SELECT'; }
  static get OPEN_SELECTED_ACTION_TYPE() { return 'distributor/OPEN_SELECTED'; }
  static get CLOSE_SELECTED_ACTION_TYPE() { return 'distributor/CLOSE_SELECTED'; }
  static get BEGIN_ADD_ACTION_TYPE() { return 'distributor/BEGIN_ADD'; }
  static get CHANGE_VALUE_ACTION_TYPE() { return 'distributor/CHANGE_VALUE'; }
  static get UPDATE_PRODUCTS_ACTION_TYPE() { return 'distributor/UPDATE_PRODUCTS'; }
  static get CONFIRM_PRODUCTS_ACTION_TYPE() { return 'distributor/CONFIRM_PRODUCTS'; }
  static get CHANGE_VALUE_PRODUCT_ACTION_TYPE() { return 'distributor/CHANGE_VALUE_PRODUCT'; }
  static get UPDATE_PRODUCT_SELECTED_ACTION_TYPE() { return 'distributor/UPDATE_PRODUCT_SELECTED'; }
  static get REMOVE_PRODUCT_ACTION_TYPE() { return 'distributor/REMOVE_PRODUCT'; }
  static get SELECT_ALL_PRODUCTS_ACTION_TYPE() { return 'distributor/SELECT_ALL_PRODUCTS'; }
  static get CHANGE_VALUE_SELECT_ALL_ACTION_TYPE() { return 'distributor/CHANGE_VALUE_SELECT_ALL'; }
  static get SAVE_ADD_ACTION_TYPE() { return 'distributor/SAVE_ADD'; }
  static get SAVE_EDIT_ACTION_TYPE() { return 'distributor/SAVE_EDIT'; }
  static get MESSAGE_ACTION_ACTION_TYPE() { return 'distributor/MESSAGE_ACTION'; }
  static get MESSAGE_LIST_ACTION_ACTION_TYPE() { return 'distributor/MESSAGE_LIST_ACTION'; }
  static get GET_ACTION_TYPE() { return 'distributor/GET'; }
  static get SET_INVALIDS_PRODUCTS() { return 'distributor/SET_INVALIDS_PRODUCTS'; }
  static get RESET_INVALIDS_PRODUCTS() { return 'distributor/RESET_INVALIDS_PRODUCTS'; }
  static get PAGE_SIZE() { return 5; }
  static get PAGE_LIST_SIZE() { return 10; }
  static get MESSAGE_ADD_OK() { return { type: 'success', text: 'Distributor added succesfully.' }; }  
  static MESSAGE_ADD_ERROR = (data) => { return { type: 'danger', text: ErrorsApp.GET_MESSAGE_TEXT(data) }; }
  static get MESSAGE_UPDATE_OK() { return { type: 'success', text: 'Distributor updated succesfully.' }; }
  static MESSAGE_UPDATE_ERROR = (data) => { return { type: 'danger', text: ErrorsApp.GET_MESSAGE_TEXT(data) }; }
}
