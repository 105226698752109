import React, { Component, Fragment, useState, useRef } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, CustomInput, Alert, Button } from 'reactstrap';
import '../Admin.css';
import { withRouter } from 'react-router-dom';
import HeaderSave from '../header/HeaderSave';
import './QuestionContainer.css'
import QuestionAnswerIconItem from './QuestionAnswerIconItem';
import QuestionAnswerTextItem from './QuestionAnswerTextItem';
import PathsApp from '../../utils/PathsApp';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { actionBeginAddQuestion, actionToggleQuestion, actionDeleteQuestion, actionBeginEditQuestion, actionChangeCategory, actionChangeValueQuestion, actionChangeAnswer, actionChangeFeature, actionSaveAddQuestion, actionSaveEditQuestion, actionUploadImageQuestion, actionMessageQuestionDetail, actionAddRange, actionChangeRangeValue, actionDeleteRange } from './QuestionAction';
import QuestionModel from './QuestionModel';
import QuestionAnswerModel from './QuestionAnswerModel';
import uuid from 'uuid/v1';
import TypeLayoutEnum from '../../models/TypeLayoutEnum';
import { actionResetOptions, actionOrderOptions } from '../options/OptionActions';
import AlertContainer from '../../alerts/AlertContainer';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Plus from '../../images/c-add2x.png';
import QuestionRangeItem from './QuestionRangeItem';
import RangeModel from './RangeModel';
import JoditEditor from "jodit-react";

const SortableItem = SortableElement(({ value, updateList, handleFile, AnswerComponent }) => <div><AnswerComponent item={value} updateList={updateList} handleFile={handleFile} /></div>);

const SortableList = SortableContainer(({ items, updateList, handleFile, AnswerComponent }) => {
  if (items) {
    return (
      <div>
        <Row className="list-admin">
          <Col className="pl-0 pr-0">
            {items.map((value, index) => (
              <SortableItem key={`item-${index}`} index={index} value={value} updateList={updateList} handleFile={handleFile} AnswerComponent={AnswerComponent} />
            ))}
          </Col>
        </Row>
      </div>
    );
  } else {
    return null;
  }
});

const RichTextArea = (props) => {
    const editor = useRef(null)

    const config = {
        readonly: false,
        toolbarSticky: false,
        buttons: "bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,font,fontsize,paragraph,classSpan,brush"
    }

    return (
        <JoditEditor
            ref={editor}
            value={props.value}
            config={config}
            tabIndex={1} 
            onBlur={newContent => {
                props.onChange(newContent)
            }}
            onChange={newContent => { }}
        />
    );
}

class QuestionContainer extends Component {

  handleGetBack = (event) => {
    event.preventDefault();
    this.props.history.push(PathsApp.QUESTIONS_ROOT);
  }

  getTypeLayout(typeLayout) {
    if (typeLayout === TypeLayoutEnum.ICON) {
      return QuestionAnswerIconItem;
    } else {
      return QuestionAnswerTextItem;
    }
  };

  handleVisible = (event) => {
    event.preventDefault();    
    if (!QuestionModel.isEmpty(this.props.currentQuestion)) {
      this.props.toggle(this.props.currentQuestion.id);
    } else {
      console.log('TODO-can not toggle message');
    }
  };

  handleDelete = (event) => {
    event.preventDefault();
    if (!QuestionModel.isEmpty(this.props.currentQuestion)) {
      this.props.delete(this.props.currentQuestion.id, false);
    } else {
      console.log('TODO-can not delete message');
    }   
  };

  changeType = (event) => {
    this.setState({ typeLayout: event.target.value });
    this.props.changeInputValue('layoutType', this.props.layoutTypes.find(layoutType => layoutType.id === event.target.value));
  };

  changeCategory = (event) => {
    this.props.changeCategory(event.target.value);
    this.props.changeInputValue('category', this.props.categories.find(category => category.id === event.target.value));
  };

  changeFeature = (event) => {
    this.props.changeFeature(event.target.value);
    this.props.changeInputValue('feature', this.props.features.find(feature => feature.id === event.target.value));
  };

  componentDidMount() {
    if (this.props.match.path === PathsApp.QUESTIONS_ADD) {
      this.props.beginAdd();
    } else {
      this.props.beginEdit(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.resetOptions();
    this.props.resetMessage();
  }

  handleChangeInput = (event) => {
    this.props.changeInputValue(event.target.id, event.target.value);
  };
  handleChangeInputEditor = (event) => {
      this.props.changeInputValue('helpInfo', event);
  };

  handleChangeRadio = (event) => {
    if (event.target.id === 'isMultipleChoice') {
      this.props.changeInputValue('isMultipleChoice', true);
    } else {
      this.props.changeInputValue('isMultipleChoice', false);
    }
  };

  handleSave = (event) => {
    event.preventDefault();
    if (!QuestionModel.isEmpty(this.props.currentQuestion)) {
      this.props.saveEdit(this.props.currentQuestion);        
    } else {
      this.props.saveAdd(this.props.currentQuestion);
    }
  };

  isValidQuestion = (question) => {
    return question &&
      question.text && question.text.length > 0 &&
      question.layoutType && question.layoutType.id.length > 0 &&
      question.category && question.category.id.length > 0 &&
      question.feature && question.feature.id.length > 0 &&
      question.answers && question.answers.length > 0;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.order(oldIndex, newIndex, this.props.options);
  };

  handleAddRange = (event) => {
    event.preventDefault();
    this.props.addNewRange(RangeModel.getEmpty());
    };


  render() {
    if (!this.props.currentQuestion.exists) { // redirect after delete question
      return (
        <Redirect to={PathsApp.QUESTIONS_ROOT} />
      );
    } else {
      return (
        <Fragment>
          {this.props.messageAction ? window.scrollTo(0, 0) : null}
          <AlertContainer alert={this.props.messageAction} />
          <Container className="page-admin page-admin--feature" fluid>
            <HeaderSave title={"Question detail"} handleSave={this.handleSave} handleGetBack={this.handleGetBack} handleVisible={this.handleVisible} handleDelete={this.handleDelete} isVisible={this.props.currentQuestion.isEnabled} />
            <Container className="question-section question-section--bordered" fluid>
              <Row>
                <Col className="mb-1" lg="7">
                  <FormGroup>
                    <Label for="questionName">Question</Label>
                    <Input type="text" className="input-admin" name="text" id="text" onChange={this.handleChangeInput} value={this.props.currentQuestion.text} />
                  </FormGroup>
                </Col>
                <Col className="mb-1" lg="5">
                  <FormGroup>
                    <Label for="questionType">Question Type</Label>
                    <div className="question-type">
                      <CustomInput type="radio" id="isSingleChoice" name="typeQuestion" label="Single Choice" inline onChange={this.handleChangeRadio} checked={!this.props.currentQuestion.isMultipleChoice} />
                      <CustomInput type="radio" id="isMultipleChoice" name="typeQuestion" label="Multiple Choice" inline onChange={this.handleChangeRadio} checked={this.props.currentQuestion.isMultipleChoice} />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="mb-1" lg="4">
                  <FormGroup>
                    <Label for="questionLayout">Type of Layout</Label>
                    <div className="select-admin__wrapper fa">
                      <select className="select-admin__control" onChange={this.changeType} value={this.props.currentQuestion.layoutType.id}>
                        {
                          this.props.layoutTypes ?
                            this.props.layoutTypes.map(layoutType => <option key={layoutType.id} value={layoutType.id}>{layoutType.name}</option>)
                            : null
                        }
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                <Col className="mb-1" lg="4">
                  <FormGroup>
                    <Label for="questionProductCategory">Product Category</Label>
                    <div className="select-admin__wrapper fa">
                      <select className="select-admin__control" onChange={this.changeCategory} value={this.props.currentQuestion.category.id}>
                        {
                          this.props.categories ?
                            this.props.categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)
                            : null
                        }
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                <Col className="mb-1" lg="4">
                  <FormGroup>
                    <Label for="questionProductFeature">Product Feature</Label>
                    <div className="select-admin__wrapper fa">
                      <select className="select-admin__control" onChange={this.changeFeature} value={this.props.currentQuestion.feature.id}>
                        {
                          this.props.features ?
                            this.props.features.map(feature => <option key={feature.id} value={feature.id}>{feature.name}</option>)
                            : null
                        }
                      </select>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="mb-1" lg="12">
                  <FormGroup>
                    <Label for="helpInfo">Help Info</Label>
                    <RichTextArea value={this.props.currentQuestion.helpInfo} onChange={this.handleChangeInputEditor} />
                  </FormGroup>
                </Col>
              </Row>
            </Container>
            <Container className="question-section" fluid>
              <Row className="mb-3">
                <Col>
                  <h5 className="answer-title">Answers</h5>
                </Col>
                {
                  this.props.currentQuestion.layoutType.code === TypeLayoutEnum.RANGE
                    ? <Col className="text-right pr-4">
                        <Button className="addRange" color="primary"><img alt="add" src={Plus} onClick={this.handleAddRange} /></Button>
                      </Col>
                    : null
                }
              </Row>              
              {
                this.props.currentQuestion.layoutType.code === TypeLayoutEnum.RANGE
                  ? this.props.currentQuestion.ranges && this.props.currentQuestion.ranges.length > 0
                    ? this.props.currentQuestion.ranges.map(range => range.isEnabled ? <QuestionRangeItem key={range.key} item={range} changeValue={this.props.changeRangeValue} deleteRange={this.props.deleteRange} /> : null)
                    : null
                : <Fragment>
                    <SortableList items={this.props.answers} onSortEnd={this.onSortEnd} updateList={this.props.changeAnswer} distance={10} handleFile={this.props.uploadFile} AnswerComponent={this.getTypeLayout(this.props.currentQuestion.layoutType.code)} />
                    {
                      this.props.messageOptions ?
                        <Alert className="mt-2" color="warning">{this.props.messageOptions}</Alert>
                        : null
                    }
                  </Fragment>
              }              
            </Container>
          </Container>
        </Fragment>
      );
    }    
  }
}

const getAnswers = (options, questionAnswers, tempAnswers) => {  
  let answers = [];
  if (options && questionAnswers) {
    const hasOptions = questionAnswers.every(answer => answer.option);
    answers = options.map((option, index) => {
      const found = hasOptions && questionAnswers.find(answer => answer.option.id === option.id);
      return found
        ? new QuestionAnswerModel(found.id, found.questionId, option, true, found.imageUrl ? found.imageUrl : '', index, 0, 0, '')
        : new QuestionAnswerModel(uuid().toString(), uuid().toString(), option, false, getTempImage(tempAnswers, option.id), index, 0, 0, '');
    });
  }
  return answers;
};

const getTempImage = (tempAnswers, optionId) => {
  let tempImage = '';
  const tempAnswer = tempAnswers.find(answer => answer.optionId === optionId);
  if (tempAnswer) {
    tempImage = tempAnswer.imageUrl;
  }
  return tempImage;
};

const mapStateToProps = state => {
  return {
    categories: state.category.items,
    features: state.feature.items,
    currentQuestion: state.question.selected,
    options: state.option.items,
    answers: getAnswers(state.option.items, state.question.selected.answers, state.question.tempAnswers),
    layoutTypes: state.question.layoutTypes,
    messageOptions: state.question.messageOptions,
    messageAction: state.question.messageAction
  };
};

const mapDispatchToProps = dispatch => {
  return {
    beginAdd: () => dispatch(actionBeginAddQuestion()),
    changeCategory: (categoryId) => dispatch(actionChangeCategory(categoryId)),
    beginEdit: (questionId) => dispatch(actionBeginEditQuestion(questionId)),
    toggle: (questionId) => dispatch(actionToggleQuestion(questionId)),
    delete: (questionId, isFromList) => dispatch(actionDeleteQuestion(questionId, isFromList)),
    changeFeature: (featureId) => dispatch(actionChangeFeature(featureId)),
    changeInputValue: (propertie, value) => dispatch(actionChangeValueQuestion(propertie, value)),
    changeAnswer: (option, isEnabled) => dispatch(actionChangeAnswer(option, isEnabled)),
    saveAdd: (question) => dispatch(actionSaveAddQuestion(question)),
    saveEdit: (question) => dispatch(actionSaveEditQuestion(question)),
    resetOptions: () => dispatch(actionResetOptions()),
    uploadFile: (file, optionId) => dispatch(actionUploadImageQuestion(file, optionId)),
    resetMessage: () => dispatch(actionMessageQuestionDetail(null)),
    order: (oldIndex, newIndex, oldOptions) => dispatch(actionOrderOptions(oldIndex, newIndex, oldOptions)),
    addNewRange: (range) => dispatch(actionAddRange(range)),
    changeRangeValue: (rangeId, field, value) => dispatch(actionChangeRangeValue(rangeId, field, value)),
    deleteRange: (rangeKey, rangeId) => dispatch(actionDeleteRange(rangeKey, rangeId))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionContainer));
