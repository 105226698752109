import BaseModel from "../../models/BaseModel";

export default class ProductDistributorRequestModel {
  constructor(id, productId, productName, distributorId, url, isEnabled, category, sku) {
    this.id = id;
    this.productId = productId;
    this.productName = productName;
    this.distributorId = distributorId;
    this.url = url;
    this.isEnabled = isEnabled;
    this.category = category;
    this.sku = sku;
  }

  static getEmpty = () => {
    return new ProductDistributorRequestModel('', '', '', '', '', true, BaseModel.getEmpty(), '');
  };
}