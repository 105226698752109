import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PathsApp from '../utils/PathsApp';
import AppRoute from './AppRoute';

export default ({ component: Component, layout: Layout, isAuthenticated: IsAuthenticated, ...rest }) => {  
  if (!IsAuthenticated) {    
    return <AppRoute {...rest} layout={Layout} component={Component} />
  } else {
    return <Route {...rest} render={() => <Redirect to={PathsApp.QUESTIONS_ROOT}/>} />
  }
}
