import BaseModel from "../../models/BaseModel";

class OptionModel extends BaseModel {
  constructor(id, name, description, featureId) {
    super(id, name);
    this.description = description;
    this.featureId = featureId;
  }
}

export default OptionModel;
