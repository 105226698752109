export default class LayoutConstants {
  static get GET_ACTION_TYPE() { return 'layout/GET'; }
  static get CHANGE_VALUE_ACTION_TYPE() { return 'layout/CHANGE_VALUE'; }
  static get SAVE_ADD_ACTION_TYPE() { return 'layout/SAVE_ADD'; }
  static get SAVE_EDIT_ACTION_TYPE() { return 'layout/SAVE_EDIT'; }
  static get MESSAGE_ACTION_TYPE() { return 'layout/MESSAGE'; }
  static get MESSAGE_LAYOUT_OK() { return { type: 'success', text: 'Layout saved succesfully.' }; }
  static get MESSAGE_LAYOUT_ERROR() { return { type: 'danger', text: 'Error saving layout.' }; }
  static MESSAGE_LAYOUT_ERROR_IMAGE(data) { return { type: 'danger', text: data }; }
  static get IMAGE() { return 'layoutImage'; }
}
