import { Component } from 'react';

class OptionBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.checked,
      id: this.props.id
    };
  }

  handleCheck = (event) => {
    event.preventDefault();
    const previousIsChecked = this.props.checked;
    this.setState({ isChecked: !previousIsChecked });
    this.props.updateOption(this.props.id, !previousIsChecked);
  };
}

export default OptionBase;
