import ResultConstants from "./ResultConstants";

const initialState = {
  data: null,
  message: null,
  loading: false
};

const ResultReducer = (state = initialState, action) => {
  switch (action.type) {
    case ResultConstants.LOADING_ACTION_TYPE:
      return {
        ...state,
        loading: true
      }
    case ResultConstants.GET_ACTION_TYPE:
      return {
        ...state,
        data: action.data,
        loading: false
      };    
    case ResultConstants.RESET_ACTION_TYPE:
      return {
        ...state,
        data: null,
        message: null,
        loading: false
      };
    case ResultConstants.MESSAGE_RESULT_ACTION_TYPE:
      return {
        ...state,
        message: action.message
      };
    case ResultConstants.GET_ALL_PRODUCTS_ACTION_TYPE:
      return {
        ...state,
        data: action.data,
        loading: false
      };
    default:
      return state;
  }
};

export default ResultReducer;
