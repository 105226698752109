import HelperConstants from "./HelperConstants";

const actionOpenHelper = () => {
  return {
    type: HelperConstants.OPEN_ACTION_TYPE
  };
};

const actionCloseHelper = () => {
  return {
    type: HelperConstants.CLOSE_ACTION_TYPE
  };
};

export { actionOpenHelper, actionCloseHelper };
