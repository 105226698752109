import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import registerServiceWorker from './registerServiceWorker';
import './index.css';
import { Provider } from 'react-redux';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import CategoryReducer from './admin/categories/CategoryReducer';
import FeatureReducer from './admin/features/FeatureReducer';
import OptionReducer from './admin/options/OptionReducer';
import HelperReducer from './client/helper/HelperReducer';
import WizardReducer from './client/wizard/WizardReducer';
import StartOverReducer from './client/startover/StartOverReducer';
import QuestionAdminReducer from './admin/questions/QuestionAdminReducer';
import ProductAdminReducer from './admin/products/ProductAdminReducer';
import LayoutAdminReducer from './admin/layout/LayoutAdminReducer';
import ResultReducer from './client/result/ResultReducer';
import PreviewReducer from './admin/preview/PreviewReducer';
import LoginReducer from './admin/login/LoginReducer';
import HomeReducer from './client/home/HomeReducer';
import UploadImageReducer from './admin/uploadImage/UploadImageReducer';
import { createBrowserHistory } from 'history';
import DistributorAdminReducer from './admin/distributors/DistributorAdminReducer';
import DistributorClientReducer from './client/distributor/DistributorClientReducer';

const rootReducer = combineReducers({  
  category: CategoryReducer,
  feature: FeatureReducer,
  option: OptionReducer,
  helper: HelperReducer,
  wizard: WizardReducer,
  startover: StartOverReducer,
  question: QuestionAdminReducer,
  product: ProductAdminReducer,
  layout: LayoutAdminReducer,
  result: ResultReducer,
  preview: PreviewReducer,
  login: LoginReducer,
  home: HomeReducer,
  uploadImage: UploadImageReducer,
  distributor: DistributorAdminReducer,
  distributorClient: DistributorClientReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = window.location.href.indexOf('localhost') !== -1 ?
  createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
  : createStore(rootReducer, applyMiddleware(thunk));

const rootElement = document.getElementById('root');

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

ReactDOM.render(
  <Provider store={store}>
        <BrowserRouter basename="/explore" history={history}>
      <App />
    </BrowserRouter>
  </Provider>,
  rootElement);

registerServiceWorker();
