import FeatureConstants from "./FeatureConstants";
import BaseModel from '../../models/BaseModel';

const initialState = {
  items: null,
  selected: {},
  message: null,
  totalItems: 0,
  totalPages: 0
};

const setFeatureSaved = (items, newFeature) => {
  return items.map((item, i) => {
    return i === 0 ? { ...item, id: newFeature.id, name: newFeature.name, description: newFeature.description } : item;
  });
};

const removeFeature = (items) => {
  return items.filter((item, i) => {
    return i !== 0;
  });
};

const setFeatureEdited = (items, editedFeature) => {
  return items.map((item) => {
    return item.id === editedFeature.id ? { ...item, name: editedFeature.name, description: editedFeature.description } : item;
  });
};

const setFeatureDeleted = (items, id) => {
  return items.filter((item) => {
    return item.id !== id;
  });
};

const FeatureReducer = (state = initialState, action) => {
  switch (action.type) {
    case FeatureConstants.GET_ALL_ACTION_TYPE:
      return {
        ...state,
        items: action.items,
        totalItems: action.totalItems,
        totalPages: action.totalPages
      };
    case FeatureConstants.GET_ACTION_TYPE:
      return {
        ...state,
        selected: action.selected
      };
    case FeatureConstants.BEGIN_ADD_ACTION_TYPE:
      return {
        ...state,
        items: [BaseModel.getEmpty(), ...state.items]
      };
    case FeatureConstants.SAVE_ADD_ACTION_TYPE:
      return {
        ...state,
        items: setFeatureSaved(state.items, action.newFeature)
      };
    case FeatureConstants.CANCEL_ADD_ACTION_TYPE:
      return {
        ...state,
        items: removeFeature(state.items)
      };
    case FeatureConstants.SAVE_EDIT_ACTION_TYPE:
      return {
        ...state,
        items: setFeatureEdited(state.items, action.editedFeature)
      };
    case FeatureConstants.DELETE_ACTION_TYPE:
      return {
        ...state,
        items: setFeatureDeleted(state.items, action.id)
      };
    case FeatureConstants.MESSAGE_ACTION_TYPE:
      return {
        ...state,
        message: action.message
      };
    default:
      return state;
  }
};

export default FeatureReducer;