import React, { Component } from 'react';
import WizardSetup from '../wizard/WizardSetup';
import './StepsContainer.css';
import WizardConstants from '../wizard/WizardConstants';
import { connect } from 'react-redux';
import { actionGetSetup, actionGetNumberProducts } from '../wizard/WizardActions';
import PropTypes from 'prop-types';
import AlertContainer from '../../alerts/AlertContainer';
import { actionGetDistributorByToken } from '../distributor/DistributorClientAction';
import DistributorNotFound from '../distributor/DistributorNotFound';

class StepsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false
    };
  }

  componentDidMount() {
    this.props.getDistributor(this.props.match.params.distributor, (distributor) => {
      if (distributor) {
        this.continueQuestions(distributor);
      } else {
        this.setState({ notFound: true });
      }
    });    
  }

  continueQuestions(distributor) {
    if (!this.props.questions) {
      this.props.getSetup(this.props.match.params.category, WizardConstants.CLIENT);
    }
    this.props.getNumberProducts(this.props.match.params.category, WizardConstants.CLIENT, distributor.id);
  }

  render() {
    if (this.state.notFound) {
      return (
        <DistributorNotFound />
      );
    } else {
      return (
        <div className="steps-container">
          <AlertContainer className="container" alert={this.props.message} />
          {this.props.questions ?
            <WizardSetup type={WizardConstants.CLIENT} max={this.props.questions.length - 1} numberProducts={this.props.numberProducts} />
            : null
          }
        </div>
      );
    }
  }
}

StepsContainer.propTypes = {
  questions: PropTypes.array
};

const mapStateToProps = state => {
  return {
    questions: state.wizard.data,
    message: state.wizard.message,
    numberProducts: state.wizard.numberProducts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSetup: (category, typeSetup) => dispatch(actionGetSetup(category, typeSetup)),
    getNumberProducts: (category, typeSetup, distributorId) => dispatch(actionGetNumberProducts(category, typeSetup, distributorId)),
    getDistributor: (token, callback) => dispatch(actionGetDistributorByToken(token, callback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepsContainer);
