import React, { Component } from 'react';
import Upload from '../../images/upload.png';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actionSetErrorUploadImage } from './UploadImageActions';

class UploadImageContainer extends Component {

  handleFile = (event) => {
    if (this.props.itemId) {
      this.props.handleFile(event.target.files[0], this.props.itemId);
    } else {
      this.props.handleFile(event.target.files[0]);
    }
  };

  triggerInputFile = () => this.fileInput.click();

  onErrorImage = (event) => {
    this.props.setIsError(this.props.keyImage, true);
  };

  handleClose = (event) => {
    event.preventDefault();   
    this.props.closeImage();
  }

  render() {
    let isError = getErrorValue(this.props.mapErrors, this.props.keyImage);
    return (
      <div className={this.props.mainClass}>
        {
          isError
            ? <div className={this.props.wrapperClass} onClick={this.triggerInputFile}>
                <img alt="upload" src={Upload} />
                <span>Upload</span>
              </div>
            : <div>
              {
                this.props.showClose
                  ? <button type="button" className="close" aria-label="Close" onClick={this.handleClose}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                  : null
              }
                <img alt="layout" onError={this.onErrorImage} className={this.props.imageClass} src={this.props.imageUrl} onClick={this.triggerInputFile} />
              </div>
        }
        <input ref={fileInput => this.fileInput = fileInput} type="file" name="file" onChange={this.handleFile} accept="image/x-png,image/jpeg" hidden />
      </div>
    );
  }
}

UploadImageContainer.propTypes = {
  handleFile: PropTypes.func,
  imageUrl: PropTypes.string,
  mainClass: PropTypes.string,
  wrapperClass: PropTypes.string,
  imageClass: PropTypes.string,
  itemId: PropTypes.string,
  keyImage: PropTypes.string,
  closeImage: PropTypes.func,
  showClose: PropTypes.bool
};

const getErrorValue = (mapErrors, key) => {
  const value = mapErrors[key];
  if (value === undefined || value === null) {
    return true;
  } else {
    return value;
  }
}

const mapStateToProps = state => {
  return {
    mapErrors: state.uploadImage.mapErrors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsError: (key, isError) => dispatch(actionSetErrorUploadImage(key, isError))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadImageContainer);
