export default class CategoryConstants {
  static get GET_ALL_ACTION_TYPE() { return 'category/GET_ALL'; }
  static get GET_ACTION_TYPE() { return 'category/GET'; }
  static get BEGIN_ADD_ACTION_TYPE() { return 'category/BEGIN_ADD'; }
  static get SAVE_ADD_ACTION_TYPE() { return 'category/SAVE_ADD'; }
  static get CANCEL_ADD_ACTION_TYPE() { return 'category/CANCEL_ADD'; }
  static get SAVE_EDIT_ACTION_TYPE() { return 'category/SAVE_EDIT'; }
  static get DELETE_ACTION_TYPE() { return 'category/DELETE'; }
  static get MESSAGE_ACTION_TYPE() { return 'category/MESSAGE'; }
  static get MESSAGE_ADD_OK() { return { type: 'success', text: 'Category added succesfully.' }; }
  static get MESSAGE_ADD_ERROR() { return { type: 'danger', text: 'Error adding category.' }; }
  static get MESSAGE_UPDATE_OK() { return { type: 'success', text: 'Category updated succesfully.' }; }
  static get MESSAGE_UPDATE_ERROR() { return { type: 'danger', text: 'Error updating category.' }; }
}
