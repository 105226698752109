import React, { Component } from 'react';
import Modal from 'react-modal';
import { Container, Button, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Back from '../../images/chevLeft.png';
import { actionCloseProductSelected, actionChangeValueProductDistributor, actionUpdateProductDistributorSelected } from './DistributorAction';

class ProductSelectedModal extends Component {
  handleClose = () => {
    this.props.close();
  };

  handleChange = (event) => {
    this.props.changeValue(event.target.id, event.target.value);
  };

  handleSave = () => {
    this.props.saveChanges();
    this.props.close();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onRequestClose={this.handleClose}
        contentLabel="Product details"
        className="preview-select">
        <Container className="page-admin page-admin--feature" fluid>
          <Container className="page-admin__header" fluid>
            <Row>
              <Col>
                <div className="page-admin__group">
                  <button className="page-admin__back" onClick={this.handleClose}>
                    <img alt="back" src={Back} className="page-admin__icon" />
                  </button>
                  <h2 className="pl-3">{this.props.product.sku}</h2>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="page-admin__modal-container" fluid>
            <Row>
              <Col>
                <FormGroup>
                  <Row>
                    <Col>
                      <Label>Product Category</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="font-weight-bold">{this.props.product.category.name}</span>
                    </Col>
                  </Row>
                </FormGroup>                
              </Col>
              <Col>
                <FormGroup>
                  <Row>
                    <Col>
                      <Label >Sku</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="font-weight-bold">{this.props.product.sku}</span>
                    </Col>
                  </Row>
                </FormGroup>                
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Row>
                    <Col>
                      <Label >Product Name</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="font-weight-bold">{this.props.product.productName}</span>
                    </Col>
                  </Row>
                </FormGroup>                
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="url">Url</Label>
                  <Input type="text" className="input-admin" name="url" id="url" value={this.props.product.url ? this.props.product.url : ''} onChange={this.handleChange} />
                </FormGroup>
              </Col>
            </Row>
          </Container>
          <div className="product-modal__footer">
            <Row className="ml-0 mr-0">
              <Col lg="4" className="product-modal__resume"></Col>
              <Col lg="8" className="product-modal__buttons">
                <Button onClick={this.handleSave} className="button_main" color="primary">Yes</Button>
                <Button onClick={this.handleClose} color="link" className="product-modal__cancel">Cancel</Button>
              </Col>
            </Row>
          </div>
        </Container>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    modalIsOpen: state.distributor.modalSelectedIsOpen,
    product: state.distributor.productSelected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    close: () => dispatch(actionCloseProductSelected()),
    changeValue: (propertie, value) => dispatch(actionChangeValueProductDistributor(propertie, value)),
    saveChanges: () => dispatch(actionUpdateProductDistributorSelected())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSelectedModal));
