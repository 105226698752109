import React, { Component } from 'react';
import { Container, Button } from 'reactstrap';
import HomeSetup from './HomeSetup';
import './HomeContainer.css';
import '../Client.css';
import { withRouter } from 'react-router-dom';
import PathsApp from '../../utils/PathsApp';
import { connect } from 'react-redux';
import { actionGetWizardLayout } from './HomeActions';
import { actionResetWizardSelected } from '../wizard/WizardActions';
import { actionResetResult } from '../result/ResultAction';
import { actionGetDistributorByToken } from '../distributor/DistributorClientAction';
import DistributorNotFound from '../distributor/DistributorNotFound';
import DistributorClientConstants from '../distributor/DistributorClientConstants';

class HomeContainer extends Component {

  constructor(props) {    
    super(props);
    this.state = {
      notFound: false,
      distributor: this.props.match.params.distributor ? this.props.match.params.distributor : DistributorClientConstants.REDIRECT
    };
  }

  handleGetStarted = (event) => {
    event.preventDefault();
    this.props.history.push(PathsApp.WIZARD_PATH(PathsApp.WIZARD_STEPS_ROOT, this.props.match.params.category, this.state.distributor));
  };

  componentDidMount() {
    this.props.resetWizard();
    this.props.resetResult();   
    this.props.getDistributor(this.state.distributor, (distributor) => {
      if (distributor) {
        this.continueDistributor();
      } else {
        this.setState({ notFound: true });
      }
    });
  }

    continueDistributor() {
        this.props.getLayout(this.props.match.params.category);
  }

  render() {
    if (this.state.notFound) {
      return (
        <DistributorNotFound />
      );
    } else {
      return (
        <Container className="home-container page-client">
          <HomeSetup layout={this.props.layout} />
          <div className="text-center">
            <Button className="home-container__start button_main" color="primary" disabled={this.props.distributor.id === ''} onClick={this.handleGetStarted}>Get started<i className="fas fa-chevron-right"></i></Button>
          </div>
        </Container>
      );
    }    
  }
}

const mapStateToProps = state => {
  return {
    layout: state.home.layout,
    distributor: state.distributorClient.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLayout: (category) => dispatch(actionGetWizardLayout(category)),
    resetWizard: () => dispatch(actionResetWizardSelected()),
    resetResult: () => dispatch(actionResetResult()),
    getDistributor: (token, callback) => dispatch(actionGetDistributorByToken(token, callback))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeContainer));
