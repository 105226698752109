import React, { Component } from 'react';
import { Container, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import './LoginContainer.css';
import Logo from '../../images/Netgear_Business2x.png';
import '../Admin.css';
import { connect } from 'react-redux';
import { actionAuthenticate, actionMessageAuthentication } from './LoginAction';
import AlertContainer from '../../alerts/AlertContainer';

class LoginContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    }
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.props.authenticate(this.state.username, this.state.password);
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  componentWillUnmount() {
    this.props.resetMessage();
  }

  render() {
    return (        
      <Container className="login page-admin">          
        <div className="login__logo">
          <img alt="logo" className="login__image" src={Logo} />
        </div>
        <AlertContainer alert={this.props.message} />
        <div>
          <div className="login__title">
            <h3 className="text-center">Log In</h3>
          </div>
          <Container className="login__form">
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label for="user">User</Label>
                <Input type="text" className="input-admin" name="username" id="username" onChange={this.handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input type="password" className="input-admin" name="password" id="password" onChange={this.handleChange} />
              </FormGroup>
              <div className="text-center pt-5">                  
                <Button className="login__button" type="submit" color="primary">Get Started</Button>
              </div>              
            </Form>
          </Container>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
    message: state.login.errorMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authenticate: (username, password) => dispatch(actionAuthenticate(username, password)),
    resetMessage: () => dispatch(actionMessageAuthentication(null))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);