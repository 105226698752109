import uuid from 'uuid/v1';
import UuidUtil from '../../utils/UuidUtil';

class RangeModel {
  constructor(key, id, minValue, maxValue, description, isEnabled) {
    this.key = key;
    this.id = id;
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.description = description;
    this.isEnabled = isEnabled
  }

  static getEmpty = () => {
    return {
      key: uuid().toString(),
      id: UuidUtil.EMPTY,
      minValue: '',
      maxValue: '',
      description: '',
      isEnabled: true
    };
  };
}

export default RangeModel;
